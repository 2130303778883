import React, { useState, ChangeEvent, useEffect } from 'react';
import { Container, Form } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import Layout from '../../../components/layouts/Layout';
import { Radio } from '../../../components/controls/Radio';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { AppState } from '../../../store/store';
import { ApiClaimSettlementType } from '../../../api/models';
import NextButton from '../../../components/controls/NextButton';
import { saveSettlementTypeAction } from './settlementTypeActions';
import { goToNextPage } from '../../../routing/RouteActions';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { push } from 'connected-react-router';

export const SettlementType: React.FC<SettlementTypeProps> = ({
  claimId,
  color,
  selectedSettlementType,
  isMotionsCloudEnabled,
  incident,
  saveSettlementType,
  navigateToNextStep,
  navigateHome,
}: SettlementTypeProps) => {
  const [loading, setLoading] = useState(false);
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [settlementType, setSettlementType] = useState<ApiClaimSettlementType | undefined | null>(
    selectedSettlementType
  );

  useEffect(() => {
    setSettlementType(selectedSettlementType);
  }, [selectedSettlementType]);

  const handleSettlementTypeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value == ApiClaimSettlementType.Repair) {
      setSettlementType(ApiClaimSettlementType.Repair);
    } else {
      setSettlementType(ApiClaimSettlementType.Money);
    }
  };

  const submitSettlementType = async () => {
    if (!settlementType) {
      setSettlementType(null);
      return;
    }
    if (claimId != undefined) {
      setLoading(true);
      try {
        await saveSettlementType(claimId, settlementType);
        navigateToNextStep(incident, isMotionsCloudEnabled);
      } catch (error) {
        setErrorPopup(
          <ModalPopup
            message={<Translate value={'errors.savingSettlementTypeFailed'} />}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
      } finally {
        setLoading(false);
      }
    } else {
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.claimNotFound'} />}
          closeHandler={() => {
            setErrorPopup(<></>);
            navigateHome();
          }}
        />
      );
    }
  };

  return (
    <Layout showProgress>
      <Container className='text-center content col-sm-10 col-md-6 col-lg-4 mt-5' fluid>
        <SectionHeader text={<Translate value={'settlementType.title'} />} />
        <Form.Group>
          <Form.Label className='font-weight-bold text-muted'>
            <Translate value={'settlementType.desiredSettlementType'} />
          </Form.Label>
          <Form.Row className='mt-2 d-flex justify-content-start'>
            <Radio
              color={color}
              type='radio'
              value={'REPAIR'}
              label={<Translate value={'settlementType.repair'} />}
              id='settlementType-repair'
              name='settlementType'
              onChange={handleSettlementTypeChange}
              defaultChecked={settlementType === ApiClaimSettlementType.Repair}
              isInvalid={settlementType === null}
            />
          </Form.Row>
          <Form.Row className='mt-2 d-flex justify-content-start'>
            <Radio
              color={color}
              type='radio'
              value={'MONEY'}
              label={<Translate value={'settlementType.money'} />}
              id='settlementType-money'
              name='settlementType'
              onChange={handleSettlementTypeChange}
              defaultChecked={settlementType === ApiClaimSettlementType.Money}
              isInvalid={settlementType === null}
            />
          </Form.Row>
        </Form.Group>
        {errorPopup}
        <NextButton
          className='my-2'
          onClick={submitSettlementType}
          data-testid='submit'
          loading={loading}
        />
      </Container>
    </Layout>
  );
};

const mapDispatchToProps = {
  saveSettlementType: saveSettlementTypeAction,
  navigateToNextStep: goToNextPage,
  navigateHome: () => push('/'),
};

const mapStateToProps = ({ claim, claimIncident, theme, claimSettlementType }: AppState) => ({
  claimId: claim.claimId,
  color: theme.color,
  selectedSettlementType: claimSettlementType.settlementType,
  isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
  incident: claimIncident.incident,
});

export type SettlementTypeProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SettlementType);
