import React, { useEffect, useState } from 'react';
import Layout from '../../components/layouts/Layout';
import Feedback from '../../components/feedback/Feedback';
import { AppState } from '../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Loader } from '../../components/loader/Loader';
import { useParams } from 'react-router-dom';
import './FeedbackView.css';
import { checkFeedbackLinkValidAction, submitFeedbackAction } from './feedbackActions';
import { Translate } from 'react-redux-i18n';
import successIcon from '../../assets/images/success-icon.png';
import failIcon from '../../assets/images/fail-icon.png';
import { getParamFromUrl } from '../../common/utils/URLUtils';
import {
  FeedbackData,
  FeedbackLinkCaseType,
  FeedbackLinkCaseTypeFromJSON,
  FeedbackLinkStatus,
} from '../../api';
import { ModalPopup } from '../../components/controls/ModalPopup';
import { isSupportedLocale } from '../../locale/locale';
import { setLocaleWithFallback } from '../../locale/languageActions';
import { getServiceThemeDataByUuidAndCaseType } from '../../common/theme/themeActions';

interface PathParameters {
  uuid: string;
}

const URL_LANG_PARAM = 'language';

export const FeedbackView: React.FC<FeedbackViewProps> = ({
  color,
  submitFeedback,
  checkFeedbackLinkValid,
  setLocale,
  getThemeDetails,
}: FeedbackViewProps) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [feedbackLinkStatus, setFeedbackLinkStatus] = useState<FeedbackLinkStatus>(
    FeedbackLinkStatus.Valid
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resultPopup, setResultPopup] = useState(<></>);

  const { uuid } = useParams<PathParameters>();
  const caseType = getParamFromUrl('caseType');

  const language = getParamFromUrl(URL_LANG_PARAM);

  useEffect(() => {
    language && isSupportedLocale(language) && setLocale(language);
  }, []);

  useEffect(() => {
    const preloadData = async () => {
      const status = await checkFeedbackLinkValid(uuid, caseType as FeedbackLinkCaseType);
      setFeedbackLinkStatus(status);
    };
    if (
      !caseType ||
      caseType.trim().length == 0 ||
      !Object.values(FeedbackLinkCaseType).includes(
        FeedbackLinkCaseTypeFromJSON(caseType.toLocaleUpperCase())
      )
    ) {
      setFeedbackLinkStatus(FeedbackLinkStatus.NotFound);
      setPageLoading(false);
    } else {
      preloadData();
    }
  }, []);

  useEffect(() => {
    setPageLoading(true);
    getThemeDetails(uuid, caseType as FeedbackLinkCaseType).then(() => setPageLoading(false));
  }, []);

  const onFeedbackSubmitted = async (feedbackData: FeedbackData) => {
    try {
      await submitFeedback(uuid, caseType as FeedbackLinkCaseType, feedbackData);
      setIsSubmitted(true);
    } catch (e: any) {
      setResultPopup(
        <ModalPopup
          message={<Translate value={'feedback.submissionError'} />}
          closeHandler={() => setResultPopup(<></>)}
        />
      );
    }
  };

  function getFeedbackLinkErrorTranslationKey(feedbackLinkStatus: string): string {
    if (feedbackLinkStatus === FeedbackLinkStatus.Expired) return 'feedback.expired';
    if (feedbackLinkStatus === FeedbackLinkStatus.AlreadyGiven) return 'feedback.alreadyGiven';
    if (feedbackLinkStatus === FeedbackLinkStatus.NotFound) return 'feedback.notFound';
    return '';
  }

  return pageLoading ? (
    <Loader />
  ) : (
    <Layout>
      <Container className='content px-0 feedback-page-centered' fluid>
        {FeedbackLinkStatus.Valid === feedbackLinkStatus ? (
          isSubmitted ? (
            <div className={'feedback-page-message'}>
              <div className={'mb-5'}>
                <img src={successIcon} className={'feedback-page-icon'} title='image' />
              </div>
              <Translate value={'feedback.success'} />
            </div>
          ) : (
            <div className='flex-1 mt-5 narrow-md'>
              <Feedback onSubmit={onFeedbackSubmitted} color={color} />
            </div>
          )
        ) : (
          <div className={'feedback-page-message'} data-testid={'feedbackError'}>
            <div className={'mb-5'}>
              <img src={failIcon} className={'feedback-page-icon'} title='image' />
            </div>
            <Translate value={getFeedbackLinkErrorTranslationKey(feedbackLinkStatus)} />
          </div>
        )}
        {resultPopup}
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
  checkFeedbackLinkValid: checkFeedbackLinkValidAction,
  submitFeedback: submitFeedbackAction,
});

const mapDispatchToProp = {
  setLocale: setLocaleWithFallback,
  getThemeDetails: getServiceThemeDataByUuidAndCaseType,
};

export type FeedbackViewProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(FeedbackView);
