/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceIncidentType,
    ApiAssistanceIncidentTypeFromJSON,
    ApiAssistanceIncidentTypeFromJSONTyped,
    ApiAssistanceIncidentTypeToJSON,
    ApiContact,
    ApiContactFromJSON,
    ApiContactFromJSONTyped,
    ApiContactToJSON,
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
} from './';

/**
 * Used for initializating assistance request with data
 * @export
 * @interface ApiAssistanceRequestLinkWithData
 */
export interface ApiAssistanceRequestLinkWithData {
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    serviceExternalId: number;
    /**
     * 
     * @type {ApiAssistanceIncidentType}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    incident: ApiAssistanceIncidentType;
    /**
     * 
     * @type {ApiContact}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    contact: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    licensePlate: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    description: string;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    language: ApiLanguage;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiAssistanceRequestLinkWithData
     */
    images?: Array<string>;
}

export function ApiAssistanceRequestLinkWithDataFromJSON(json: any): ApiAssistanceRequestLinkWithData {
    return ApiAssistanceRequestLinkWithDataFromJSONTyped(json, false);
}

export function ApiAssistanceRequestLinkWithDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAssistanceRequestLinkWithData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceExternalId': json['serviceExternalId'],
        'incident': ApiAssistanceIncidentTypeFromJSON(json['incident']),
        'contact': ApiContactFromJSON(json['contact']),
        'licensePlate': json['licensePlate'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'address': json['address'],
        'description': json['description'],
        'language': ApiLanguageFromJSON(json['language']),
        'images': !exists(json, 'images') ? undefined : json['images'],
    };
}

export function ApiAssistanceRequestLinkWithDataToJSON(value?: ApiAssistanceRequestLinkWithData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceExternalId': value.serviceExternalId,
        'incident': ApiAssistanceIncidentTypeToJSON(value.incident),
        'contact': ApiContactToJSON(value.contact),
        'licensePlate': value.licensePlate,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'address': value.address,
        'description': value.description,
        'language': ApiLanguageToJSON(value.language),
        'images': value.images,
    };
}

