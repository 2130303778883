/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface HandleSmsStatusUpdateRequest {
    smsId: number;
    smsSid?: string;
    smsStatus?: string;
    messageStatus?: string;
    to?: string;
    messageSid?: string;
    accountSid?: string;
    from?: string;
    apiVersion?: string;
}

/**
 * 
 */
export class SmsWebhookApi extends runtime.BaseAPI {

    /**
     * Accepts SMS status reports from SMS service
     */
    async handleSmsStatusUpdateRaw(requestParameters: HandleSmsStatusUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.smsId === null || requestParameters.smsId === undefined) {
            throw new runtime.RequiredError('smsId','Required parameter requestParameters.smsId was null or undefined when calling handleSmsStatusUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.smsSid !== undefined) {
            formParams.append('SmsSid', requestParameters.smsSid as any);
        }

        if (requestParameters.smsStatus !== undefined) {
            formParams.append('SmsStatus', requestParameters.smsStatus as any);
        }

        if (requestParameters.messageStatus !== undefined) {
            formParams.append('MessageStatus', requestParameters.messageStatus as any);
        }

        if (requestParameters.to !== undefined) {
            formParams.append('To', requestParameters.to as any);
        }

        if (requestParameters.messageSid !== undefined) {
            formParams.append('MessageSid', requestParameters.messageSid as any);
        }

        if (requestParameters.accountSid !== undefined) {
            formParams.append('AccountSid', requestParameters.accountSid as any);
        }

        if (requestParameters.from !== undefined) {
            formParams.append('From', requestParameters.from as any);
        }

        if (requestParameters.apiVersion !== undefined) {
            formParams.append('ApiVersion', requestParameters.apiVersion as any);
        }

        const response = await this.request({
            path: `/sms-webhook/{smsId}`.replace(`{${"smsId"}}`, encodeURIComponent(String(requestParameters.smsId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accepts SMS status reports from SMS service
     */
    async handleSmsStatusUpdate(requestParameters: HandleSmsStatusUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.handleSmsStatusUpdateRaw(requestParameters, initOverrides);
    }

}
