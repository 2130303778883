/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAssistanceRequestStatus,
    ApiAssistanceRequestStatusFromJSON,
    ApiAssistanceRequestStatusFromJSONTyped,
    ApiAssistanceRequestStatusToJSON,
    ApiDirection,
    ApiDirectionFromJSON,
    ApiDirectionFromJSONTyped,
    ApiDirectionToJSON,
} from './';

/**
 * driver location info
 * @export
 * @interface ApiDriverLocationAndCaseStatus
 */
export interface ApiDriverLocationAndCaseStatus {
    /**
     * 
     * @type {number}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    speed?: number;
    /**
     * 
     * @type {ApiDirection}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    direction?: ApiDirection;
    /**
     * 
     * @type {number}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    driverId?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    heading?: number;
    /**
     * 
     * @type {ApiAssistanceRequestStatus}
     * @memberof ApiDriverLocationAndCaseStatus
     */
    caseStatus?: ApiAssistanceRequestStatus;
}

export function ApiDriverLocationAndCaseStatusFromJSON(json: any): ApiDriverLocationAndCaseStatus {
    return ApiDriverLocationAndCaseStatusFromJSONTyped(json, false);
}

export function ApiDriverLocationAndCaseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiDriverLocationAndCaseStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'speed': !exists(json, 'speed') ? undefined : json['speed'],
        'direction': !exists(json, 'direction') ? undefined : ApiDirectionFromJSON(json['direction']),
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'heading': !exists(json, 'heading') ? undefined : json['heading'],
        'caseStatus': !exists(json, 'caseStatus') ? undefined : ApiAssistanceRequestStatusFromJSON(json['caseStatus']),
    };
}

export function ApiDriverLocationAndCaseStatusToJSON(value?: ApiDriverLocationAndCaseStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'speed': value.speed,
        'direction': ApiDirectionToJSON(value.direction),
        'driverId': value.driverId,
        'heading': value.heading,
        'caseStatus': ApiAssistanceRequestStatusToJSON(value.caseStatus),
    };
}

