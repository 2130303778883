import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const TermsEnglish: React.FC = () => {
  return (
    <>
      <SectionHeader text={'GENERAL TERMS OF SERVICE'} />
      <div>
        <p>
          <b>Yaway OÜ</b> (registration code 16406514, located at Valukoja 8/1, 11415, Tallinn,
          Estonia) (hereinafter referred to as &quot;<b>Yaway</b>&quot;, &quot;<b>we</b>&quot; and
          &quot;<b>us</b>&quot;) operates the platform at https://yaway.ai and its subdomains,
          mobile applications, associated software, hardware, databases, interfaces, related pages,
          documentation, updates, and other connected or integrated components and materials
          (hereinafter referred to as the &quot;<b>Platform</b>&quot;).
        </p>
        <p>
          The application offers a virtual environment through which a vehicle user can order
          roadside assistance (towing) in case of a problem with the vehicle or report damage that
          has occurred with the vehicle (for insurance claim processing) and, in certain cases, also
          get an overview of the status of the case. Notifications can be received via email or SMS.
        </p>
        <p>
          &quot;<b>User</b>&quot; means a person who uses the Platform to order roadside assistance
          or to report damage that has occurred with the vehicle. &quot;<b>Service Provider</b>
          &quot; refers to a person who uses the Platform to provide roadside assistance services
          (e.g., towing) or acts as an insurer. &quot;<b>Order</b>&quot; is an order submitted by
          the User through the Platform to the Service Provider, for instance, to receive roadside
          assistance or for the handling of a damage claim.
        </p>
        <p>
          Use of the Platform requires agreement to these terms of use (hereinafter referred to as
          &quot;
          <b>Terms</b>&quot;) and familiarity with the privacy terms at{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a> (&quot;<b>Privacy Terms</b>&quot;). By
          using the Platform, the User confirms that they have carefully read the Terms and agrees
          to them. Upon agreeing to the Terms, they become a binding contract (hereinafter referred
          to as the &quot;<b>Agreement</b>&quot;) between you and us.
        </p>
        {NumericSection('1.', <b>PLATFORM AND ITS USE</b>)}
        {NumericSection(
          '1.1.',
          `The Platform creates a virtual environment through which the User can submit
          orders to Service Providers, for instance, to order roadside assistance (towing) in the event of a problem
          with the vehicle or to report damage that has occurred with the vehicle (for damage claim processing),
          and the Service Provider can accept the User's Order.`
        )}
        {NumericSection(
          '1.2.',
          `To use the service, the User must submit an Order on the Platform (via the website or mobile
          application), and the Service Provider must accept (approve) the Order.`
        )}
        {NumericSection(
          '1.3.',
          `The User understands that Yaway only provides an intermediary service through the Platform.
          Yaway is neither an insurer nor a provider of roadside assistance services. Therefore, Yaway is not
          a party to the legal relationship that arises between the User and the Service Provider, and Yaway
          is not responsible for the proper fulfillment of obligations arising from the relationship between
          the User and the Service Provider. Furthermore, Yaway is not responsible for the accuracy or legality
          of the information published by the User on the Platform.`
        )}
        {NumericSection('2.', <b>USER&apos;S RIGHTS AND OBLIGATIONS</b>)}
        {NumericSection('', <b>The User undertakes:</b>)}
        {NumericSection(
          '2.1.',
          `to provide truthful information when using the Platform, including submitting requests under
          their correct and full name, and using a valid email address;`
        )}
        {NumericSection(
          '2.2.',
          `to ensure that the data submitted when using the Platform is not false, misleading, or
          inaccurate, does not infringe upon the rights of third parties, and is not in contradiction
          with applicable law and good customs;`
        )}
        {NumericSection('', <b>The User is prohibited from:</b>)}
        {NumericSection('2.3.', `not to use the Platform for fraud or any other unlawful purpose;`)}
        {NumericSection(
          '2.4.',
          `not to entice others to engage in or participate in any unlawful acts;`
        )}
        {NumericSection(
          '2.5.',
          `not to upload or transmit viruses or any other type of malicious code that may or is used in any
          way that affects the functionality or operation of the Platform;`
        )}
        {NumericSection(
          '2.6.',
          `not to transmit through the Platform any data, orders, comments, or any other content for 
          which the User does not have the right to transmit and publish.`
        )}
        {NumericSection('3.', <b>YAWAY&apos;S RIGHTS AND OBLIGATIONS</b>)}
        {NumericSection(
          '3.1.',
          `Yaway reserves the right, at its discretion, to limit or revoke the User's right to
          use the Platform if:`
        )}
        {NumericSection(
          '3.1.1.',
          `The User significantly breaches the Terms or does not rectify any violation within
          a reasonable deadline given by Yaway;`,
          2
        )}
        {NumericSection(
          '3.1.2.',
          `The User has provided information during the use of the Platform that is false,
          misleading, and/or inaccurate;`,
          2
        )}
        {NumericSection(
          '3.1.3.',
          `For any other reason that gives Yaway cause to doubt the User's reliability.`,
          2
        )}
        {NumericSection(
          '3.2.',
          `Yaway reserves the right to unilaterally modify and supplement the Terms at any time
          in the interest of the Platform's and service's development and to ensure their better and safer use.`
        )}
        {NumericSection(
          '3.3.',
          `The intellectual property rights for the Platform and all its content belong to Yaway.
          By transmitting works protected by copyright or other intellectual property rights (e.g., photos)
          to the Platform, the User grants Yaway a free non-exclusive license with respect to the applicable
          property rights of those works. This means we have the right to use the works submitted by the User
          in any way, permit or prohibit their use on the Platform, reproduce, distribute, translate, adapt,
          include works in collections or databases, and make them available to the public.`
        )}
        {NumericSection('4.', <b>LIABILITY AND COMPENSATION FOR DAMAGE</b>)}
        {NumericSection(
          '4.1.',
          `The content, services, or features made available in connection with or through the Platform
          are provided on an "as is" and "as available" basis, without any expressed or implied warranties.
          We disclaim all warranties to the extent permitted by applicable law.`
        )}
        {NumericSection(
          '4.2.',
          `Yaway does not guarantee or warrant that the Platform is compatible with any hardware or software
          used by any User, is always available or available at any specific time, operates uninterrupted at all times,
          is secure or error-free, meets any of your specific needs, or is free from viruses, disruptions, protected
          against hacking, and other intrusions that reduce security.`
        )}
        {NumericSection(
          '4.3.',
          `Among other things, Yaway is not responsible for, but not limited to, the following circumstances: `
        )}
        {NumericSection(
          '4.3.1.',
          `Any interruption, halt, stoppage, or other unavailability of the Platform or any
          service offered therein;`,
          2
        )}
        {NumericSection(
          '4.3.2.',
          `Any software bugs, viruses, Trojan horses, etc., which anyone might transmit to or through
          the Platform or any service offered therein;`,
          2
        )}
        {NumericSection(
          '4.3.3.',
          `Any deletion of content or data, or inability to save content or data;`,
          2
        )}
        {NumericSection(
          '4.3.4.',
          `Any disclosure, loss, or unauthorized use of login credentials, due to your failure to
          maintain the confidentiality of your credentials;`,
          2
        )}
        {NumericSection('4.3.5.', `The User's behavior while using the Platform;`, 2)}
        {NumericSection(
          '4.3.6.',
          `The incompatibility of technical means used to access the Platform.`,
          2
        )}

        {NumericSection('5.', <b>OTHER PROVISIONS</b>)}
        {NumericSection(
          '5.1.',
          `The legal relationships arising from the use of the Platform between us and the User are
          governed by the laws of the Republic of Estonia.`
        )}
      </div>
    </>
  );
};

export default TermsEnglish;
