import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const PrivacyRussian: React.FC = () => {
  return (
    <>
      <SectionHeader text={'УСЛОВИЯ КОНФИДЕНЦИАЛЬНОСТИ'} />
      <div>
        <p>
          <b>ВВЕДЕНИЕ</b>
        </p>
        <p>
          Данный документ об условиях обработки персональных данных (в дальнейшем именуемый как
          &quot;
          <b>Условия конфиденциальности</b>&quot;) описывает, как Yaway обрабатывает персональные
          данные в рамках своих услуг. Цель Условий конфиденциальности - предоставить ясную и
          прозрачную информацию о обработке персональных данных в соответствии с действующим
          законодательством.
        </p>

        <p>
          Если у вас возникнут дополнительные вопросы о том, как мы обрабатываем ваши персональные
          данные, или если вы хотите отправить запросы на реализацию своих прав, связанных с
          обработкой ваших персональных данных, пожалуйста, свяжитесь с нами, используя контактные
          данные, представленные в разделе &quot;Контакты&quot; ниже.
        </p>

        <p>
          Yaway может время от времени изменять эти Условия конфиденциальности. Текущие Условия
          конфиденциальности всегда публикуются на веб-сайте{' '}
          <a href='https://yaway.ai'>https://yaway.ai</a>.
        </p>

        {NumericSection('1.', <b>ОПРЕДЕЛЕНИЯ</b>)}
        {NumericSection(
          'Субъект данных',
          <>Физическое лицо, данные которого обрабатывает Yaway;</>,
          4
        )}

        {NumericSection(
          'GDPR',
          <>
            Регламент (EU) 2016/679 Европейского парламента и Совета от 27 апреля 2016 года о защите
            физических лиц в связи с обработкой персональных данных и о свободном движении таких
            данных, и об отмене Директивы 95/46/EC (Общий регламент по защите данных);
          </>,
          4
        )}

        {NumericSection(
          'Персональные данные',
          <>
            Любая информация, относящаяся к идентифицированному или идентифицируемому физическому
            лицу («субъект данных»); идентифицируемое физическое лицо - это лицо, которое можно
            идентифицировать, прямо или косвенно, в частности, посредством идентификатора, такого
            как имя, идентификационный номер, данные о местоположении, онлайн-идентификатор или по
            одному или нескольким особенным признакам, характерным для физической, физиологической,
            генетической, психической, экономической, культурной или социальной идентичности этого
            физического лица;
          </>,
          4
        )}

        {NumericSection(
          'Применимое законодательство',
          <>
            Все применимые законодательные акты Европейского Союза и все применимые законодательные
            акты Эстонской Республики, включая, но не ограничиваясь, национальными актами,
            реализующими GDPR, которые действуют в течение срока действия этих Условий
            конфиденциальности или вступают в силу после их установления;
          </>,
          4
        )}

        {NumericSection(
          'Пользователь',
          <>
            кто использует Платформу для заказа дорожной помощи или для сообщения о произошедшем
            ущербе транспортному средству;
          </>,
          4
        )}

        {NumericSection(
          'Yaway',
          <>
            Yaway OÜ, регистрационный код 16406514, расположенный по адресу Valukoja 8/1, 11415,
            Таллинн, Эстония;
          </>,
          4
        )}

        {NumericSection(
          'Платформа',
          <>
            Платформа, управляемая Yaway на веб-сайте{' '}
            <a href='https://yaway.ai'>https://yaway.ai</a>, через которую можно заказать дорожную
            помощь или отправить уведомления о происшествиях с повреждением транспортного средства;
          </>,
          4
        )}

        {NumericSection(
          'Обработка',
          <>
            Любая операция или набор операций, которые выполняются с персональными данными или с
            наборами персональных данных, независимо от того, производятся ли они автоматически,
            такие как сбор, запись, организация, структурирование, хранение, адаптация или
            изменение, извлечение, консультация, использование, раскрытие путем передачи,
            распространение или иное предоставление, выравнивание или объединение, ограничение,
            удаление или уничтожение;
          </>,
          4
        )}

        {NumericSection(
          'Поставщик услуг',
          <>
            это поставщик услуг, которому Пользователь может представить Заказ через Платформу для
            получения дорожной помощи или для обработки инцидента с повреждением транспортного
            средства;
          </>,
          4
        )}

        {NumericSection(
          'Заказ',
          <>
            это заказ, представленный Пользователем через Платформу Поставщику услуг, например, для
            получения дорожной помощи или для обработки заявления о повреждении;
          </>,
          4
        )}

        {NumericSection(
          'Контролер данных',
          <>
            Физическое или юридическое лицо, государственный орган, агентство или другой орган,
            который, самостоятельно или совместно с другими, определяет цели и средства обработки
            персональных данных. В контексте этих Условий контролером данных для персональных данных
            сотрудника является Yaway; в рамках правовых отношений, возникающих между Поставщиком
            услуг и Пользователем через Платформу, контролером данных для персональных данных
            Пользователя является Поставщик услуг;
          </>,
          4
        )}

        {NumericSection(
          'Обработчик данных',
          <>
            Физическое или юридическое лицо, государственный орган, агентство или другой орган,
            который обрабатывает персональные данные от имени контролера данных;
          </>,
          4
        )}

        {NumericSection(
          'Общие условия или Условия',
          <>
            Условия использования, которые применяются к отношениям между Yaway и Пользователями в
            связи с использованием Платформы, доступны на{' '}
            <a href='https://yaway.ai'>https://yaway.ai</a>.
          </>,
          4
        )}

        {NumericSection('2.', <b>КОГДА И ПОЧЕМУ МЫ ОБРАБАТЫВАЕМ ПЕРСОНАЛЬНЫЕ ДАННЫЕ?</b>)}
        {NumericSection(
          '2.1',
          <>
            Если вы решили использовать Платформу Yaway, нам необходимо обработать ваши персональные
            данные, чтобы обеспечить использование Платформы в соответствии с Условиями.
          </>
        )}
        {NumericSection(
          '2.2',
          <>
            Мы обрабатываем персональные данные, которые вы предоставили Yaway в связи с
            использованием Платформы. К этим данным, среди прочего, относятся следующие:
          </>
        )}
        {NumericSection(
          '2.2.1',
          <>
            <u>общие персональные данные</u>: имя (имя, фамилия);
          </>,
          2
        )}
        {NumericSection(
          '2.2.2',
          <>
            <u>контактные данные</u>: адрес электронной почты; номер телефона;
          </>,
          2
        )}
        {NumericSection(
          '2.2.3',
          <>
            <u>Данные, необходимые для заказа дорожной помощи (эвакуации)</u>: данные о транспортном
            средстве (марка, модель, год выпуска, номерной знак), адрес и координаты, описание
            происшествия (описание деталей происшествия, предоставленное Пользователем при
            использовании Платформы);
          </>,
          2
        )}
        {NumericSection(
          '2.2.4',
          <>
            <u>Данные, необходимые для обработки заявления о повреждении</u>: данные о транспортном
            средстве (марка, модель, год выпуска, номерной знак), описание происшествия (описание
            деталей происшествия, предоставленное Пользователем при использовании Платформы);
            фотографии;
          </>,
          2
        )}
        {NumericSection(
          '2.2.5',
          <>
            <u>Другие данные</u>: другие данные, которые Пользователь может передать через
            Платформу;
          </>,
          2
        )}
        {NumericSection(
          '2.2.6',
          <>
            <u>технические данные</u>: технические данные, собранные в связи с предоставлением
            Платформы, которые собираются с использованием файлов cookie (читайте раздел ниже под
            названием &quot;Файлы cookie&quot;).
          </>,
          2
        )}

        {NumericSection(
          '3.',
          <b>КАКОВО ПРАВОВОЕ ОСНОВАНИЕ ДЛЯ ОБРАБОТКИ ВАШИХ ПЕРСОНАЛЬНЫХ ДАННЫХ?</b>
        )}
        {NumericSection(
          '3.1',
          <>
            Мы можем обрабатывать персональные данные для предоставления Платформы в соответствии с
            Условиями Yaway. Правовое основание для такой обработки данных - статья 6 (1) (b) GDPR,
            т.е. обработка персональных данных необходима для исполнения договора, стороной которого
            является субъект данных, или для проведения действий по запросу субъекта данных до
            заключения договора.
          </>
        )}
        {NumericSection(
          '3.2',
          <>
            Мы можем обрабатывать персональные данные на основе согласия, данного субъектом данных.
            Правовое основание для такой обработки данных - статья 6 (1) (a) GDPR. В таких ситуациях
            Yaway обрабатывает персональные данные на условиях, указанных в согласии, которое каждый
            субъект данных дал нам.
          </>
        )}
        {NumericSection(
          '3.3',
          <>
            Мы можем обрабатывать персональные данные, если обработка необходима для выполнения
            юридического обязательства, применимого к нам, например, в бухгалтерских целях в
            соответствии с применимым бухгалтерским законодательством, или если персональные данные
            запрашиваются у Yaway на основании запроса от компетентного органа, например, на
            основании действующего судебного решения. Правовое основание для такой обработки данных
            - статья 6 (1) (c) GDPR.
          </>
        )}
        {NumericSection(
          '3.4',
          <>
            В определенных случаях мы также можем обрабатывать персональные данные, если обработка
            персональных данных необходима для наших законных интересов. Правовое основание для
            такой обработки данных - статья 6 (1) (f) GDPR. В таком случае Yaway гарантирует, что
            обработка пропорциональна и мы провели оценку воздействия нашего законного интереса.
          </>
        )}

        {NumericSection('4.', <b>ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>)}
        {NumericSection(
          '4.1',
          <>
            Yaway не хранит персональные данные дольше, чем это необходимо, исходя из цели обработки
            персональных данных или в соответствии с применимым законодательством. В общем случае мы
            применяем следующие сроки хранения.
          </>
        )}
        {NumericSection(
          '4.2',
          <>
            Персональные данные, связанные с договорами, могут храниться на протяжении срока
            договора и на основании нашего законного интереса согласно статье 6 (1) (f) GDPR до
            истечения обязательных сроков давности, предусмотренных применимым законом. В общем
            случае Yaway хранит данные, собранные в связи с предоставлением услуг, столько, сколько
            это необходимо для предоставления услуг в течение срока действия договора между
            субъектом данных и Yaway, и в течение 3 лет после окончания договора. Как правило, все
            персональные данные, связанные с Пользователем, удаляются через 3 года после выполнения
            заказа, отправленного Пользователем Поставщику услуг, если у нас нет правового основания
            для хранения ваших персональных данных дольше.
          </>
        )}
        {NumericSection(
          '4.3',
          <>
            Данные, собранные на основе согласия, хранятся до отзыва согласия. Если субъект данных
            не отозвал свое согласие, Yaway применяет тот же срок хранения для персональных данных,
            собранных на основе согласия, что и для персональных данных, собранных для поддержки
            Платформы. Как правило, если Платформа не использовалась в течение 3 лет, все
            персональные данные, связанные с пользователем, удаляются, если у нас нет правового
            основания для хранения ваших персональных данных дольше.
          </>
        )}
        {NumericSection(
          '4.4',
          <>
            Персональные данные, связанные с бухгалтерскими исходными документами и бухгалтерскими
            записями, должны храниться в соответствии с соответствующими бухгалтерскими законами.
            Таким образом, на основании бухгалтерского законодательства мы храним бухгалтерские
            документы в течение 7 лет.
          </>
        )}

        {NumericSection('5.', <b>ОБЗОР ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>)}
        {NumericSection(
          '',
          <>
            Yaway обрабатывает персональные данные для следующих конкретных целей в течение
            следующих сроков хранения:
          </>
        )}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='134'>
                  <p>
                    <strong>Цель обработки</strong>
                  </p>
                </td>
                <td width='132'>
                  <p>
                    <strong>Категории персональных данных</strong>
                  </p>
                </td>
                <td width='133'>
                  <p>
                    <strong>Как мы получили персональные данные</strong>
                  </p>
                </td>
                <td width='174'>
                  <p>
                    <strong>Срок хранения</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Заказ услуги по дорожной помощи через платформу (предоставление пользователю
                    возможности отправлять заказ на эвакуацию в соответствии с Условиями и
                    положениями)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Имя, фамилия, адрес электронной почты, тип инцидента, адрес и координаты, данные
                    о транспортном средстве, описание инцидента, фотографии инцидента
                  </p>
                </td>
                <td width='133'>
                  <p>Непосредственно от каждого субъекта данных (Пользователя)</p>
                </td>
                <td width='174'>
                  <p>
                    В течение срока действия Условий Yaway и до 3 лет после завершения договора с
                    субъектом данных, на основе законного интереса Yaway до истечения установленных
                    законом сроков давности
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Через платформу, сообщение о инциденте (предоставление Пользователю возможности
                    отправить заказ на услуги по обработке повреждений в соответствии с Условиями)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Имя, фамилия, адрес электронной почты, тип инцидента, данные о транспортном
                    средстве, описание инцидента, фотографии инцидента
                  </p>
                </td>
                <td width='133'>
                  <p>Непосредственно от каждого субъекта данных (Пользователя)</p>
                </td>
                <td width='174'>
                  <p>
                    В течение срока действия Условий Yaway и до 3 лет после завершения договора,
                    заключенного с субъектом данных, на основе законного интереса Yaway до истечения
                    сроков, указанных в соответствующем законодательстве
                  </p>
                </td>
              </tr>

              <tr>
                <td width='134'>
                  <p>Обработка заказа, сделанного через колл-центр</p>
                </td>
                <td width='132'>
                  <p>
                    Имя, фамилия, адрес электронной почты, тип инцидента, данные о транспортном
                    средстве, описание инцидента и другая информация, которую Пользователь сообщает
                    во время звонка
                  </p>
                </td>
                <td width='133'>
                  <p>Непосредственно от каждого субъекта данных (Пользователя)</p>
                </td>
                <td width='174'>
                  <p>
                    В течение срока действия Условий Yaway и до 3 лет после завершения договора с
                    субъектом данных, на основе законного интереса Yaway до истечения сроков,
                    установленных применимым законодательством
                  </p>
                </td>
              </tr>

              <tr>
                <td width='134'>
                  <p>Ответ на запросы субъекта данных</p>
                </td>
                <td width='132'>
                  <p>
                    Имя, адрес электронной почты и другие личные данные, которые вы непосредственно
                    предоставляете нам, когда связываетесь с нами с запросом или вопросом, будь то
                    через Платформу или любой другой канал (например, отправив электронное письмо)
                  </p>
                </td>
                <td width='133'>
                  <p>Непосредственно от каждого субъекта данных</p>
                </td>
                <td width='174'>
                  <p>
                    До истечения срока давности, связанного с представленным запросом. Обычно этот
                    период составляет 3 года
                  </p>
                </td>
              </tr>

              <tr>
                <td width='134'>
                  <p>
                    Регистрация учетной записи для водителя эвакуатора (сотрудника поставщика услуг)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Имя, фамилия, номер телефона, адрес электронной почты и пароль, выбранный для
                    учетной записи пользователя
                  </p>
                </td>
                <td width='133'>
                  <p>Непосредственно от каждого субъекта данных (водителя эвакуатора)</p>
                </td>
                <td width='174'>
                  <p>
                    В течение срока действия Условий Yaway и до 3 лет после завершения договора со
                    субъектом данных, на основании законных интересов Yaway и в соответствии со
                    сроками истечения, установленными действующим законодательством
                  </p>
                </td>
              </tr>

              <tr>
                <td width='134'>
                  <p>Технические данные</p>
                </td>
                <td colSpan={3} width='441'>
                  <p>См. раздел &quot;Куки&quot;</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {NumericSection('6.', <b>КАК ПОСТАВЩИКИ УСЛУГ ИМЕЮТ ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ?</b>)}
        {NumericSection(
          '6.1',
          <>
            Платформа обеспечивает посредничество в оформлении заказов между пользователями и
            поставщиками услуг.
          </>
        )}
        {NumericSection(
          '6.2',
          <>
            При заказе услуги Yaway передает данные, связанные с заказом пользователя,
            соответствующему поставщику услуг.
          </>
        )}
        {NumericSection(
          '6.3',
          <>
            При размещении заказа поставщик услуг является ответственным обработчиком персональных
            данных пользователя, связанных с выполнением заказа. По вопросам, связанным с обработкой
            персональных данных в связи с обработкой поставщиком услуг, обратитесь к поставщику
            услуг.
          </>
        )}

        {NumericSection(
          '7.',
          <b>КОГДА YAWAY МОЖЕТ ПЕРЕДАВАТЬ ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТРЕТЬИМ ЛИЦАМ?</b>
        )}
        {NumericSection(
          '7.1',
          <>
            Yaway может передавать ваши персональные данные своим уполномоченным обработчикам,
            например, определенным третьим лицам-поставщикам услуг, таким как поставщики IT-услуг
            или другие поставщики услуг.
          </>
        )}
        {NumericSection(
          '7.2',
          <>
            Мы также можем передавать ваши персональные данные третьим лицам, если на это налагается
            юридическая обязанность, например, если какой-либо официальный орган, имеющий
            компетенцию требовать такие данные, требует их у нас, например, в случаях, когда данные
            запрашиваются судом или правоохранительным органом.
          </>
        )}
        {NumericSection(
          '7.3',
          <>
            Yaway не передает персональные данные за пределы Европейского союза или Европейской
            экономической зоны, кроме случаев, когда у нас есть законное основание и для целей,
            объясненных в этих принципах конфиденциальности. При передаче ваших персональных данных
            в третьи страны мы обеспечиваем применение соответствующих мер безопасности и защиту
            ваших прав, например, используя стандартные контрактные условия Европейской комиссии для
            передачи персональных данных в третьи страны (то есть стандартные контрактные условия).
            Вы можете запросить копию мер безопасности, которые мы применили в отношении передачи
            персональных данных, связавшись с нами по контактным данным, указанным ниже.
          </>
        )}

        {NumericSection('8.', <b>КАК YAWAY ЗАЩИЩАЕТ ВАШИ ПЕРСОНАЛЬНЫЕ ДАННЫЕ?</b>)}
        {NumericSection(
          '',
          <>
            Для защиты ваших персональных данных от несанкционированного доступа, неправомерной
            обработки или раскрытия, случайной утраты, изменения или уничтожения, мы применяем
            соответствующие технические и организационные меры в соответствии с действующим
            законодательством. К этим мерам относятся, помимо прочего, внедрение подходящих систем
            компьютерной безопасности, защита файлов в бумажной и электронной форме техническими и
            физическими средствами, а также контроль и ограничение доступа к документам и зданиям.
          </>
        )}

        {NumericSection('9.', <b>COOKIES (ФАЙЛЫ COOKIE)</b>)}
        {NumericSection(
          '9.1',
          <>
            Платформа Yaway использует файлы cookie. В этом разделе описаны наши принципы
            использования файлов cookie (<b>принципы использования файлов cookie</b>), которые
            применяются при использовании платформы.
          </>
        )}
        {NumericSection(
          '9.2',
          <>
            Файлы cookie - это небольшие текстовые файлы, которые сохраняются на вашем жестком диске
            веб-сайтом. Файлы cookie помогают нам отслеживать и улучшать функциональность и
            использование нашей платформы, а также ваш опыт работы на платформе. Мы можем
            использовать файлы cookie, чтобы увидеть, какие области и функции популярны и подсчитать
            посещения нашей платформы, идентифицировать вас как возвращающегося пользователя и
            настроить ваш опыт на платформе согласно вашим предпочтениям. Мы также можем
            использовать файлы cookie для целей таргетирования или рекламы.
          </>
        )}
        {NumericSection('9.3', <>На нашей платформе мы используем следующие типы файлов cookie:</>)}
        {NumericSection(
          '9.3.1',
          <>
            <u>Необходимые файлы cookie</u>, которые абсолютно необходимы для использования
            платформы и ее функций.
          </>,
          2
        )}
        {NumericSection(
          '9.3.2',
          <>
            <u>Функциональные файлы cookie</u>, которые сохраняют информацию о ваших выборах и
            позволяют настраивать платформу согласно вашим потребностям. Функциональные файлы cookie
            сохраняют ваши выборы. Функциональные файлы cookie, которые мы используем, сохраняют
            адрес электронной почты после входа в систему, поэтому если вы входите в систему на
            платформу с несколькими адресами электронной почты, мы можем предложить вам объединить
            разные учетные записи платформы, что должно упростить ваши процессы.
          </>,
          2
        )}
        {NumericSection(
          '9.3.3',
          <>
            <u>Аналитические файлы cookie</u>, которые сохраняют информацию о том, как используется
            наша платформа, чтобы получить информацию о том, как часто посещается наша платформа,
            где на нашей платформе посетители проводят больше всего времени, как часто
            взаимодействие происходит со страницей или частью страницы, что позволяет нам изменять
            структуру платформы, навигацию и содержание так, чтобы они были максимально удобными для
            пользователя.
          </>,
          2
        )}
        {NumericSection(
          '9.3.4',
          <>
            <u>Рекламные файлы cookie</u>, которые позволяют рекламодателям отслеживать, как вы
            используете портал, чтобы нацелить рекламу и контент, который может быть для вас
            наиболее интересным.
          </>,
          2
        )}
        {NumericSection('9.4', <>Конкретные файлы cookie, используемые на платформе, таковы:</>)}

        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='188'>
                  <p>
                    <strong>Cookie (Файлы cookie)</strong>
                  </p>
                </td>
                <td width='139'>
                  <p>
                    <strong>Описание</strong>
                  </p>
                </td>
                <td width='135'>
                  <p>
                    <strong>Длительность</strong>
                  </p>
                </td>
                <td width='138'>
                  <p>
                    <strong>Тип</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_ga</p>
                </td>
                <td width='139'>
                  <p>
                    Этот файл cookie устанавливается Google Analytics. Файл cookie используется для
                    расчета данных о посетителях, сессиях и кампаниях и для отслеживания
                    использования сайта для аналитического отчета сайта. Файлы cookie хранят данные
                    анонимно и присваивают случайно сгенерированный номер для идентификации каждого
                    посетителя.
                  </p>
                </td>
                <td width='135'>
                  <p>2 года</p>
                </td>
                <td width='138'>
                  <p>Аналитика</p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_gid</p>
                </td>
                <td width='139'>
                  <p>
                    Этот файл cookie устанавливается Google Analytics. Файл cookie используется для
                    хранения информации о том, как посетители используют веб-сайт, и помогает
                    составить аналитический отчет о работе веб-сайта. Собранные данные включают,
                    помимо прочего, количество посетителей, их источник происхождения и посещенные
                    страницы в анонимной форме.
                  </p>
                </td>
                <td width='135'>
                  <p>1 день</p>
                </td>
                <td width='138'>
                  <p>Аналитика</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {NumericSection(
          '9.5',
          <>
            Вы можете в любое время удалить или заблокировать файлы cookie в настройках вашего
            веб-браузера. Однако некоторые файлы cookie могут быть необходимы для функционирования
            платформы. Поэтому вы должны понимать, что блокируя или удаляя файлы cookie, некоторые
            части платформы могут работать некорректно.
          </>
        )}
        {NumericSection(
          '9.6',
          <>
            Для получения более общей информации о файлах cookie, включая различия между сессионными
            файлами cookie и постоянными файлами cookie, посетите{' '}
            <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>
          </>
        )}
        {NumericSection(
          '9.7',
          <>
            Если у вас есть вопросы касательно политики использования файлов cookie, вы можете
            связаться с нами, используя предоставленные ниже контактные данные.
          </>
        )}

        {NumericSection('10.', <b>ВАШИ ПРАВА</b>)}
        {NumericSection(
          '10.1',
          <>
            Yaway старается гарантировать, что все права субъектов данных, вытекающие из
            действующего законодательства, всегда обеспечиваются вам. В частности, каждый субъект
            данных имеет:
          </>,
          2
        )}
        {NumericSection(
          '10.1.1',
          <>право на доступ к персональным данным, которые Yaway обрабатывает о вас;</>,
          2
        )}
        {NumericSection(
          '10.1.2',
          <>право требовать, чтобы Yaway исправил любые неточные персональные данные;</>,
          2
        )}
        {NumericSection(
          '10.1.3',
          <>
            право требовать, чтобы Yaway удалил ваши персональные данные и/или ограничил обработку
            ваших персональных данных, если у нас нет действительной правовой основы для их
            обработки;
          </>,
          2
        )}
        {NumericSection(
          '10.1.4',
          <>
            право получать обработанные вами персональные данные в структурированном, обычно
            используемом и машиночитаемом формате, а также право передавать ваши персональные данные
            другому контролеру данных;
          </>,
          2
        )}
        {NumericSection(
          '10.1.5',
          <>право возражать против обработки ваших персональных данных;</>,
          2
        )}
        {NumericSection(
          '10.2',
          <>
            Если вы считаете, что ваши права были нарушены, вы можете обратиться в надзорный орган
            вашей юрисдикции (в Эстонии это Инспекция по защите данных, адрес: Tatari 39, Таллинн
            10134, <a href='mailto:info@aki.ee'>info@aki.ee</a>).
          </>,
          2
        )}

        {NumericSection('11.', <b>ПРИМЕНИМОЕ ПРАВО И ЮРИСДИКЦИЯ</b>)}
        {NumericSection(
          '',
          <>
            К Политике конфиденциальности применяются законы Эстонской Республики. Любые споры,
            возникающие из принципов конфиденциальности, будут разрешаться в Гарьюском окружном суде
            Эстонской Республики, если у вас нет законного права обратиться в суд вашего места
            жительства.
          </>
        )}

        {NumericSection('12.', <b>КОНТАКТНАЯ ИНФОРМАЦИЯ</b>)}
        {NumericSection(
          '',
          <>
            Если у вас есть вопросы касательно принципов конфиденциальности или принципов
            использования файлов cookie, или опасения относительно того, как мы используем ваши
            персональные данные, или если вы хотите реализовать свои права таким образом, как это
            описано выше, пожалуйста, свяжитесь с нами по электронной почте или письменно, используя
            предоставленные ниже контактные данные.
          </>
        )}

        <p>
          <b>YAWAY OÜ</b>
        </p>
        <p>
          Электронная почта: <a href='mailto:hello@yaway.ai'>hello@yaway.ai</a>
        </p>
        <p>Адрес: Valukoja 8/1, 11415 Таллинн</p>
      </div>
    </>
  );
};

export default PrivacyRussian;
