import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiVehicleDetailsPreview } from '../../../api';

export interface VehicleDetailsState {
  plateNumber: string;
  vinCode: string;
  mark: string;
  model: string;
  year: string;
  error: string;
}

const initialState: VehicleDetailsState = {
  plateNumber: '',
  vinCode: '',
  mark: '',
  model: '',
  year: '',
  error: '',
};

const vehicleDetailsSlice = createSlice({
  name: 'vehicleDetails',
  initialState,
  reducers: {
    setVehicleDetails: (
      state: VehicleDetailsState,
      action: PayloadAction<ApiVehicleDetailsPreview>
    ) => {
      if (action.payload) {
        state.plateNumber = action.payload.licensePlate || '';
        state.vinCode = action.payload.vinCode || '';
        state.mark = action.payload.manufacturer || '';
        state.model = action.payload.model || '';
        state.year = action.payload.year ? action.payload.year.toString() : '';
      }
    },
    clearVehicleDetails: (state: VehicleDetailsState) => {
      state.plateNumber = initialState.plateNumber;
      state.vinCode = initialState.vinCode;
      state.mark = initialState.mark;
      state.model = initialState.model;
      state.year = initialState.year;
      state.error = initialState.error;
    },
    showError: (state: VehicleDetailsState, error: PayloadAction<string>) => {
      clearVehicleDetails();
      state.error = error.payload;
    },
    clearError: (state: VehicleDetailsState) => {
      state.error = initialState.error;
    },
  },
});

const { actions, reducer } = vehicleDetailsSlice;

export const { setVehicleDetails, clearVehicleDetails, showError, clearError } = actions;

export default reducer;
