import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

export interface DatePickerProps {
  label: JSX.Element;
  startDate?: Date | undefined;
  onChange: (e: Date) => void;
  cssClass?: string;
}

export const DateInput: React.FC<DatePickerProps> = ({
  label,
  onChange,
  startDate,
  cssClass,
}: DatePickerProps) => {
  return (
    <>
      {label}
      <DatePicker
        className={cssClass}
        selected={startDate}
        onChange={onChange}
        maxDate={new Date()}
        dateFormat='dd/MM/yyyy'
      />
    </>
  );
};
