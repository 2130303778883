import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import './FileUploader.css';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IoIosRemoveCircle } from 'react-icons/io';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ModalPopup } from '../controls/ModalPopup';
import { Translate } from 'react-redux-i18n';
import heic2any from 'heic2any';
import { IMAGE_HEIC_FORMAT, IMAGE_JPEG_EXTENSION } from '../../common/utils/ImageUtils';

export interface FileUploaderProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string | JSX.Element;
  mode?: 'update' | 'append';
  accept: string;
  maxSize?: number | undefined;
}

export const FileUploader: React.FC<FileUploaderProps> = (props: FileUploaderProps) => {
  const { name, accept, mode = 'update', label = 'Files', maxSize = 10485760 } = props;
  const { register, unregister, setValue, watch } = useFormContext();
  const files: File[] = watch(name as string);
  const [errorPopup, setErrorPopup] = useState(<></>);
  const MAX_IMAGE_COUNT = 9;

  const showErrorModal = () => {
    setErrorPopup(
      <ModalPopup
        message={<Translate value='photos.photoUploadLimitReached' number={MAX_IMAGE_COUNT} />}
        closeHandler={() => {
          setErrorPopup(<></>);
        }}
      />
    );
  };
  const onDrop = useCallback(
    (droppedFiles) => {
      const maxImageCount =
        files && files.length > 0 ? MAX_IMAGE_COUNT - files.length : MAX_IMAGE_COUNT;
      if (droppedFiles.length > maxImageCount) {
        droppedFiles = droppedFiles.slice(0, maxImageCount);
        showErrorModal();
      }
      let newFiles = mode === 'update' ? droppedFiles : [...(files || []), ...droppedFiles];
      if (mode === 'append') {
        newFiles = newFiles.reduce(async (prev: any, file: any) => {
          if (file.type === IMAGE_HEIC_FORMAT) {
            file = await heic2any({
              blob: file,
              toType: IMAGE_JPEG_EXTENSION,
              quality: 0.5,
            });
          }
          prev = await prev;
          newFiles = [...prev, file];
          setValue(name as string, newFiles, { shouldValidate: true });
          return [...prev, file];
        }, []);
      }
    },
    [setValue, name, mode, files]
  );

  const fileValidator = (file: File) => {
    const errors: FileError[] = [];

    if (file.size > maxSize) {
      errors.push({
        code: 'size too big',
        message: `File size should be smaller than ${maxSize / 1024 / 1024} MB`,
      });
    }

    return errors.length == 0 ? null : errors;
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: accept,
    validator: fileValidator,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }: FileRejection) => (
    // @ts-ignore
    <li key={file.name} className='error'>
      {file.name}
      <ul>
        {errors.map((e: FileError) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  useEffect(() => {
    register(name as string);
    return () => {
      unregister(name as string);
    };
  }, [register, unregister, name]);

  const handleRemoveFile = (fileName: string) => {
    const afterRemovingFiles = files.filter((file: File) => file.name !== fileName);
    setValue(name as string, afterRemovingFiles, { shouldValidate: true });
  };

  return (
    <>
      <div className='text-muted'>{label}</div>
      <div className='file-uploader'>
        {files && files.length > 0 ? (
          <Row>
            {files.length < MAX_IMAGE_COUNT && (
              <Col xs={4} md={3} {...getRootProps()} className='mb-2'>
                <input {...props} id={name} {...getInputProps()} />
                <div className='text-center'>
                  <AiOutlineCloudUpload size={50} />
                </div>
                <p className='text-center text-muted text-sm'>
                  <Translate value={'additionalDetails.addPhotos'} />
                </p>
              </Col>
            )}
            {files.map((file: File, index: number) => (
              <Col xs={4} md={3} key={index} className='mb-2'>
                <Container className='my-auto'>
                  <img src={URL.createObjectURL(file)} alt={file.name} width='100%' />
                  <IoIosRemoveCircle
                    className='remove text-danger'
                    size={25}
                    onClick={() => handleRemoveFile(file.name)}
                    data-testid='removeBtn'
                  />
                </Container>
              </Col>
            ))}
          </Row>
        ) : (
          <div {...getRootProps()}>
            <input {...props} id={name} {...getInputProps()} data-testId='uploadBtn' />
            <div className='text-center'>
              <AiOutlineCloudUpload size={50} />
            </div>
            <p className='text-center text-muted text-sm' data-testId={'addPhotosLabel'}>
              <Translate value={'additionalDetails.addPhotos'} />
            </p>
          </div>
        )}
        {fileRejectionItems}
        {errorPopup}
      </div>
    </>
  );
};
