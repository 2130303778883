import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const WindshieldDamage: React.FC<ThemeProps> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='62'
        height='42'
        viewBox='0 0 62 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M61.6754 10.9423C61.5618 10.8115 58.8285 7.71986 53.1106 4.84752C52.5757 4.57965 51.9299 4.71808 51.5452 5.18332L46.7986 10.9718C46.5122 11.3218 46.4239 11.796 46.5665 12.2279L48.8963 19.3328L45.5802 22.7038L46.4329 20.1032C46.5591 19.7186 46.5035 19.2969 46.2827 18.9598L41.5324 11.7179L45.5512 3.55046C45.7254 3.19547 45.7329 2.78017 45.5714 2.41989C45.41 2.05972 45.0971 1.79184 44.72 1.69316C40.4503 0.569103 35.8336 0 30.9999 0C9.98904 0 0.705177 10.5015 0.319131 10.9488C0.0340779 11.2796 -0.0681248 11.7332 0.0454607 12.1575L7.79543 41.0331C7.90902 41.4548 8.2218 41.7894 8.62916 41.929C9.03276 42.0726 9.48565 41.9944 9.8275 41.7303C9.8918 41.6803 16.4385 36.7506 30.9999 36.7506C45.5614 36.7506 52.1081 41.6803 52.1724 41.7303C52.4006 41.9059 52.6782 41.9982 52.9583 41.9982C53.097 41.9982 53.237 41.9764 53.3707 41.9289C53.7782 41.7892 54.091 41.4547 54.2044 41.033L61.9544 12.1574C62.0689 11.7305 61.9642 11.2743 61.6754 10.9423Z'
          fill='black'
        />
        <path
          d='M11.6401 8.90594C11.0018 8.58294 10.2261 8.84442 9.90687 9.49301L7.32358 14.7431C7.0045 15.3917 7.26304 16.18 7.90132 16.5044C8.08671 16.5979 8.28349 16.6428 8.47772 16.6428C8.95205 16.6428 9.40736 16.3775 9.63441 15.9173L12.2177 10.6671C12.5369 10.0186 12.2784 9.2303 11.6401 8.90594Z'
          fill={color}
        />
        <path
          d='M16.8068 8.90594C16.1685 8.58294 15.394 8.84442 15.0736 9.49301L9.90702 19.9932C9.58794 20.6417 9.84647 21.43 10.4848 21.7544C10.6701 21.8479 10.8669 21.8928 11.0612 21.8928C11.5355 21.8928 11.9908 21.6275 12.2178 21.1673L17.3845 10.6671C17.7036 10.0185 17.4451 9.2303 16.8068 8.90594Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(WindshieldDamage);
