import { Dispatch } from 'redux';
import { FeedbackLinkCaseType, themeApi } from '../../apis';
import { ClientError } from '../exception/ClientError';
import { setTheme } from './themeReducer';
import { SERVICE_THEME_NOT_FOUND } from '../exception/ErrorMessages';
import { isSupportedLanguageByValue } from '../../locale/locale';
import { setLocale } from 'react-redux-i18n';
import { getLanguageTypeFromEnum } from '../../locale/languageMap';
import { ServiceStorage } from '../ServiceStorage';
import { setCountry } from '../../locale/localeReducer';
import {
  setServiceCommunicationPreferences,
  setServiceContactForAssistanceRequest,
  setServiceId,
} from '../../pages/assistance-requests/assistanceRequestReducer';
import {
  resetClaim,
  setIsMotionsCloudEnabled,
  setServiceContactForClaim,
  setShowInsuranceSelection,
} from '../../pages/claims/claimReducer';
import { NetworkError } from '../exception/NetworkError';
import store from '../../store/store';

export const getServiceThemeDataByAssistanceRequestId =
  (id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const service = await themeApi.getServiceDetailsByAssistanceId({ id });
      dispatch(setCountry(service.countryCode));
      dispatch(setServiceContactForAssistanceRequest(service.serviceDirectContact));
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      dispatch(setServiceId(service.serviceId));
      service.theme && dispatch(setTheme(service.theme));
      ServiceStorage.setTestService(service.isTestService);
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      throw Error(SERVICE_THEME_NOT_FOUND);
    }
  };

export const getServiceThemeDataByClaimId =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      store.dispatch(resetClaim());
      const service = await themeApi.getServiceDetailsByClaimId({ id });
      dispatch(setCountry(service.countryCode));
      dispatch(setServiceContactForClaim(service.serviceDirectContact));
      dispatch(setIsMotionsCloudEnabled(service.isMotionsCloudEnabled));
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      if (service.showInsuranceSelection)
        dispatch(setShowInsuranceSelection(service.showInsuranceSelection));
      service.theme && dispatch(setTheme(service.theme));
      ServiceStorage.setTestService(service.isTestService);
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      throw Error(SERVICE_THEME_NOT_FOUND);
    }
  };

export const getServiceThemeDataByUuidAndCaseType =
  (uuid: string, caseType: FeedbackLinkCaseType) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      store.dispatch(resetClaim());
      const request = { uuid: uuid, caseType: caseType };
      const service = await themeApi.getServiceDetailsByFeedbackUuidAndCaseType(request);
      if (service.showInsuranceSelection)
        dispatch(setShowInsuranceSelection(service.showInsuranceSelection));
      dispatch(setCountry(service.countryCode));
      dispatch(setServiceContactForClaim(service.serviceDirectContact));
      dispatch(setIsMotionsCloudEnabled(service.isMotionsCloudEnabled));
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      dispatch(setServiceId(service.serviceId));
      service.theme && dispatch(setTheme(service.theme));
      ServiceStorage.setTestService(service.isTestService);
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      throw Error(SERVICE_THEME_NOT_FOUND);
    }
  };

export const getServiceThemeDataByServiceExternalId =
  (serviceExternalId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      store.dispatch(resetClaim());
      const service = await themeApi.getServiceDetailsByServiceExternalId({ serviceExternalId });
      const { theme, serviceLink } = service;
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      dispatch(setServiceId(service.serviceId));
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      if (service.showInsuranceSelection)
        dispatch(setShowInsuranceSelection(service.showInsuranceSelection));
      theme && dispatch(setTheme(theme));
      serviceLink &&
        serviceLink.language &&
        isSupportedLanguageByValue(serviceLink.language) &&
        // @ts-ignore
        dispatch(setLocale(getLanguageTypeFromEnum(serviceLink.language)));
      ServiceStorage.setTestService(service.isTestService);
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      throw Error(SERVICE_THEME_NOT_FOUND);
    }
  };

export const getServiceThemeDataByServiceName =
  (serviceName: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      store.dispatch(resetClaim());
      const service = await themeApi.getServiceDetailsByService({ serviceName });
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      const { theme, serviceLink } = service;
      theme && dispatch(setTheme(theme));
      dispatch(setServiceCommunicationPreferences(service.communicationPreferences));
      if (service.showInsuranceSelection)
        dispatch(setShowInsuranceSelection(service.showInsuranceSelection));
      serviceLink &&
        serviceLink.language &&
        isSupportedLanguageByValue(serviceLink.language) &&
        // @ts-ignore
        dispatch(setLocale(getLanguageTypeFromEnum(serviceLink.language)));
      ServiceStorage.setTestService(service.isTestService);
    } catch (err) {
      if (err instanceof ClientError || err instanceof NetworkError) throw err;
      throw Error(SERVICE_THEME_NOT_FOUND);
    }
  };
