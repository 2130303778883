import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { TextInput } from '../../../components/controls/TextInput';
import { FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getLocalizedMessageElement } from '../../../locale/locale';
import { PartyState } from './OtherParties';
import { useDispatch } from 'react-redux';
import { setPartyErrorAction } from './otherPartiesActions';
import { PhoneNumberInput } from '../../../components/controls/PhoneNumberInput';
import { countryCodes, countryListForCodes } from '../../commonConstants';
import { phoneNumberFormat, INVALID_PHONE_NUMBER } from '../../../common/FormValidations';

const validationSchema = yup.object().shape({
  licensePlate: yup.string().required('Required field'),
  phone: yup.string().matches(phoneNumberFormat, {
    excludeEmptyString: true,
    message: INVALID_PHONE_NUMBER,
  }),
});

export interface VehiclePartyFormProps {
  country: string;
  handlePartyHeader: (data: string, formData: PartyState) => void;
  formData: PartyState;
  setErrors: (errors: FieldErrors<any>, formData: PartyState) => void;
}

export const VehiclePartyForm: React.FC<VehiclePartyFormProps> = ({
  country,
  formData,
  handlePartyHeader,
  setErrors,
}: VehiclePartyFormProps) => {
  const { submitCount } = formData;
  const { register, handleSubmit, errors, getValues } = useForm<any>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      licensePlate: formData.vehicle?.licensePlate,
      fullName: formData.person?.fullName,
      personalCode: formData.person?.personalCode,
      phone: formData.person?.phone,
      email: formData.person?.email,
    },
  });

  const [countryCode, setCountryCode] = useState(country);
  const dispatch = useDispatch();

  const formSubmit = (data: any) => {
    const party: PartyState = {
      ...formData,
      vehicle: {
        ...formData.vehicle,
        licensePlate: data.licensePlate as string,
      },
      person: {
        ...formData.person,
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        phonePrefix: data.phone ? countryCodes[countryCode] : '',
        personalCode: data.personalCode,
      },
    };
    party.submit(party);
  };

  useEffect(() => {
    if (submitCount > 0) {
      handleSubmit(formSubmit)();
    }
  }, [submitCount]);

  useEffect(() => {
    setErrors(errors, formData);
    if (Object.keys(errors).length > 0) {
      dispatch(
        setPartyErrorAction(formData.id, {
          errors: errors,
          vehicle: {
            licensePlate: getValues('licensePlate'),
          },
          person: {
            fullName: getValues('fullName'),
            personalCode: getValues('personalCode'),
            phone: getValues('phone'),
            phonePrefix: countryCodes[countryCode],
            email: getValues('email'),
          },
          id: formData.id,
        })
      );
    }
  }, [errors]);

  return (
    <Form className='mx-auto' onSubmit={handleSubmit(formData.submit)}>
      <TextInput
        cssClass='text-uppercase'
        label={<Translate value='otherParties.vehicleParty.number' />}
        name={`licensePlate`}
        controlId='vehiclePartyForm.licensePlate'
        ref={register}
        testId='licensePlate'
        onChangeHandler={() => handlePartyHeader(getValues(`licensePlate`), formData)}
        isInvalid={!!errors.licensePlate}
        validationError={getLocalizedMessageElement(errors.licensePlate?.message)}
      />

      <TextInput
        label={<Translate value='otherParties.vehicleParty.name' />}
        name={`fullName`}
        controlId='vehiclePartyForm.name'
        ref={register}
      />

      <TextInput
        label={<Translate value='otherParties.vehicleParty.personalCode' />}
        name={`personalCode`}
        controlId='vehiclePartyForm.personalCode'
        ref={register}
      />

      <PhoneNumberInput
        label={<Translate value='otherParties.vehicleParty.phoneNumber' />}
        name={`phone`}
        controlId='vehiclePartyForm.phoneNumber'
        ref={register}
        testId={'phoneNumber'}
        onCountryCodeChange={(code: string) => setCountryCode(code)}
        countryCode={countryCode}
        countryCodes={countryCodes}
        countryListForCodes={countryListForCodes}
        isInvalid={!!errors.phone}
      />

      <TextInput
        label={<Translate value='otherParties.vehicleParty.email' />}
        name={`email`}
        controlId='vehiclePartyForm.email'
        ref={register}
      />
    </Form>
  );
};

export default VehiclePartyForm;
