/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DamageAssessmentRegistrationResponse
 */
export interface DamageAssessmentRegistrationResponse {
    /**
     * 
     * @type {string}
     * @memberof DamageAssessmentRegistrationResponse
     */
    redirectionUrl?: string;
}

export function DamageAssessmentRegistrationResponseFromJSON(json: any): DamageAssessmentRegistrationResponse {
    return DamageAssessmentRegistrationResponseFromJSONTyped(json, false);
}

export function DamageAssessmentRegistrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DamageAssessmentRegistrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectionUrl': !exists(json, 'redirectionUrl') ? undefined : json['redirectionUrl'],
    };
}

export function DamageAssessmentRegistrationResponseToJSON(value?: DamageAssessmentRegistrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectionUrl': value.redirectionUrl,
    };
}

