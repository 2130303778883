import React, { ReactComponentElement } from 'react';
import { Card } from 'react-bootstrap';

export interface IncidentProps {
  icon: ReactComponentElement<any>;
  text: string | JSX.Element;
  onClick?: () => void;
  isSelected?: boolean;
  color?: string;
}

const Incident: React.FC<IncidentProps> = ({
  icon,
  text,
  onClick,
  isSelected,
  color,
}: IncidentProps) => {
  const getStyle = () => {
    if (isSelected)
      return {
        textDecorationLine: 'underline',
        textDecorationThickness: '6px',
        textDecorationColor: color,
      };
  };

  return (
    <>
      <div onClick={onClick}>
        <Card className='square-card mx-auto my-2' data-testid={text}>
          <Card.Body className='text-center'>
            <Card.Text>{icon}</Card.Text>
          </Card.Body>
        </Card>
        <p className='incident-text' style={getStyle()}>
          {text}
        </p>
      </div>
    </>
  );
};

export default Incident;
