/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiClaimSettlementType {
    Money = 'MONEY',
    Repair = 'REPAIR'
}

export function ApiClaimSettlementTypeFromJSON(json: any): ApiClaimSettlementType {
    return ApiClaimSettlementTypeFromJSONTyped(json, false);
}

export function ApiClaimSettlementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimSettlementType {
    return json as ApiClaimSettlementType;
}

export function ApiClaimSettlementTypeToJSON(value?: ApiClaimSettlementType | null): any {
    return value as any;
}

