import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store/store';
import { Redirect, Route, Switch } from 'react-router';
import AssistanceRoute from './AssistanceRoute';
import ClaimRoute from './ClaimRoute';
import Landing from '../pages/landing/Landing';
import { AssistanceRoutes, ClaimRoutes, RouteName } from './RouteNames';
import AssistanceRequestPartnerLanding from '../pages/partner/assistance-request/AssistanceRequestPartnerLanding';
import AssistanceLinkValidation from '../pages/assistance-requests/link/LinkValidation';
import ClaimLinkValidation from '../pages/claims/link/LinkValidation';
import ClaimPartnerLanding from '../pages/partner/claims/ClaimPartnerLanding';
import { Loader } from '../components/loader/Loader';
import PartnerLanding from '../pages/partner/partnerLanding/PartnerLanding';
import FeedbackView from '../pages/feedback/FeedbackView';

const CaseStatusView = lazy(() => import('../pages/cases/case-status/CaseStatus'));
const CaseProgressView = lazy(() => import('../pages/cases/case-progress/CaseProgress'));
const WorkshopExternalSelectionView = lazy(
  () => import('../pages/claims/select-worshop-external/WorkshopExternalSelection')
);

export const Router: React.FC = () => {
  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path='/assistance-request-link/:code' component={AssistanceLinkValidation} />
          <Route path='/claim-link/:code' component={ClaimLinkValidation} />
          <Route
            path='/partner/assistance-request/:serviceLinkName'
            component={AssistanceRequestPartnerLanding}
          />
          <Route path='/partner/claim/:serviceLinkName' component={ClaimPartnerLanding} />
          {ClaimRoutes.map((route: RouteName) => (
            /*@ts-ignore*/
            <ClaimRoute path={route.path} component={route.component} key={route.path} />
          ))}
          {AssistanceRoutes.map((route: RouteName) => (
            /*@ts-ignore*/
            <AssistanceRoute path={route.path} component={route.component} key={route.path} />
          ))}
          <Route path='/partner/:serviceLinkName' component={PartnerLanding} />
          <Route path='/cases/status' component={CaseStatusView} />
          <Route path='/cases/progress' component={CaseProgressView} />
          <Route path='/feedback/:uuid' component={FeedbackView} />
          <Route path='/select-workshop-external' component={WorkshopExternalSelectionView} />
          <Route path='/' component={Landing} />
          <Redirect from='*' to='/' />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};
