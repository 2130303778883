import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import Rating from '@mui/material/Rating';
import Form from 'react-bootstrap/Form';
import { NextButton } from '../controls/NextButton';
import './Feedback.css';
import { FeedbackData } from '../../api';

export interface FeedbackProps {
  onSubmit: (data: FeedbackData) => Promise<void>;
  color: string;
}

const Feedback: React.FC<FeedbackProps> = ({ onSubmit, color }: FeedbackProps) => {
  const [stars, setStars] = useState(0);
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    await onSubmit({ stars: stars, comment: comments });
    setLoading(false);
  };

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  return (
    <Container className='content px-0' fluid>
      <Col>
        <Row className='veh-dtl-row justify-content-center' data-testid='feedbackTitle'>
          <p className='mr-1 feedback-title'>
            <Translate value={'feedback.title'} />
          </p>
        </Row>
        <Row className='veh-dtl-row justify-content-center' data-testid='feedbackSubText'>
          <p className='mr-1 feedback-subtext'>
            <Translate value={'feedback.subText'} />
          </p>
        </Row>

        <div className={'row justify-content-center'}>
          <Rating
            data-testid={'feedbackRating'}
            classes={{ sizeLarge: 'test' }}
            onChange={(event, newValue) => {
              setStars(newValue != null ? newValue : 0);
            }}
            size={'large'}
            sx={{
              fontSize: '4rem',
            }}
          />
        </div>

        <Form className='my-4' noValidate onSubmit={handleSubmit}>
          <Form.Group controlId='description'>
            <Form.Label className='text-muted'>
              <Translate value={'feedback.commentLabel'} />
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={5}
              onChange={handleCommentChange}
              data-testid='feedbackComments'
            />
          </Form.Group>

          <NextButton
            color={color}
            type='submit'
            data-testid='submitBtn'
            loading={loading}
            text={<Translate value={'submit'} />}
            disabled={stars === 0}
          />
        </Form>
      </Col>
    </Container>
  );
};

export default Feedback;
