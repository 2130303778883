import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const Locked: React.FC<ThemeProps> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='58'
        height='40'
        viewBox='0 0 58 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.3311 3.90062C10.4245 3.90062 6.45091 7.86687 6.39751 12.7794L7.63591 13.1818C8.31751 13.4032 9.03372 13.5174 9.75312 13.5174H33.2452V9.85736C33.2452 8.03653 33.71 6.31779 34.5188 4.81021C33.3484 4.24479 32.0538 3.90039 30.6672 3.90039L15.3311 3.90062ZM4.52651 14.2759L4.46851 14.3211C2.4587 15.8341 1.2771 18.2097 1.2771 20.7314V25.829C1.8875 27.4056 3.4797 28.5516 5.41371 28.5516H28.2458L28.2257 25.0634H17.8897L16.6085 19.9307H28.2451V17.2508C28.2451 16.6219 28.4131 16.033 28.6867 15.5163H9.75308C8.82428 15.5163 7.90048 15.3683 7.01868 15.0833L4.52651 14.2759ZM8.94452 19.932C10.3559 19.9326 11.4997 21.0816 11.4991 22.4984C11.4997 23.9152 10.3559 25.0642 8.94452 25.0648C7.53291 25.0644 6.38911 23.9152 6.38971 22.4984C6.38911 21.0816 7.53291 19.9325 8.94452 19.932ZM1.3663 29.1313C1.7765 31.5523 3.86111 33.4008 6.38971 33.4008H28.2452V30.553H5.41311C3.87911 30.553 2.4789 30.0058 1.3663 29.1313Z'
          fill='black'
        />
        <path
          d='M31.5 17.1523C30.669 17.1523 30 17.8212 30 18.6523V38.4999C30 39.3309 30.669 39.9998 31.5 39.9998H56.5001C57.3311 39.9998 58.0001 39.3309 58.0001 38.4999V18.6523C58.0001 17.8212 57.3311 17.1523 56.5001 17.1523H31.5ZM44 23.0779C45.6568 23.0779 47 24.4212 47 26.078C46.999 27.043 46.5338 27.9487 45.75 28.5117V34.0781H42.25V28.5117C41.4662 27.9486 41.001 27.043 41 26.078C41 24.4212 42.3432 23.0779 44 23.0779Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.9976 0C39.0388 0 34.9976 4.08209 34.9976 9.09035V15.4999H36.9976V9.09035C36.9964 5.1737 40.1196 2.0201 43.9976 2.0201C47.8754 2.0201 50.9976 5.1737 50.9976 9.09035V15.4999H52.9976V9.09035C52.996 4.08209 48.9564 0 43.9976 0Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.49976 32.9023V38.6203C6.49976 39.3286 7.07176 39.9025 7.77756 39.9025H10.3332C11.039 39.9025 11.6112 39.3288 11.6112 38.6203V32.9023'
          fill='black'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.55461 10.3164C1.1432 10.3164 0 11.4621 0 12.8788V14.1642C0 14.8098 0.483001 15.3207 1.1016 15.4102C1.7148 14.4644 2.49541 13.6203 3.41801 12.9257L4.84761 11.8477C4.89962 11.3216 5.01961 10.8184 5.14461 10.3164H2.55461Z'
          fill='black'
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Locked);
