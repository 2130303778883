import React, { LegacyRef } from 'react';
import GeoSuggest, { Suggest } from 'react-geosuggest';
import './LocationInputReactGeosuggest.css';

export interface LocationInputProps {
  label: JSX.Element;
  value?: string;
  onSelect: (data: LocationInputHandlerParam) => void;
}

export interface LocationInputHandlerParam {
  latitude: number;
  longitude: number;
  label: string;
}

// eslint-disable-next-line react/display-name
export const LocationInput = React.forwardRef<GeoSuggest, LocationInputProps>(
  ({ label, value, onSelect }: LocationInputProps, ref: LegacyRef<GeoSuggest>) => {
    function handleSelection(data: Suggest | string) {
      if (data != undefined && typeof data != 'string') {
        onSelect({
          latitude: data.location.lat,
          longitude: data.location.lng,
          label: data.label,
        });
      } else {
        onSelect({ latitude: 0, longitude: 0, label: data });
      }
    }

    return (
      <>
        <div className='text-muted input-lbl'>{label}</div>
        <GeoSuggest
          ref={ref}
          placeholder=''
          initialValue={value}
          placeDetailFields={[]}
          onSuggestSelect={(data: Suggest) => handleSelection(data)}
          inputClassName='txt-input form-control'
          autoComplete='off'
        />
      </>
    );
  }
);
