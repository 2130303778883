import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { FileUploader } from '../../../components/fileUploader/FileUploader';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../../components/controls/TextInput';
import NextButton from '../../../components/controls/NextButton';
import { saveAdditionalDetails, saveAdditionalImages } from './additionalDetailsActions';
import { connect, ResolveThunks } from 'react-redux';
import { AdditionalDetailsState } from './additionDetailsReducer';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { AppState } from '../../../store/store';
import { submitAssistanceRequestAction } from '../assistanceRequestActions';
import { push } from 'connected-react-router';
import Layout from '../../../components/layouts/Layout';
import { I18n, Translate } from 'react-redux-i18n';
import {
  trackAdditionalDetailsViewEvent,
  trackTermsAndPrivacyClickEvent,
} from '../../../analytics/Analytics';
import CheckBox from '../../../components/controls/CheckBox';
import TermsModal from '../../../components/terms-and-privacy/TermsModal';
import { getLocalizedMessageElement, LanguageType } from '../../../locale/locale';
import { getLanguageEnumFromType } from '../../../locale/languageMap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { emailFormat, INVALID_EMAIL } from './additionDetailsValidations';
import './AdditionalDetails.css';
import { descriptionValidation } from '../../../common/FormValidations';
import {
  FULL_HD_HEIGHT,
  FULL_HD_WIDTH,
  resizeImage,
  ResizerOutputFormat,
} from '../../../common/utils/ImageUtils';

const validationSchema = yup.object().shape({
  email: yup.string().test('email', INVALID_EMAIL, (email) => !email || emailFormat.test(email)),
  description: descriptionValidation,
});

export const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  setAdditionalDetailsAction,
  saveImages,
  pendingRequestId,
  submitAssistanceRequest,
  navigateToNextStep,
  color,
  language,
  assistanceIncidentType,
}: AdditionalDetailsProps) => {
  useEffect(() => {
    trackAdditionalDetailsViewEvent(pendingRequestId);
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const { register, handleSubmit, errors } = methods;
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState<undefined | boolean>(undefined);
  const [showTerms, setShowTerms] = useState(false);

  const onSubmit = handleSubmit(async (values: AdditionalDetailsState) => {
    if (agreement) {
      setLoading(true);
      try {
        if (values.images != undefined && values.images != []) {
          let reader;
          const imageFiles: File[] = [];
          (values.images as File[]).map(async (image: File) => {
            reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = async (e) => {
              const img = new Image();
              img.src = e?.target?.result as string;
              img.onload = async function () {
                const blobPhoto: Blob = await fetch(img.src.toString()).then((res) => res.blob());
                if (img.height > FULL_HD_HEIGHT || img.width > FULL_HD_WIDTH) {
                  const file = await resizeImage(
                    blobPhoto,
                    img.width > img.height,
                    ResizerOutputFormat.blob
                  );
                  imageFiles.push(file as File);
                } else {
                  imageFiles.push(image);
                }
                if ((values.images as File[]).length == imageFiles.length) {
                  values.images = imageFiles;
                  await saveImages(pendingRequestId, values);
                }
              };
            };
          });
        }
        await setAdditionalDetailsAction(pendingRequestId, values);
        await submitAssistanceRequest(pendingRequestId);
        navigateToNextStep();
      } catch (error) {
        setErrorPopup(
          <ModalPopup
            message={getLocalizedMessageElement(error.message)}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
      } finally {
        setLoading(false);
      }
    } else {
      setAgreement(false);
    }
  });

  const handleShowTerms = (event: React.MouseEvent) => {
    trackTermsAndPrivacyClickEvent(pendingRequestId.toString());
    event.preventDefault();
    setShowTerms(true);
  };

  const handleCloseTerms = () => {
    setShowTerms(false);
  };

  return (
    <Layout>
      <Container className='content col-sm-10 col-md-6 col-lg-4 mt-5' fluid>
        <p className='text-center'>
          <Translate value={'additionalDetails.description'} />
        </p>
        <FormProvider {...methods}>
          <Form className='my-4' noValidate onSubmit={onSubmit}>
            <Form.Group controlId='description'>
              <Form.Label className='text-muted'>
                <Translate value={'additionalDetails.descriptionLabel'} />
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                ref={register}
                name='description'
                placeholder={I18n.t(
                  `additionalDetails.descriptionPlaceholder.${assistanceIncidentType}`
                )}
                isInvalid={errors.description}
                data-testid='additionalDetailsDescription'
              />
              <Form.Control.Feedback type='invalid'>
                <Translate value={'additionalDetails.requiredField'} />
              </Form.Control.Feedback>
            </Form.Group>

            <div style={loading ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
              <FileUploader
                label={<Translate value={'additionalDetails.pictures'} />}
                accept='image/*,.heic,.heif'
                maxSize={52428800}
                multiple
                name='images'
                mode='append'
              />
            </div>
            <div className={'email-input'}>
              <TextInput
                name='email'
                label={<Translate value={'additionalDetails.email'} />}
                cssClass={'email-description'}
                testId='email'
                controlId='email'
                ref={register}
                isInvalid={errors.email}
                validationError={getLocalizedMessageElement(errors.email?.message)}
              />
            </div>

            {errorPopup}

            <CheckBox
              name='agreement'
              type='checkbox'
              id={`agreement`}
              value='agreement'
              label={
                <>
                  <Translate value={'terms.agreement'} />{' '}
                  <span className='text-primary link' onClick={handleShowTerms}>
                    <Translate value={'terms.termsAndPrivacy'} />
                  </span>
                </>
              }
              onChange={() => setAgreement(!agreement)}
              checked={agreement}
              isInvalid={agreement == false}
              title='agreement'
            />
            {agreement == false && (
              <Container className={'mt-3'}>
                <Translate style={{ color: '#dc3545', fontSize: '0.8rem' }} value={'terms.error'} />
              </Container>
            )}
            <TermsModal
              isOpen={showTerms}
              onClose={handleCloseTerms}
              color={color}
              language={language}
            />
            <NextButton
              type='submit'
              data-testid='submitBtn'
              loading={loading}
              text={<Translate value={'orderHelp'} />}
            />
          </Form>
        </FormProvider>
      </Container>
    </Layout>
  );
};

const mapStateToProp = ({ assistanceRequest, theme, i18n, assistanceIncident }: AppState) => ({
  pendingRequestId: assistanceRequest.pendingRequestId,
  assistanceIncidentType: assistanceIncident.incident,
  color: theme.color,
  language: getLanguageEnumFromType(i18n.locale as LanguageType),
});

const mapDispatchToProps = {
  setAdditionalDetailsAction: saveAdditionalDetails,
  saveImages: saveAdditionalImages,
  submitAssistanceRequest: submitAssistanceRequestAction,
  navigateToNextStep: () => push('/assistance/track-driver'),
};

export type AdditionalDetailsProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProp>;

export default connect(mapStateToProp, mapDispatchToProps)(AdditionalDetails);
