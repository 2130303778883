import { ApiClaimIncidentType } from '../api';
import { Dispatch } from 'redux';
import { checkIfStepIsValid, getNextRoute } from './RouteNames';
import { push } from 'connected-react-router';

export const goToNextPage =
  (
    incident?: ApiClaimIncidentType,
    isMotionsCloudEnabled?: boolean,
    showInsuranceSelection?: boolean,
    skipWorkshop?: boolean
  ) =>
  (dispatch: Dispatch): void => {
    if (incident) {
      const nextRoute = getNextRoute(
        incident,
        isMotionsCloudEnabled,
        showInsuranceSelection,
        skipWorkshop
      );
      nextRoute && dispatch(push(nextRoute));
    }
  };

export const redirectToHome =
  (incident?: ApiClaimIncidentType, isMotionsCloudEnabled?: boolean) =>
  (dispatch: Dispatch): void => {
    if (!checkIfStepIsValid(incident, isMotionsCloudEnabled)) {
      dispatch(push('/claims/incidents'));
    }
  };
