import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const TermsEstonian: React.FC = () => {
  return (
    <>
      <SectionHeader text={'TEENUSE ÜLDTINGIMUSED'} />
      <div>
        <p>
          <b>Yaway OÜ</b> (registrikood 16406514, asukoht Valukoja 8/1, 11415, Tallinn, Eesti)
          (edaspidi „<b>Yaway</b>“, „<b>meie</b>” ja „<b>me</b>“) haldab platvormi aadressil
          https://yaway.ai ja selle alamdomeene ja mobiilirakendust ja seotud tarkvara, riistvara,
          andmebaase, liidestusi, seotud lehti, dokumentatsiooni, uuendusi ja muud ühendatud või
          liidetud komponente ja materjale (edaspidi „<b>Platvorm</b>“).
        </p>
        <p>
          Rakendus pakub virtuaalse keskkonna, mille abil saab sõiduki kasutaja tellida sõidukiga
          ilmnenud probleemi korral autoabi (puksiiri) või teatada sõidukiga toimunud kahjust
          (kindlustusjuhtumi käsitlemiseks) ja teatud juhtudel ka ülevaate juhtumi staatusest. Saab
          teavitusi e-maili või sõnumi teel.
        </p>
        <p>
          „<b>Kasutaja</b>“ tähendab isikut, kes kasutab Platvormi autoabi tellimiseks või sõidukiga
          toimunud kahjust teatamiseks. „<b>Teenusepakkuja</b>“ tähendab isikut, kes kasutab
          Platvormi autoabi teenuse pakkumiseks (näiteks puksiir) või kindlustusandjana. „
          <b>Tellimus</b>“ on Kasutaja poolt Platvormi kaudu Teenusepakkujale esitatud tellimus,
          näiteks autoabiteenuse saamiseks või kahjujuhtumi käsitlemiseks.
        </p>
        <p>
          Platvormi kasutamise eelduseks on nõustumine käesolevate kasutustingimustega (edaspidi „
          <b>Tingimused</b>“) ja tutvumine privaatsustingimusetega{' '}
          <a href='https://yaway.ai'>https://yaway.ai </a>
          („<b>Privaatsustingimused</b>“). Kasutaja kinnitab Platvormi kasutamisel, et on Tingimused
          hoolikalt läbi lugenud ning nõustub nendega. Tingimustega nõustumisel muutuvad need Teie
          ja meie vahel sõlmitud siduvaks lepinguks (edaspidi „<b>Leping</b>“).
        </p>
        {NumericSection('1.', <b>PLATVORM JA SELLE KASUTAMINE</b>)}
        {NumericSection(
          '1.1.',
          `Platvorm loob virtuaalse keskkonna, mille vahendusel saab Kasutaja esitada
            Teenusepakkujatele tellimusi, näiteks tellida sõidukiga ilmnenud probleemi korral
            autoabi (puksiiri) või teatada sõidukiga toimunud kahjust (kahjujuhtumi käsitlemiseks)
            ja Teenusepakkuja võtta vastu Kasutaja Tellimuse.`
        )}
        {NumericSection(
          '1.2.',
          `Teenuse kasutamiseks peab Kasutaja esitama Tellimuse Platvormil (veebilehe või
        mobiilirakenduse kaudu) ja Teenuseosutaja peab Tellimuse vastu võtma (Tellimuse
        aktsepteerima).`
        )}
        {NumericSection(
          '1.3.',
          `Kasutaja mõistab, et Yaway pakub Platvormi kaudu üksnes vahendusteenust. Yaway
        ei ole kindlustusandjaks ega autoabiteenuse osutajaks. Yaway ei ole seega
        Kasutaja ja Teenusepakkuja vahel tekkiva õigussuhte pooleks, mh ei vastuta Yaway
        Kasutaja ja Teenusepakkuja omavahelisest suhtest tulenevate kohustuste nõuetekohase täitmise
        eest. Seejuures ei vastuta Yaway Kasutaja poolt Platvormil avaldatud informatsiooni
        tõele vastavuse ega seaduslikkuse eest.`
        )}
        {NumericSection('2.', <b>KASUTAJA ÕIGUSED JA KOHUSTUSED</b>)}
        {NumericSection('', <b>Kasutaja kohustub:</b>)}
        {NumericSection(
          '2.1.',
          `esitama Platvormi kasutamisel tõeseid andmeid, sh esitama taotlusi oma õige ja täieliku nime
        all ning kasutama kehtivat e-posti aadressi;`
        )}
        {NumericSection(
          '2.2.',
          `tagama, et Platvormi kasutamisel esitatud andmed ei oleks väärad, eksitavad või ebatäpsed, ei
        rikuks kolmandate isikute õigusi ning ei oleks vastuolus kehtiva õigusega ja heade kommetega;`
        )}
        {NumericSection('', <b>Kasutajal on keelatud</b>)}
        {NumericSection(
          '2.3.',
          `kasutada Platvormi pettuseks või mis tahes muul ebaseaduslikul eesmärgil;`
        )}
        {NumericSection(
          '2.4.',
          `keelitada teisi tegema mis tahes ebaseaduslikke tegusid või neis osalema;`
        )}
        {NumericSection(
          '2.5.',
          `üles laadida või edastada viiruseid või mis tahes muud tüüpi pahavara, mida kasutatakse või
      võidakse kasutada viisil, mis mõjutab Platvormi funktsionaalsust või toimimist;`
        )}
        {NumericSection(
          '2.6.',
          `edastada Platvormi kaudu andmeid, tellimusi, kommentaare ega mis tahes muud sisu, mille
        edastamiseks ja avaldamiseks puudub Kasutajal õigus.`
        )}
        {NumericSection('3.', <b>YAWAY ÕIGUSED JA KOHUSTUSED</b>)}
        {NumericSection(
          '3.1.',
          `Yaway'l on õigus omal valikul piirata või tühistada Kasutaja Platvormi
      kasutamisõigust juhul, kui:`
        )}
        {NumericSection(
          '3.1.1.',
          `Kasutaja rikub oluliselt Tingimusi või ei paranda mistahes rikkumist pärast Yaway
        antud mõistlikku tähtaega;`,
          2
        )}
        {NumericSection(
          '3.1.2.',
          `Kasutaja on esitanud Platvormi kasutamisel informatsiooni, mis on väär, eksitav ja/või
        ebatäpne;`,
          2
        )}
        {NumericSection(
          '3.1.3.',
          `Mis tahes muu alusel esinemisel, mis annab Yaway'le põhjuse kahelda Kasutaja usaldusväärsuses.`,
          2
        )}
        {NumericSection(
          '3.2.',
          `Yaway'l on õigus igal ajal Platvormi ja teenuse arengust tulenevalt ning nende parema
      ja turvalisema kasutamise huvides Tingimusi ühepoolselt muuta ning täiendada.`
        )}
        {NumericSection(
          '3.3.',
          `Platvorm ja kogu selle sisu intellektuaalomandi õigused kuuluvad Yaway'le.
        Platvormile autoriõiguse või muu intellektuaalomandi õigusega kaitstud teoseid (fotosid)
        edastades annab Kasutaja Yaway'le tasuta lihtlitsentsi nende teoste osas kehtivate
        varaliste õiguste teostamiseks, sh on meil õigus Kasutaja poolt edastatud teoseid igal
        viisil ise kasutada, lubada ja keelata nende kasutamist Platvormil, reprodutseerida,
        levitada, tõlkida, kohandada, lisada teoseid kogumikesse või andmebaasidesse ja edastada
        üldsusele.`
        )}
        {NumericSection('4.', <b>VASTUTUS JA KAHJU HÜVITAMINE</b>)}
        {NumericSection(
          '4.1.',
          `Platvormiga seoses või selle kaudu kättesaadavaks tehtud sisu, teenuseid või funktsioone
        pakutakse põhimõttel „nagu on“ ja „nagu saadaval“, ilma mis tahes otse või kaudselt
        väljendatud tagatisteta. Me ütleme lahti kõigist garantiidest niivõrd, kuivõrd see on
        kohaldatava õiguse alusel võimalik.`
        )}
        {NumericSection(
          '4.2.',
          `Yaway ei taga ega garanteeri, et Platvorm ühildub mis tahes Kasutaja poolt
        kasutatava riist- ja tarkvaraga, on alati kättesaadav või mõnel konkreetsel ajahetkel
        kättesaadav, toimib igal ajal katkestusteta, on turvaline või vaba vigadest, vastab teie
        mistahes vajadustele või on vaba viirustest, häiretest, kaitstud häkkimiste ja muu
        turvalisust vähendava sissetungimise eest.`
        )}
        {NumericSection(
          '4.3.',
          `Yaway ei vastuta muu hulgas, ent mitte üksnes, järgmiste asjaolude eest:`
        )}
        {NumericSection(
          '4.3.1.',
          `Platvormi või seal pakutava teenuse mis tahes katkestus, seiskumine, peatumine või muu kättesaamatus;`,
          2
        )}
        {NumericSection(
          '4.3.2.',
          `Mis tahes programmivead, viirused, Trooja hobused jms, mida keegi võib Platvormile või seal
      pakutavale teenusele või nende kaudu edastada;`,
          2
        )}
        {NumericSection(
          '4.3.3.',
          `Mis tahes sisu või andmete kustutamine või võimatus sisu või andmeid salvestada;`,
          2
        )}
        {NumericSection(
          '4.3.4.',
          `Mis tahes sisselogimismandaadi avalikustamine, kaotsiminek või lubamatu kasutamine,
        põhjusel, et te pole säilitanud oma mandaadi konfidentsiaalsust;`,
          2
        )}
        {NumericSection('4.3.5.', `Kasutaja käitumise eest Platvormi kasutamisel;`, 2)}
        {NumericSection(
          '4.3.6.',
          `Platvormi poole pöördumiseks kasutatavate tehniliste vahendite ühildamatus.`,
          2
        )}

        {NumericSection('5.', <b>MUUD SÄTTED</b>)}
        {NumericSection(
          '5.1.',
          `Platvormi kasutamisest tulenevatele õigussuhetele meie ja Kasutaja vahel kohaldatakse Eesti Vabariigi õigusakte.`
        )}
      </div>
    </>
  );
};

export default TermsEstonian;
