import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import NextButton from '../../../components/controls/NextButton';
import { connect, ResolveThunks } from 'react-redux';
import CheckBox from '../../../components/controls/CheckBox';
import {
  saveCommunicationPreference,
  submitClaim,
  registerClaimForDamageAssessment,
} from './communicationPreferenceActions';
import { ApiCommunicationPreference, ApiCommunicationPreferenceType } from '../../../api';
import { AppState } from '../../../store/store';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import {
  trackClaimCommunicationPreferenceSubmitEvent,
  trackClaimCommunicationViewEvent,
  trackClaimSubmitEvent,
  trackExceptionEvent,
  trackTermsAndPrivacyClickEvent,
} from '../../../analytics/Analytics';
import './CommunicationPreference.css';
import TermsModal from '../../../components/terms-and-privacy/TermsModal';
import { LanguageType } from '../../../locale/locale';
import { getLanguageEnumFromType } from '../../../locale/languageMap';

export const CommunicationPreference: React.FC<CommunicationPreferenceProps> = ({
  savePreferences,
  claimId,
  userPreferences,
  color,
  language,
  submitClaim,
  isMotionsCloudEnabled,
  registerClaimForDamageAssessment,
  serviceCommunicationPreferences,
}: CommunicationPreferenceProps) => {
  interface communicationTypes {
    email: ApiCommunicationPreferenceType | undefined;
    sms: ApiCommunicationPreferenceType | undefined;
  }

  const communicationTypeConstants = {
    email: ApiCommunicationPreferenceType.Email,
    sms: ApiCommunicationPreferenceType.Sms,
  };

  const communicationTypeMap: communicationTypes = {
    email: userPreferences.includes(ApiCommunicationPreferenceType.Email)
      ? ApiCommunicationPreferenceType.Email
      : undefined,
    sms: userPreferences.includes(ApiCommunicationPreferenceType.Sms)
      ? ApiCommunicationPreferenceType.Sms
      : undefined,
  };
  const [preferences, setPreferences] = useState(communicationTypeMap);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState<undefined | boolean>(undefined);
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    trackClaimCommunicationViewEvent(claimId);
    if (serviceCommunicationPreferences.length == 1) {
      if (serviceCommunicationPreferences.includes('EMAIL'))
        setPreferences({ ...preferences, email: ApiCommunicationPreferenceType.Email });
      else setPreferences({ ...preferences, sms: ApiCommunicationPreferenceType.Sms });
    }

    if (serviceCommunicationPreferences.length > 1) {
      setPreferences({
        email: ApiCommunicationPreferenceType.Email,
        sms: ApiCommunicationPreferenceType.Sms,
      });
    }

    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const handleChange = async (e: { target: { name: string } }) => {
    const { name } = e.target;
    // @ts-ignore
    const value = preferences[name] === undefined ? communicationTypeConstants[name] : undefined;
    setPreferences({ ...preferences, [name]: value });
  };

  const getPreferenceTypeList = (): Array<ApiCommunicationPreferenceType> => {
    // @ts-ignore
    return Object.values(preferences).filter((communicationType) => communicationType != undefined);
  };
  const isInvalid = preferences.sms === undefined && preferences.email === undefined;
  const handleSubmit = async () => {
    if (agreement) {
      setLoading(true);
      const preferenceTypeList: Array<ApiCommunicationPreferenceType> = getPreferenceTypeList();
      const communicationPreferenceRequest: ApiCommunicationPreference = {
        communicationPreferences: preferenceTypeList,
      };

      try {
        if (claimId != undefined && !isInvalid && agreement) {
          await savePreferences(communicationPreferenceRequest, claimId);
          trackClaimCommunicationPreferenceSubmitEvent(claimId, communicationPreferenceRequest);
          await handleClaimSubmission();
          if (isMotionsCloudEnabled) {
            await handleMotionsCloudRedirection();
          }
        }
      } catch (error) {
        trackExceptionEvent(
          claimId || null,
          'claim communication preference submit',
          error.message
        );
        setErrorPopup(
          <ModalPopup
            message={<Translate value={'errors.savingPreferencesFailed'} />}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
      } finally {
        setLoading(false);
      }
    } else {
      setAgreement(false);
    }
  };

  const handleClaimSubmission = async () => {
    try {
      if (claimId != undefined) {
        await submitClaim(claimId, isMotionsCloudEnabled);
        trackClaimSubmitEvent(claimId);
      }
    } catch (error) {
      trackExceptionEvent(claimId || null, 'claim submit', error.message);
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.claimSubmissionFailed'} />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
  };

  const handleMotionsCloudRedirection = async () => {
    try {
      if (claimId != undefined) {
        const response = await registerClaimForDamageAssessment(claimId);
        if (response.redirectionUrl) {
          window.location.replace(response.redirectionUrl);
        }
      }
    } catch (error) {
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.claimDamageAssessmentRegistrationFailed'} />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
  };

  const handleShowTerms = (event: React.MouseEvent) => {
    trackTermsAndPrivacyClickEvent(claimId);
    event.preventDefault();
    setShowTerms(true);
  };

  const handleCloseTerms = () => {
    setShowTerms(false);
  };

  return (
    <Layout>
      <Container className='content mt-5'>
        <div className='d-flex flex-column'>
          <SectionHeader text={<Translate value={'communicationPreference.title'} />} />
          <div className='d-flex flex-column justify-content-between'>
            <div className='my-3 col-md-8 mx-auto px-0'>
              {((serviceCommunicationPreferences.length == 1 &&
                serviceCommunicationPreferences.includes('EMAIL')) ||
                serviceCommunicationPreferences.length > 1) && (
                <CheckBox
                  name='email'
                  type='checkbox'
                  disabled={
                    serviceCommunicationPreferences.length == 1 &&
                    serviceCommunicationPreferences.includes('EMAIL')
                  }
                  id={`otherPartiesType-2`}
                  value='EMAIL'
                  aria-selected={true}
                  label={<Translate value={'communicationPreference.email'} />}
                  isInvalid={isInvalid}
                  onChange={handleChange}
                  checked={
                    preferences.email !== undefined ||
                    (serviceCommunicationPreferences.length == 1 &&
                      serviceCommunicationPreferences.includes('EMAIL'))
                  }
                />
              )}
              <br />
              {((serviceCommunicationPreferences.length == 1 &&
                serviceCommunicationPreferences.includes('SMS')) ||
                serviceCommunicationPreferences.length > 1) && (
                <CheckBox
                  name='sms'
                  type='checkbox'
                  id={`otherPartiesType-5`}
                  disabled={
                    serviceCommunicationPreferences.length == 1 &&
                    serviceCommunicationPreferences.includes('SMS')
                  }
                  value='SMS'
                  label={<Translate value={'communicationPreference.sms'} />}
                  onChange={handleChange}
                  isInvalid={isInvalid}
                  checked={
                    preferences.sms !== undefined ||
                    (serviceCommunicationPreferences.length == 1 &&
                      serviceCommunicationPreferences.includes('SMS'))
                  }
                />
              )}
              {isInvalid && (
                <Container className={'mt-3'}>
                  <Translate
                    style={{ color: '#dc3545', fontSize: '0.8rem' }}
                    value={'communicationPreference.error'}
                  />
                </Container>
              )}
            </div>
            {errorPopup}
          </div>
        </div>
        {!isInvalid && (
          <div className='agreement-position col-md-8 mx-auto mt-5 px-0'>
            <CheckBox
              name='agreement'
              type='checkbox'
              id={`agreement`}
              value='agreement'
              label={
                <>
                  <Translate value={'terms.agreement'} />{' '}
                  <span className='text-primary link' onClick={handleShowTerms}>
                    <Translate value={'terms.termsAndPrivacy'} />
                  </span>
                </>
              }
              onChange={() => setAgreement(!agreement)}
              checked={agreement}
              isInvalid={agreement == false}
              title='agreement'
            />
            {agreement == false && (
              <Container className={'mt-3'}>
                <Translate style={{ color: '#dc3545', fontSize: '0.8rem' }} value={'terms.error'} />
              </Container>
            )}
          </div>
        )}
        <TermsModal
          isOpen={showTerms}
          onClose={handleCloseTerms}
          color={color}
          language={language}
        />
        <NextButton
          className={isInvalid ? 'disabled' : ''}
          loading={loading}
          text={<Translate value={'communicationPreference.submit'} />}
          onClick={handleSubmit}
        />
      </Container>
    </Layout>
  );
};

const mapDispatchToProps = {
  savePreferences: saveCommunicationPreference,
  submitClaim: submitClaim,
  registerClaimForDamageAssessment: registerClaimForDamageAssessment,
};

const mapStateToProps = ({
  claim,
  communicationPreference,
  theme,
  i18n,
  assistanceRequest,
}: AppState) => {
  return {
    claimId: claim.claimId,
    isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
    userPreferences: communicationPreference.communicationPreferences,
    serviceCommunicationPreferences:
      assistanceRequest.serviceCommunicationPreferences != null &&
      assistanceRequest.serviceCommunicationPreferences != ''
        ? assistanceRequest.serviceCommunicationPreferences.split(',')
        : ['EMAIL', 'SMS'],
    color: theme.color,
    language: getLanguageEnumFromType(i18n.locale as LanguageType),
  };
};

export type CommunicationPreferenceProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationPreference);
