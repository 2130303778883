import React from 'react';
import { AppState } from '../../../../store/store';
import { connect } from 'react-redux';

export const HitWildAnimal: React.FC<ThemeProps> = ({ color }: ThemeProps) => {
  return (
    <>
      <svg
        width='56'
        height='56'
        viewBox='0 0 56 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M24.5003 54.6013L1.40112 31.4989C-0.46704 29.6308 -0.46704 26.3673 1.40112 24.4992L24.5003 1.39529C26.3685 -0.465096 29.6319 -0.465096 31.5001 1.39529L54.5993 24.4992C56.4659 26.3673 56.4659 29.6308 54.5993 31.4989L31.5001 54.6013C29.6319 56.4632 26.3685 56.4632 24.5003 54.6013Z'
          fill='black'
        />
        <path
          d='M15.6347 34.5336L24.9677 44.3255C29.6327 41.9985 30.3358 34.5336 30.3358 34.5336C30.3358 34.5336 31.969 36.1685 36.4007 35.2336C37.5642 34.9987 38.7324 36.3925 40.1308 35.2336C41.3005 34.0639 41.9989 34.2987 41.9989 33.829C41.9989 33.6034 42.464 31.9639 41.5307 31.4988C38.7308 29.8687 34.9992 24.7355 32.6628 24.034C29.6311 22.8674 28.4661 24.4991 27.9994 24.2626C27.5327 24.034 25.901 22.6294 24.7313 21.9325C23.5647 21.2356 20.7663 20.9976 20.3028 21.9325C19.8346 22.6294 20.9996 24.9657 22.6314 25.6688C24.2646 26.3657 25.1964 25.8928 25.1964 25.8928C25.1964 25.8928 21.0027 34.0639 15.6347 34.5336Z'
          fill={color}
        />
        <path
          d='M32.1977 25.8939L30.3357 20.3019C30.3357 20.3019 31.969 14.6974 31.5008 14.2386C31.0326 13.7641 29.6326 19.8306 29.1644 19.605C28.6978 19.1322 28.0009 18.6702 27.5343 18.1989C27.0676 17.7369 27.7692 9.80537 27.3056 9.56738C26.8374 9.3325 25.9025 17.264 25.9025 17.264C25.9025 17.264 24.9677 16.3292 24.2662 15.6323C23.3375 14.699 23.8042 8.17676 23.3375 8.17676C22.8709 8.17676 22.4042 15.8703 23.1011 16.5687C23.8042 17.2656 27.7692 21.9352 27.7692 21.9352L28.9358 25.8955H32.1977V25.8939Z'
          fill={color}
        />
      </svg>
    </>
  );
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type ThemeProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(HitWildAnimal);
