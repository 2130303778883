import React from 'react';
import { Translate } from 'react-redux-i18n';
import { BsArrowRight } from 'react-icons/bs';
import { Image } from 'react-bootstrap';
import './PartnerLanding.css';

export interface PartnerLandingSectionProps {
  color: string;
  title: string;
  imageSrc: string | undefined;
  reverse?: boolean;
  onClick: any;
}

export const PartnerLandingSection: React.FC<PartnerLandingSectionProps> = ({
  color,
  title,
  imageSrc,
  reverse,
  onClick,
}: PartnerLandingSectionProps) => {
  return (
    <div
      className={`d-flex h-35-md ${reverse ? 'flex-row-reverse' : ''} select`}
      style={{ backgroundColor: color }}
      onClick={onClick}
      title='main'
    >
      <div className={`d-flex text-light flex-auto justify-content-center flex-column pl-3`}>
        <p>
          <Translate value={title} className='font-weight-bold' />
        </p>
        <div>
          <BsArrowRight
            className='bg-light text-dark rounded-circle p-1'
            size='1.5rem'
            title='icon'
          />
        </div>
      </div>
      <div className='fill wrapper'>
        <Image src={imageSrc} className='fill-img img-shadow' title='image' />
      </div>
    </div>
  );
};
