import React from 'react';
import { Translate } from 'react-redux-i18n';
import Layout from '../../components/layouts/Layout';
import { SectionHeader } from '../../components/sectionHeader/SectionHeader';
import { Container } from 'react-bootstrap';

const Landing: React.FC = () => {
  return (
    <Layout hideLogo>
      <Container className='content mt-5' fluid>
        <SectionHeader text={<Translate value={'landing.welcome'} />} />
        <p className='text-center mt-4 px-4'>
          <Translate value={'landing.callYourInsuranceCompanyToGetLink'} />
        </p>
        <p className='text-center mt-4 p  x-4'>
          <Translate value={'landing.openTheLinkAgain'} />
        </p>
      </Container>
    </Layout>
  );
};

export default Landing;
