import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import MapReducer, { MapState } from '../pages/assistance-requests/map/mapReducer';
import AssistanceIncidentReducer, {
  AssistanceIncidentState,
} from '../pages/assistance-requests/incidents/incidentReducer';
import VehicleDetailsReducer, {
  VehicleDetailsState,
} from '../common/components/vehicle-details/vehicleDetailsReducer';
import ContactDataReducer, {
  ContactDataState,
} from '../pages/assistance-requests/vehicle-details/contactDataReducer';
import AssistanceRequestReducer, {
  AssistanceRequestState,
} from '../pages/assistance-requests/assistanceRequestReducer';
import InsuranceReducer, {
  InsuranceState,
} from '../pages/assistance-requests/vehicle-details/insuranceReducer';
import AdditionalDetailsReducer, {
  AdditionalDetailsState,
} from '../pages/assistance-requests/additional-details/additionDetailsReducer';
import ThemeReducer, { ThemeState } from '../common/theme/themeReducer';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import {
  i18nReducer,
  I18nState,
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { translations } from '../locale/locale';
import ClaimIncidentReducer, {
  ClaimIncidentState,
} from '../pages/claims/incidents/incidentReducer';
import NotifierInfoReducer, {
  NotifierInfoState,
} from '../pages/claims/notifier-info/notifierInfoReducer';
import OtherPartiesReducer, {
  OtherPartiesState,
} from '../pages/claims/other-parties/otherPartiesReducer';
import ClaimReducer, { ClaimState } from '../pages/claims/claimReducer';
import ClaimPhotosReducer, { ClaimPhotosState } from '../pages/claims/photos/photosReducer';
import AccidentDetailsReducer, {
  AccidentDetailsState,
} from '../pages/claims/accident-details/accidentDetailsReducer';
import DamageLocationReducer, {
  DamageLocationState,
} from '../pages/claims/damage-location/damageLocationReducer';
import CommunicationPreferencesReducer, {
  CommunicationPreferencesState,
} from '../pages/claims/communication-preferences/communicationPreferencesReducer';
import CaseStatusReducer, { CaseStatusState } from '../pages/cases/case-status/caseStatusReducer';
import workshopReducer, { WorkshopState } from '../pages/claims/select-workshop/workshopReducer';
import LocaleReducer, { LocaleState } from '../locale/localeReducer';
import SettlementReducer, {
  SettlementTypeState,
} from '../pages/claims/settlement-type/settlementTypeReducer';

export const history = createBrowserHistory();

export interface AppState {
  router: RouterState;
  map: MapState;
  assistanceIncident: AssistanceIncidentState;
  claimIncident: ClaimIncidentState;
  claimNotifierInfo: NotifierInfoState;
  vehicleDetails: VehicleDetailsState;
  contactData: ContactDataState;
  assistanceRequest: AssistanceRequestState;
  claim: ClaimState;
  insurance: InsuranceState;
  additionalDetails: AdditionalDetailsState;
  locale: LocaleState;
  theme: ThemeState;
  i18n: I18nState;
  otherParties: OtherPartiesState;
  claimPhotos: ClaimPhotosState;
  claimAccidentDetails: AccidentDetailsState;
  damageLocation: DamageLocationState;
  communicationPreference: CommunicationPreferencesState;
  caseStatus: CaseStatusState;
  workshops: WorkshopState;
  claimSettlementType: SettlementTypeState;
}

const persistConfig = {
  key: 'root',
  storage,
  timeout: 500,
};

const reducer = combineReducers({
  router: connectRouter(history),
  map: MapReducer,
  assistanceIncident: AssistanceIncidentReducer,
  claimIncident: ClaimIncidentReducer,
  claimNotifierInfo: NotifierInfoReducer,
  vehicleDetails: VehicleDetailsReducer,
  contactData: ContactDataReducer,
  assistanceRequest: AssistanceRequestReducer,
  claim: ClaimReducer,
  insurance: InsuranceReducer,
  communicationPreference: CommunicationPreferencesReducer,
  additionalDetails: AdditionalDetailsReducer,
  locale: LocaleReducer,
  theme: ThemeReducer,
  i18n: i18nReducer,
  otherParties: OtherPartiesReducer,
  claimPhotos: ClaimPhotosReducer,
  claimAccidentDetails: AccidentDetailsReducer,
  damageLocation: DamageLocationReducer,
  caseStatus: CaseStatusReducer,
  workshops: workshopReducer,
  claimSettlementType: SettlementReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore<AppState, any>({
  // @ts-ignore
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['payload.accidentDatetime', 'payload.progress'],
      },
    }),
    routerMiddleware(history),
  ] as any,
});

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('en'));
export default store;
