import { Dispatch } from 'redux';
import {
  clearVehicleDetails,
  setVehicleDetails,
  showError,
} from '../../../common/components/vehicle-details/vehicleDetailsReducer';
import { ApiClaimIncidentType, ApiVehicleDetails, claimVehicleDetails } from '../../../apis';
import { setInsurance } from '../../assistance-requests/vehicle-details/insuranceReducer';
import { TRY_AGAIN, VEHICLE_NOT_FOUND } from '../../../common/exception/ErrorMessages';
import {
  trackClaimVehicleDetailsSearchEvent,
  trackClaimVehicleDetailsSubmitEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import { goToNextPage } from '../../../routing/RouteActions';

export const getVehicleDetailsByPlateNumberAction =
  (plateNumber: string, claimId?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(clearVehicleDetails());
    plateNumber = plateNumber.toUpperCase().trim();
    if (plateNumber == '') return;
    if (!claimId || claimId === '') dispatch(showError(TRY_AGAIN));
    try {
      if (claimId) {
        const response = await claimVehicleDetails.getVehicleDetailsAndVerifyInsurance({
          licensePlate: plateNumber,
          claimId: claimId,
        });

        if (
          !response.vehicle?.licensePlate &&
          !response.vehicle?.manufacturer &&
          response.vehicle?.error != ''
        ) {
          dispatch(setInsurance({ verified: false }));
          dispatch(showError(response.vehicle?.error || VEHICLE_NOT_FOUND));
        } else {
          dispatch(setVehicleDetails(response.vehicle));
          dispatch(setInsurance(response.insurance));
        }
        trackClaimVehicleDetailsSearchEvent(claimId, response.insurance?.verified, plateNumber);
      }
    } catch (e) {
      trackExceptionEvent(claimId || null, 'claim load vehicle details', e.message);
      dispatch(showError(TRY_AGAIN));
    }
  };

export const saveVehicleDetailsAction =
  (
    claimId: string,
    vehicleDetails: ApiVehicleDetails,
    incident?: ApiClaimIncidentType,
    isMotionsCloudEnabled?: boolean
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await claimVehicleDetails.createVehicleDetails({
        claimId,
        apiVehicleDetails: vehicleDetails,
      });
      trackClaimVehicleDetailsSubmitEvent(claimId, vehicleDetails);
      goToNextPage(incident, isMotionsCloudEnabled)(dispatch);
    } catch (e) {
      trackExceptionEvent(claimId, 'claim vehicle details save', e.message);
      dispatch(clearVehicleDetails());
    }
  };
