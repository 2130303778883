import { ApiAssistanceIncidentType, ApiClaimIncidentType } from '../../api';

export const en = {
  ok: 'OK',
  close: 'CLOSE',
  next: 'NEXT',
  submit: 'SUBMIT',
  name: 'Name',
  refresh: 'Refresh',
  phoneNumber: 'Phone number',
  enterVehicleNumber: 'Enter vehicle number',
  enterYourName: 'Enter your name',
  enterYourPhoneNumber: 'Enter your phone number',
  refreshPageToSeeUpdates:
    'We have updated the page. Please refresh the page to see the latest changes.',
  orderHelp: 'ORDER HELP',
  fileAClaimDescription:
    'In case of an insurance event, please submit an application here or BALTA APP!',
  fileClaim: 'FILE CLAIM',
  assistanceIncident: {
    WHAT_IS_THE_ISSUE: 'What is the issue?',
    [ApiAssistanceIncidentType.EmptyBattery]: 'Empty battery',
    [ApiAssistanceIncidentType.RunOutOfFuel]: 'Run out of fuel',
    [ApiAssistanceIncidentType.FlatTires]: 'Flat tire',
    [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Stuck in sand/snow/mud',
    [ApiAssistanceIncidentType.DoorLocked]: 'Doors locked',
    [ApiAssistanceIncidentType.UnknownTechnicalIssue]: 'Unknown technical issue',
    [ApiAssistanceIncidentType.TrafficAccident]: 'Traffic accident',
    [ApiAssistanceIncidentType.Other]: 'Other',
  },
  claimIncident: {
    WHAT_IS_THE_ISSUE: 'What is the issue?',
    [ApiClaimIncidentType.WindshieldDamage]: 'Windshield damage',
    [ApiClaimIncidentType.TrafficAccident]: 'Car accident',
    [ApiClaimIncidentType.HitWildAnimal]: 'Hit wild animal',
    [ApiClaimIncidentType.TechnicalBreakdown]: 'Technical breakdown',
    [ApiClaimIncidentType.Theft]: 'Theft',
    [ApiClaimIncidentType.VandalismOrFire]: 'Vandalism or fire',
    [ApiClaimIncidentType.Other]: 'Other',
    invalidClaim: 'Invalid Claim!',
  },
  vehicleDetails: {
    mark: 'Mark',
    model: 'Model',
    year: 'Year',
    error: 'To get help please contact the operator.',
    callOperator: 'Call',
    missingLicensePlate: 'Provide vehicle number',
    invalidLicensePlate: 'Only digits and letters allowed.',
    missingName: 'Provide your name',
    missingPhoneNumber: 'Provide your phone number',
    requiredField: 'Required field!',
    invalidPhoneNumber: 'Phone number can contain only digits',
  },
  findTowTruck: {
    message1: 'Notifying the operators',
    message2: 'Finding a tow trucker takes max 2 min',
    message3: 'Setting up the geofence',
    message4: 'Searching for nearby tow truckers',
    message5: 'Found nearby tow truckers',
    message6: 'Forwarding incident information to tow truckers',
    message7: 'Tow truckers are assessing the data',
    message8: 'Waiting for tow truckers to respond',
  },
  waitForTowTruck: {
    title: 'Help is one the way!',
    driverComing: 'is coming to help',
    callDriver: 'Call Driver',
    driverShouldBeThere: 'Driver should be there any moment',
    eta: 'Estimated waiting time',
  },
  noTowTruckFound: {
    title: 'Sorry! No tow truck is available at this moment',
    details: `Our operators have been notified and will call you as soon as we find one`,
  },
  towTruckJobFinished: {
    title: 'Job is finished',
  },
  map: {
    searchHint: 'Search',
    error: 'Failed to reach the server. Kindly check your network connection.',
    pin: 'Drag Map to set location',
    pleaseSetPinToYourLocation: 'Please, set pin to your location',
    unableToRetrieveLocation: 'Location could not be retrieved. Please locate the position on map.',
    confirmLocation: {
      modalTitle: 'Confirm Address',
      modalBody: 'Please confirm that the address is <b>%{address}</b>.',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    failedToRetrieveAddress: 'Failed to retrieve address. Please try again.',
    failedToSaveLocation: 'Failed to save location. Please try again.',
  },
  countdown: {
    minutes: 'Minutes',
    seconds: 'seconds',
  },
  errors: {
    noVehicleFound: 'No vehicle found',
    tryAgain: 'Sorry, something went wrong. Try again later.',
    networkProblem:
      'Network outage detected. Please make sure connection is working and try again.',
    failedToUpload: 'Failed to upload images. Please try again or remove photos to continue.',
    savingAccidentDetailsFailed: 'Failed to save accident details. Please try again.',
    claimNotFound: 'Claim not found.',
    savingPreferencesFailed: 'Failed to save communication preferences. Please try again.',
    savingSettlementTypeFailed: 'Failed to save settlement type. Please try again.',
    claimSubmissionFailed: 'Failed to submit claim. Please try again.',
    claimDamageAssessmentRegistrationFailed:
      'Failed to register claim for automatic damage assessment. Please try again.',
  },
  additionalDetails: {
    description: 'Please add accident description and photos.',
    addPhotos: 'Click and add photos',
    requiredField: 'Required field!',
    descriptionLabel: 'Description',
    pictures: 'Pictures (Optional)',
    email: 'Email (Optional)',
    invalidEmail: 'Invalid email format!',
    descriptionPlaceholder: {
      [ApiAssistanceIncidentType.EmptyBattery]:
        'Please describe the situation. How long has the vehicle been stationary? Is the vehicle in a garage or underground parking?',
      [ApiAssistanceIncidentType.RunOutOfFuel]: 'Please describe the situation.',
      [ApiAssistanceIncidentType.FlatTires]:
        'Please describe the situation. Which tire is flat? Is the tire damaged from the side?',
      [ApiAssistanceIncidentType.DoorLocked]:
        'Please describe the situation. Where are the car keys? Where are the spare keys?',
      [ApiAssistanceIncidentType.StuckInSandSnowMud]: 'Please describe the situation.',
      [ApiAssistanceIncidentType.UnknownTechnicalIssue]:
        'Please describe the issue. Is it possible to start the vehicle? Is it possible to put the gearbox into neutral? Is it possible to disengage the handbrake? Does the steering wheel turn?',
      [ApiAssistanceIncidentType.TrafficAccident]:
        'Please describe the accident. Is the vehicle drivable? Are there other parties involved? Has the police been informed?',
      [ApiAssistanceIncidentType.Other]: 'Please describe the situation.',
    },
  },
  landing: {
    welcome: 'Welcome',
    callYourInsuranceCompanyToGetLink:
      'Please, call your insurance company to get SMS with link to form.',
    openTheLinkAgain: 'If you have received an SMS, open the link again.',
  },
  linkValidation: {
    linkValidationFailed: 'Link validation failed',
    linkHasExpired: 'Link has expired',
    linkAlreadyProcessed: 'Link was already processed',
    invalidLink: 'Invalid link',
    sorrySomethingWentWrong: 'Sorry, something went wrong',
    callToGetNewLink: 'Call to get a new SMS link',
  },
  trackDriver: {
    warning: 'WARNING',
    towtruckDriverChanged: 'Your case will be handled by another driver!',
  },
  otherParties: {
    delete: 'Delete',
    missingValue: 'Missing Fields',
    title: 'Other Parties',
    otherPartiesInvolved: 'Were any other parties involved?',
    otherPartiesInfo: 'Do you know other parties info?',
    otherPartiesType: 'Select party type',
    vehiclePartyType: 'Is it a vehicle?',
    objectPartyType: 'Is it an object?',
    vehicleParty: {
      number: 'Other parties vehicle number',
      name: 'Contact name (optional)',
      personalCode: 'Personal Code (optional)',
      phoneNumber: 'Phone number (optional)',
      email: 'Email (optional)',
    },
    objectParty: {
      description: "Describe other parties' object",
      name: 'Contact name (optional)',
      personalCode: 'Personal Code (optional)',
      phoneNumber: 'Phone number (optional)',
      email: 'Email (optional)',
    },
    addAnotherParty: 'Add Another Party',
    yes: 'YES',
    no: 'NO',
    invalidPartyPresentErrorMessage:
      'Some of the parties have missing required information. Please fill in details or delete the party!',
  },
  notifierInfo: {
    title: 'NOTIFIER INFO',
    driver: 'Driver',
    name: 'Full name',
    personalCode: 'Personal Code (Optional)',
    telephone: 'Phone Number',
    email: 'Email',
    confirmUserIsDriver: 'Were you the driver during the accident?',
    notifyUserTofillDriverDetails: '(If no, provide information about the driver)',
    affirm: 'YES',
    deny: 'NO',
    errors: {
      name: 'Full name is required!',
      telephone: 'Phone number can contain only digits and can not be empty!',
      email: 'Email must be a valid email address!',
      personalCode: 'Personal code should be in correct format!',
    },
  },
  insuranceSelection: {
    title: 'Insurance',
    subtitle: 'We will send the claim to the insurer',
    cascoSelect: 'Select CASCO insurance',
    mtplSelect: 'Select MTPL insurance',
    CascoNoneApplicable: 'No Casco Insurance',
    MtplNotSure: "I don't know",
    mandatorySelectionError: 'Selection is mandatory!',
    casco: 'Casco',
    mtpl: 'Motor third party liability insurance',
  },
  accidentDetails: {
    title: 'Accident Details',
    affirmation: 'YES',
    deny: 'NO',
    date: 'Enter date of the accident',
    time: 'Enter time of the accident',
    location: 'Accident Location',
    description: 'Accident Description',
    culprit: {
      responsibleParty: 'Who caused the accident?',
      notifier: '%{licensePlate} caused the accident',
      otherParty: 'Other side was guilty',
      unknown: 'Unknown',
    },
    dateError: 'Date is necessary!',
    timeError: 'Time is necessary!',
    authoritiesNotified: 'Were the authorities notified?',
    personalInjury: 'Were there any personal injury?',
    accidentDateIsMandatory: 'Accident date is required!',
    accidentTimeIsMandatory: 'Accident time is required!',
    accidentDescriptionIsMandatory: 'Accident description is required!',
  },
  claimReceived: {
    title: 'CLAIM RECEIVED',
    body: `Your case has been successfully registered. We will contact you directly if there 
          will be any questions or updates. You may close this page now.`,
  },
  communicationPreference: {
    title: 'Communication Preferences',
    email: 'EMAIL',
    sms: 'SMS',
    error: 'At least one communication preference is required!',
    submit: 'SUBMIT',
  },
  damageLocation: {
    title: 'Damage Location',
  },
  photos: {
    title: 'UPLOAD PHOTOS',
    skipForNow: 'SKIP FOR NOW',
    skipStep: 'SKIP STEP',
    takePhotos: 'TAKE PHOTOS',
    uploadFromGallery: 'UPLOAD FROM GALLERY',
    photoUploadLimitReached:
      'Total %{number} images can be added to this form. The rest have been discarded',
    skipTitle: `You are about to skip 'upload photos' step. Photos are important to file a
          claim. Do not forget to add them later.`,
    turnYourPhoneHorizontally: 'Turn your phone horizontally',
    takeAPhotoOfDamage: 'Take a photo of the damage',
    uploadSuggestions: {
      generalViewOfTheVehicle: 'Picture of a general view of the vehicle with registration number',
      damagePicture: 'Pictures of the damage (further and closer)',
      availableDocuments: 'Notification of the accident or other written document (if available)',
      driversLicense: "Photo of driver's license",
      scenePicture: 'Pictures of the scene (if available)',
    },
  },
  partnerLanding: {
    title: 'How can we help?',
    fileAClaim: 'File a claim',
    roadsideAssistance: 'Roadside Assistance',
  },
  caseStatus: {
    title: 'Case Status',
    registration: 'Registration nr.',
    steps: {
      caseRegistered: 'Case registered',
      caseRegisteredDone: 'Case registered',
      vehicleDeliveredToWorkshop: 'Vehicle delivered to workshop',
      vehicleDeliveredToWorkshopDone: 'Vehicle delivered to workshop',
      estimatedDiagnostics: 'Estimated diagnostics',
      estimatedDiagnosticsDone: 'Diagnostics done',
      preparingRepairEstimate: 'Preparing the repair estimate',
      preparingRepairEstimateDone: 'Repair estimate completed',
      confirmingRepairEstimate: 'Confirming the repair estimate',
      confirmingRepairEstimateDone: 'Repair estimate confirmed',
      estimatedArrivalOfSpareParts: 'Estimated arrival of spare parts',
      estimatedArrivalOfSparePartsDone: 'Spare parts arrived',
      repairingStarts: 'Repairing starts',
      repairingStartsDone: 'Repairing started',
      estimatedEndOfRepair: 'Estimated end of repair',
      estimatedEndOfRepairDone: 'Repair ended',
      pickUpVehicle: 'Please pick up vehicle',
      pickUpVehicleDone: 'Vehicle picked up',
    },
    nextStep: 'Your claim is being handled. Next step is',
    workshopDetails: 'Workshop Details',
    errors: {
      invalidLink: 'Invalid link',
      caseNotFound: 'Case not found',
    },
  },
  terms: {
    agreement: 'I agree with the terms & conditions and privacy policies',
    termsAndPrivacy: 'Terms and privacy policies',
    error: 'You must agree with the terms and privacy policies',
    termsTitle: 'GENERAL TERMS OF SERVICE',
    privacyTitle: 'PRIVACY POLICY',
  },
  workshop: {
    choose: 'CHOOSE WORKSHOP',
    hint: '<h6><b>Select workshop by clicking <img src = %{img} width="20px" height="20px" /> on the map</b></h6>',
  },
  feedback: {
    title: 'Your feedback matters!',
    subText: 'Let us know how was your overall experience.',
    emptyRatingText: 'Please rate your experience.',
    commentLabel: 'Other comments (optional)',
    expired: 'The link has expired!',
    notFound: 'The link is invalid!',
    alreadyGiven: 'You already gave feedback!',
    success: 'Thank you! Your feedback successfully processed!',
    submissionError: 'Failed to submit your feedback! Please try again later.',
  },
  assistanceLanding: {
    title: 'Order roadside assistance',
    description:
      "<p>Ordering roadside assistance by clicking the START button is <b>75% faster</b> than speaking with the operator.<br><br> It's a simple 4-step process that takes up to 2 minutes. Your location is detected via GPS.</p>",
    start: 'START',
  },
  settlementType: {
    title: 'Settlement Type',
    desiredSettlementType: 'What is the desired settlement type for the claim?',
    repair: 'Repair',
    money: 'Money',
  },
};
