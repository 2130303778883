import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiClaimSettlementType } from '../../../api';

export interface SettlementTypeState {
  settlementType: ApiClaimSettlementType | undefined;
}

export const initialState: SettlementTypeState = {
  settlementType: undefined,
};

const settlementTypeSlice = createSlice({
  name: 'settlementType',
  initialState,
  reducers: {
    setSettlementType: (
      state: SettlementTypeState,
      action: PayloadAction<ApiClaimSettlementType>
    ) => {
      state.settlementType = action.payload;
    },
    resetSettlementType: (state: SettlementTypeState) => {
      state.settlementType = initialState.settlementType;
    },
  },
});

const { actions, reducer } = settlementTypeSlice;

export const { setSettlementType, resetSettlementType } = actions;

export default reducer;
