import React, { RefObject, useEffect, useRef, useState } from 'react';
import './TimePicker.css';
import { Translate } from 'react-redux-i18n';

export interface TimePickerProps {
  label: JSX.Element;
  startTime?: string;
  onChange: ((event: string | undefined) => void) | undefined;
  error: boolean;
}

// @ts-ignore
export const TimeInput: React.FC<TimePickerProps> = ({
  label,
  onChange,
  startTime,
  error,
}: TimePickerProps) => {
  const [minute, setMinute] = useState(startTime ? startTime.split(':')[1] : '');
  const [hour, setHour] = useState(startTime ? startTime.split(':')[0] : '');
  const [select, setSelect] = useState(false);
  const hourList = ['HH'];
  const minuteList = ['MM'];
  const DEFAULT_TIME = '00';

  for (let i = 0; i < 24; i++) {
    let hour = i.toString();
    if (hour.length < 2) hour = '0' + hour;
    hourList.push(hour);
  }
  for (let j = 0; j < 60; j++) {
    let min = j.toString();
    if (min.length < 2) min = '0' + min;
    minuteList.push(min);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setSelect(!select);
  };

  const onHourChange = async (selectedHour: string) => {
    if (!minute) setMinute(DEFAULT_TIME);
    setHour(selectedHour);
    const timeString = `${selectedHour}:${minute || DEFAULT_TIME}`;
    if (onChange) onChange(!selectedHour ? undefined : timeString);
    setSelect(false);
  };

  const onMinuteChange = async (selectedMinute: string) => {
    if (!hour) setHour(DEFAULT_TIME);
    setMinute(selectedMinute);
    const timeString = `${hour || DEFAULT_TIME}:${selectedMinute}`;
    if (onChange) onChange(!selectedMinute ? undefined : timeString);
    setSelect(false);
  };

  const clearFieldsOnTimeHeaderClicked = () => {
    setMinute('');
    setHour('');
    if (onChange) onChange(undefined);
    setSelect(false);
  };

  function useOutsideAlerter(ref: RefObject<HTMLInputElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        // @ts-ignore
        if (ref.current && !ref.current.contains(event.target)) {
          setSelect(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} onClick={onClick}>
      {label}
      <br />
      <div className={`time ${error ? 'time-error-border' : ''}`}>
        <div
          className='timepicker timepicker__display d-flex justify-content-right'
          onClick={onClick}
        >
          {!(hour && minute) ? (
            <span style={{ visibility: 'hidden' }}>Empty</span>
          ) : (
            `${hour}:${minute}`
          )}
        </div>
      </div>
      {error ? (
        <span className={'error-text'}>
          {' '}
          <Translate value={'accidentDetails.timeError'} />{' '}
        </span>
      ) : (
        <></>
      )}
      {select && (
        <div
          onClick={(event) => event.stopPropagation()}
          className={`d-flex justify-content-start w-25 time-container ${select ? 'selected' : ''}`}
        >
          <div className={'hour-container'}>
            {hourList.map((hourItem, index) => {
              if (index === 0)
                return (
                  <p key={index} className='hour' onClick={clearFieldsOnTimeHeaderClicked}>
                    {'HH'}
                  </p>
                );
              return (
                <p
                  key={index}
                  className={hourItem == hour ? 'hour active-time' : 'hour'}
                  onClick={() => onHourChange(hourItem)}
                >
                  {hourItem}
                </p>
              );
            })}
          </div>
          <div className={'minute-container'}>
            {minuteList.map((minuteItem, index) => {
              if (index == 0)
                return (
                  <p key={index} className='minute' onClick={clearFieldsOnTimeHeaderClicked}>
                    {'MM'}
                  </p>
                );
              return (
                <p
                  key={index}
                  className={minute == minuteItem ? 'minute active-time' : 'minute'}
                  onClick={() => onMinuteChange(minuteItem)}
                >
                  {minuteItem}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
