/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCountryCode,
    ApiCountryCodeFromJSON,
    ApiCountryCodeToJSON,
    ApiInsurances,
    ApiInsurancesFromJSON,
    ApiInsurancesToJSON,
} from '../models';

export interface GetAllInsurancesRequest {
    countryCode: ApiCountryCode;
}

/**
 * 
 */
export class InsuranceProviderApi extends runtime.BaseAPI {

    /**
     * get insurance by country
     */
    async getAllInsurancesRaw(requestParameters: GetAllInsurancesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiInsurances>> {
        if (requestParameters.countryCode === null || requestParameters.countryCode === undefined) {
            throw new runtime.RequiredError('countryCode','Required parameter requestParameters.countryCode was null or undefined when calling getAllInsurances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/{countryCode}/insurances`.replace(`{${"countryCode"}}`, encodeURIComponent(String(requestParameters.countryCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInsurancesFromJSON(jsonValue));
    }

    /**
     * get insurance by country
     */
    async getAllInsurances(requestParameters: GetAllInsurancesRequest, initOverrides?: RequestInit): Promise<ApiInsurances> {
        const response = await this.getAllInsurancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
