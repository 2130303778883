import React, { ChangeEvent, useEffect, useState } from 'react';
import { AppState } from '../../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import './VehicleDetails.css';
import NextButton from '../../../components/controls/NextButton';
import { Container, Form } from 'react-bootstrap';
import { TextInput } from '../../../components/controls/TextInput';
import { useForm } from 'react-hook-form';
import { saveContactPrefixAction } from './contactDataAction';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLocalizedMessageElement } from '../../../locale/locale';
import * as assistanceRequestActions from '../assistanceRequestActions';
import { trackVehicleDetailsViewEvent } from '../../../analytics/Analytics';
import Layout from '../../../components/layouts/Layout';
import { Translate } from 'react-redux-i18n';
import VehicleInfoBox from '../../../common/components/vehicle-details/VehicleInfoBox';
import { getVehicleDetailsByPlateNumberAction } from '../../../common/components/vehicle-details/vehicleDetailsAction';
import { PhoneNumberInput } from '../../../components/controls/PhoneNumberInput';
import { countryCodes, countryListForCodes } from '../../commonConstants';
import { getCountryCodeFromContact } from '../../commonUtils';
import {
  INVALID_PHONE_NUMBER,
  phoneNumberFormat,
  plateNumberFormat,
  plateNumberValidationSchema,
  removeInvalidCharacters,
  REQUIRED_FIELD,
} from '../../../common/FormValidations';

const validationSchema = yup.object().shape({
  vehicleNumber: plateNumberValidationSchema,
  fullName: yup.string().required(REQUIRED_FIELD),
  phoneNumber: yup
    .string()
    .required(REQUIRED_FIELD)
    .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0)
    .matches(phoneNumberFormat, INVALID_PHONE_NUMBER),
});

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  country,
  contactData,
  vehicleDetails,
  insurance,
  saveContactPrefix,
  getVehicleInfoByPlateNumber,
  saveContact,
  saveVehicle,
  pendingRequestId,
  serviceContact,
  themeColor,
}: VehicleDetailsProps) => {
  const { register, handleSubmit, errors, setValue, trigger, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phoneNumber: contactData.phoneNumber,
      fullName: contactData.fullName,
      vehicleNumber: vehicleDetails.plateNumber,
    },
  });

  const onPlateNumberChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue('vehicleNumber', removeInvalidCharacters(evt.currentTarget.value));
  };

  useEffect(() => {
    trackVehicleDetailsViewEvent(pendingRequestId);
    if (
      vehicleDetails.plateNumber &&
      vehicleDetails.mark &&
      vehicleDetails.model &&
      insurance.verified === true
    ) {
      setDisplayContactDataFields(true);
    }
  }, []);

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.plateNumber) {
      if (vehicleDetails.plateNumber.match(plateNumberFormat) == null) return;
      setIsSearchingVehicle(true);
      setSpinnerDisplay(true);
      getVehicleInfoByPlateNumber(vehicleDetails.plateNumber, pendingRequestId).finally(() => {
        setSpinnerDisplay(false);
        setPreviousNumber(vehicleDetails.plateNumber);
        setIsSearchingVehicle(false);
        setDisplayContactDataFields(true);
      });
    }
  }, []);

  const onSaveContactData = async (data: {
    vehicleNumber: string;
    fullName: string;
    phoneNumber: string;
  }) => {
    await saveContact(pendingRequestId, {
      name: data.fullName,
      phone: data.phoneNumber,
      phonePrefix: countryCodes[countryCode],
    });
    await saveVehicle(pendingRequestId, {
      licensePlate: vehicleDetails.plateNumber,
      vinCode: vehicleDetails.vinCode,
      mark: vehicleDetails.mark,
      model: vehicleDetails.model,
      year: Number(vehicleDetails.year),
    });
  };

  const [spinnerDisplay, setSpinnerDisplay] = useState(false);
  const [previousNumber, setPreviousNumber] = useState('0');
  const [isSearchingVehicle, setIsSearchingVehicle] = useState(false);
  const [countryCode, setCountryCode] = useState(
    getCountryCodeFromContact(contactData.phonePrefix, contactData.phoneNumber, country)
  );
  const [displayContactDataFields, setDisplayContactDataFields] = useState(false);

  const searchVehicleDataByNumber = (evt: ChangeEvent<HTMLInputElement>) => {
    const plateNumber = evt.currentTarget.value;
    trigger('vehicleNumber');
    if (plateNumber == previousNumber) return;
    if (plateNumber.match(plateNumberFormat) == null) return;
    setIsSearchingVehicle(true);
    setSpinnerDisplay(true);
    getVehicleInfoByPlateNumber(plateNumber, pendingRequestId).finally(() => {
      setSpinnerDisplay(false);
      setPreviousNumber(plateNumber);
      setIsSearchingVehicle(false);
      setDisplayContactDataFields(true);
    });
  };

  const handleCountryCodeChange = (code: string) => {
    setCountryCode(code);
    saveContactPrefix(countryCodes[countryCode]);
  };

  const displayAdditionalUserInfoFields = () => {
    return (
      displayContactDataFields &&
      !isSearchingVehicle &&
      !vehicleDetails.error &&
      insurance.verified === true
    );
  };

  return (
    <Layout>
      <Container className='content col-sm-10 col-md-6 col-lg-4 mt-5' fluid>
        <Form id='contactForm' onSubmit={handleSubmit(onSaveContactData)}>
          <TextInput
            name='vehicleNumber'
            label={<Translate value={'enterVehicleNumber'} />}
            controlId='vehicleDetailsForm.vehicleNumber'
            onBlurHandler={searchVehicleDataByNumber}
            onEnterKeyHandler={searchVehicleDataByNumber}
            ref={register}
            isInvalid={!!errors.vehicleNumber}
            validationError={getLocalizedMessageElement(errors.vehicleNumber?.message)}
            testId='vehicleNumber'
            cssClass='text-uppercase'
            onChangeHandler={onPlateNumberChange}
          />
          <VehicleInfoBox
            vehicleDetails={vehicleDetails}
            insurance={insurance.verified}
            loading={spinnerDisplay}
            themeColor={themeColor}
            directPhoneLine={serviceContact}
          />
          {displayAdditionalUserInfoFields() && (
            <>
              <TextInput
                name='fullName'
                label={<Translate value={'enterYourName'} />}
                controlId='vehicleDetailsForm.fullName'
                ref={register}
                isInvalid={!!errors.fullName}
                validationError={getLocalizedMessageElement(errors.fullName?.message)}
                testId='fullName'
              />
              <PhoneNumberInput
                name='phoneNumber'
                label={<Translate value={'enterYourPhoneNumber'} />}
                controlId='vehicleDetailsForm.phoneNumber'
                ref={register}
                isInvalid={!!errors.phoneNumber}
                validationError={getLocalizedMessageElement(errors.phoneNumber?.message)}
                testId={'phoneNumber'}
                onCountryCodeChange={(code: string) => handleCountryCodeChange(code)}
                countryCode={countryCode}
                countryCodes={countryCodes}
                countryListForCodes={countryListForCodes}
              />
            </>
          )}
        </Form>

        <NextButton
          type='submit'
          form='contactForm'
          data-testid='submitBtn'
          disabled={
            insurance.verified === false ||
            isSearchingVehicle ||
            formState.isSubmitting ||
            !!vehicleDetails.error
          }
          loading={formState.isSubmitting}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProp = (appState: AppState) => ({
  country: appState.locale.countryCode,
  vehicleDetails: appState.vehicleDetails,
  contactData: appState.contactData,
  insurance: appState.insurance,
  pendingRequestId: appState.assistanceRequest.pendingRequestId,
  serviceContact: appState.assistanceRequest.serviceContact,
  themeColor: appState.theme.color,
});

const mapDispatchToProp = {
  saveContactPrefix: saveContactPrefixAction,
  getVehicleInfoByPlateNumber: getVehicleDetailsByPlateNumberAction,
  saveContact: assistanceRequestActions.saveContactDataAction,
  saveVehicle: assistanceRequestActions.saveVehicleDataAction,
};

export type VehicleDetailsProps = ReturnType<typeof mapStateToProp> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProp, mapDispatchToProp)(VehicleDetails);
