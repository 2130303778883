/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAccidentDetails,
    ApiAccidentDetailsFromJSON,
    ApiAccidentDetailsToJSON,
    ApiClaimData,
    ApiClaimDataFromJSON,
    ApiClaimDataToJSON,
    ApiClaimIncident,
    ApiClaimIncidentFromJSON,
    ApiClaimIncidentToJSON,
    ApiClaimSettlement,
    ApiClaimSettlementFromJSON,
    ApiClaimSettlementToJSON,
    ApiCommunicationPreference,
    ApiCommunicationPreferenceFromJSON,
    ApiCommunicationPreferenceToJSON,
    ApiInsuranceInfo,
    ApiInsuranceInfoFromJSON,
    ApiInsuranceInfoToJSON,
    ApiLanguageUpdate,
    ApiLanguageUpdateFromJSON,
    ApiLanguageUpdateToJSON,
    ApiNotifierInfo,
    ApiNotifierInfoFromJSON,
    ApiNotifierInfoToJSON,
    ApiOtherPartyDetails,
    ApiOtherPartyDetailsFromJSON,
    ApiOtherPartyDetailsToJSON,
    ApiWorkshopId,
    ApiWorkshopIdFromJSON,
    ApiWorkshopIdToJSON,
    DamageAssessmentRegistrationResponse,
    DamageAssessmentRegistrationResponseFromJSON,
    DamageAssessmentRegistrationResponseToJSON,
} from '../models';

export interface CreateAccidentDetailsRequest {
    id: string;
    apiAccidentDetails: ApiAccidentDetails;
}

export interface CreateClaimInsuranceInfoRequest {
    id: string;
    apiInsuranceInfo: ApiInsuranceInfo;
}

export interface CreateNotifierInfoRequest {
    id: string;
    apiNotifierInfo: ApiNotifierInfo;
}

export interface GetClaimDataRequest {
    id: string;
}

export interface GetClaimFilesRequest {
    uuid: string;
}

export interface RegisterClaimForDamageAssessmentRequest {
    claimId: string;
}

export interface SaveCommunicationPreferenceRequest {
    id: string;
    apiCommunicationPreference: ApiCommunicationPreference;
}

export interface SaveSettlementTypeRequest {
    id: string;
    apiClaimSettlement: ApiClaimSettlement;
}

export interface SubmitClaimRequest {
    claimId: string;
}

export interface UpdateClaimDetailsRequest {
    claimId: string;
    apiWorkshopId: ApiWorkshopId;
}

export interface UpdateClaimIncidentRequest {
    id: string;
    apiClaimIncident: ApiClaimIncident;
}

export interface UpdateClaimLanguageRequest {
    claimId: string;
    apiLanguageUpdate: ApiLanguageUpdate;
}

export interface UpdateClaimOtherPartiesDetailsRequest {
    claimId: string;
    apiOtherPartyDetails: ApiOtherPartyDetails;
}

/**
 * 
 */
export class ClaimApi extends runtime.BaseAPI {

    /**
     * create accident details
     */
    async createAccidentDetailsRaw(requestParameters: CreateAccidentDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createAccidentDetails.');
        }

        if (requestParameters.apiAccidentDetails === null || requestParameters.apiAccidentDetails === undefined) {
            throw new runtime.RequiredError('apiAccidentDetails','Required parameter requestParameters.apiAccidentDetails was null or undefined when calling createAccidentDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/accident-details`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAccidentDetailsToJSON(requestParameters.apiAccidentDetails),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create accident details
     */
    async createAccidentDetails(requestParameters: CreateAccidentDetailsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createAccidentDetailsRaw(requestParameters, initOverrides);
    }

    /**
     * create claim insurance info
     */
    async createClaimInsuranceInfoRaw(requestParameters: CreateClaimInsuranceInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createClaimInsuranceInfo.');
        }

        if (requestParameters.apiInsuranceInfo === null || requestParameters.apiInsuranceInfo === undefined) {
            throw new runtime.RequiredError('apiInsuranceInfo','Required parameter requestParameters.apiInsuranceInfo was null or undefined when calling createClaimInsuranceInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/insurance-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiInsuranceInfoToJSON(requestParameters.apiInsuranceInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create claim insurance info
     */
    async createClaimInsuranceInfo(requestParameters: CreateClaimInsuranceInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createClaimInsuranceInfoRaw(requestParameters, initOverrides);
    }

    /**
     * create notifier information
     */
    async createNotifierInfoRaw(requestParameters: CreateNotifierInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createNotifierInfo.');
        }

        if (requestParameters.apiNotifierInfo === null || requestParameters.apiNotifierInfo === undefined) {
            throw new runtime.RequiredError('apiNotifierInfo','Required parameter requestParameters.apiNotifierInfo was null or undefined when calling createNotifierInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/notifier-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiNotifierInfoToJSON(requestParameters.apiNotifierInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * create notifier information
     */
    async createNotifierInfo(requestParameters: CreateNotifierInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createNotifierInfoRaw(requestParameters, initOverrides);
    }

    /**
     * returns claim data
     */
    async getClaimDataRaw(requestParameters: GetClaimDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiClaimData>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getClaimData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiClaimDataFromJSON(jsonValue));
    }

    /**
     * returns claim data
     */
    async getClaimData(requestParameters: GetClaimDataRequest, initOverrides?: RequestInit): Promise<ApiClaimData> {
        const response = await this.getClaimDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns claim data
     */
    async getClaimFilesRaw(requestParameters: GetClaimFilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getClaimFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{uuid}/files`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * returns claim data
     */
    async getClaimFiles(requestParameters: GetClaimFilesRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getClaimFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * register claim for auto damage assessment
     */
    async registerClaimForDamageAssessmentRaw(requestParameters: RegisterClaimForDamageAssessmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DamageAssessmentRegistrationResponse>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling registerClaimForDamageAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}/register-damage-assessment`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DamageAssessmentRegistrationResponseFromJSON(jsonValue));
    }

    /**
     * register claim for auto damage assessment
     */
    async registerClaimForDamageAssessment(requestParameters: RegisterClaimForDamageAssessmentRequest, initOverrides?: RequestInit): Promise<DamageAssessmentRegistrationResponse> {
        const response = await this.registerClaimForDamageAssessmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * save user communication preferences
     */
    async saveCommunicationPreferenceRaw(requestParameters: SaveCommunicationPreferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling saveCommunicationPreference.');
        }

        if (requestParameters.apiCommunicationPreference === null || requestParameters.apiCommunicationPreference === undefined) {
            throw new runtime.RequiredError('apiCommunicationPreference','Required parameter requestParameters.apiCommunicationPreference was null or undefined when calling saveCommunicationPreference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/communication-preference`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCommunicationPreferenceToJSON(requestParameters.apiCommunicationPreference),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * save user communication preferences
     */
    async saveCommunicationPreference(requestParameters: SaveCommunicationPreferenceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.saveCommunicationPreferenceRaw(requestParameters, initOverrides);
    }

    /**
     * save claim settlement type
     */
    async saveSettlementTypeRaw(requestParameters: SaveSettlementTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling saveSettlementType.');
        }

        if (requestParameters.apiClaimSettlement === null || requestParameters.apiClaimSettlement === undefined) {
            throw new runtime.RequiredError('apiClaimSettlement','Required parameter requestParameters.apiClaimSettlement was null or undefined when calling saveSettlementType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/settlement-type`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiClaimSettlementToJSON(requestParameters.apiClaimSettlement),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * save claim settlement type
     */
    async saveSettlementType(requestParameters: SaveSettlementTypeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.saveSettlementTypeRaw(requestParameters, initOverrides);
    }

    /**
     * submit claim
     */
    async submitClaimRaw(requestParameters: SubmitClaimRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling submitClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/claims/{claimId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * submit claim
     */
    async submitClaim(requestParameters: SubmitClaimRequest, initOverrides?: RequestInit): Promise<void> {
        await this.submitClaimRaw(requestParameters, initOverrides);
    }

    /**
     * update claim details
     */
    async updateClaimDetailsRaw(requestParameters: UpdateClaimDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateClaimDetails.');
        }

        if (requestParameters.apiWorkshopId === null || requestParameters.apiWorkshopId === undefined) {
            throw new runtime.RequiredError('apiWorkshopId','Required parameter requestParameters.apiWorkshopId was null or undefined when calling updateClaimDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/details`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiWorkshopIdToJSON(requestParameters.apiWorkshopId),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update claim details
     */
    async updateClaimDetails(requestParameters: UpdateClaimDetailsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateClaimDetailsRaw(requestParameters, initOverrides);
    }

    /**
     * update claim incident type
     */
    async updateClaimIncidentRaw(requestParameters: UpdateClaimIncidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClaimIncident.');
        }

        if (requestParameters.apiClaimIncident === null || requestParameters.apiClaimIncident === undefined) {
            throw new runtime.RequiredError('apiClaimIncident','Required parameter requestParameters.apiClaimIncident was null or undefined when calling updateClaimIncident.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{id}/incident`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiClaimIncidentToJSON(requestParameters.apiClaimIncident),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update claim incident type
     */
    async updateClaimIncident(requestParameters: UpdateClaimIncidentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateClaimIncidentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateClaimLanguageRaw(requestParameters: UpdateClaimLanguageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateClaimLanguage.');
        }

        if (requestParameters.apiLanguageUpdate === null || requestParameters.apiLanguageUpdate === undefined) {
            throw new runtime.RequiredError('apiLanguageUpdate','Required parameter requestParameters.apiLanguageUpdate was null or undefined when calling updateClaimLanguage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/language`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiLanguageUpdateToJSON(requestParameters.apiLanguageUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateClaimLanguage(requestParameters: UpdateClaimLanguageRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateClaimLanguageRaw(requestParameters, initOverrides);
    }

    /**
     * update other parties details in claim details
     */
    async updateClaimOtherPartiesDetailsRaw(requestParameters: UpdateClaimOtherPartiesDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling updateClaimOtherPartiesDetails.');
        }

        if (requestParameters.apiOtherPartyDetails === null || requestParameters.apiOtherPartyDetails === undefined) {
            throw new runtime.RequiredError('apiOtherPartyDetails','Required parameter requestParameters.apiOtherPartyDetails was null or undefined when calling updateClaimOtherPartiesDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/claims/{claimId}/other-parties-details`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiOtherPartyDetailsToJSON(requestParameters.apiOtherPartyDetails),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update other parties details in claim details
     */
    async updateClaimOtherPartiesDetails(requestParameters: UpdateClaimOtherPartiesDetailsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateClaimOtherPartiesDetailsRaw(requestParameters, initOverrides);
    }

}
