/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCountryCode,
    ApiCountryCodeFromJSON,
    ApiCountryCodeFromJSONTyped,
    ApiCountryCodeToJSON,
    ApiServiceLink,
    ApiServiceLinkFromJSON,
    ApiServiceLinkFromJSONTyped,
    ApiServiceLinkToJSON,
    ApiServiceThemeData,
    ApiServiceThemeDataFromJSON,
    ApiServiceThemeDataFromJSONTyped,
    ApiServiceThemeDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiService
 */
export interface ApiService {
    /**
     * 
     * @type {ApiCountryCode}
     * @memberof ApiService
     */
    countryCode: ApiCountryCode;
    /**
     * 
     * @type {ApiServiceThemeData}
     * @memberof ApiService
     */
    theme?: ApiServiceThemeData;
    /**
     * 
     * @type {ApiServiceLink}
     * @memberof ApiService
     */
    serviceLink?: ApiServiceLink;
    /**
     * 
     * @type {boolean}
     * @memberof ApiService
     */
    isTestService: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiService
     */
    communicationPreferences: string;
    /**
     * 
     * @type {string}
     * @memberof ApiService
     */
    serviceDirectContact?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiService
     */
    isMotionsCloudEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiService
     */
    showInsuranceSelection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiService
     */
    serviceId?: number;
}

export function ApiServiceFromJSON(json: any): ApiService {
    return ApiServiceFromJSONTyped(json, false);
}

export function ApiServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': ApiCountryCodeFromJSON(json['countryCode']),
        'theme': !exists(json, 'theme') ? undefined : ApiServiceThemeDataFromJSON(json['theme']),
        'serviceLink': !exists(json, 'serviceLink') ? undefined : ApiServiceLinkFromJSON(json['serviceLink']),
        'isTestService': json['isTestService'],
        'communicationPreferences': json['communicationPreferences'],
        'serviceDirectContact': !exists(json, 'serviceDirectContact') ? undefined : json['serviceDirectContact'],
        'isMotionsCloudEnabled': json['isMotionsCloudEnabled'],
        'showInsuranceSelection': !exists(json, 'showInsuranceSelection') ? undefined : json['showInsuranceSelection'],
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
    };
}

export function ApiServiceToJSON(value?: ApiService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': ApiCountryCodeToJSON(value.countryCode),
        'theme': ApiServiceThemeDataToJSON(value.theme),
        'serviceLink': ApiServiceLinkToJSON(value.serviceLink),
        'isTestService': value.isTestService,
        'communicationPreferences': value.communicationPreferences,
        'serviceDirectContact': value.serviceDirectContact,
        'isMotionsCloudEnabled': value.isMotionsCloudEnabled,
        'showInsuranceSelection': value.showInsuranceSelection,
        'serviceId': value.serviceId,
    };
}

