/* tslint:disable */
/* eslint-disable */
/**
 * Yaway API
 * Yaway API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiClaimSettlementType,
    ApiClaimSettlementTypeFromJSON,
    ApiClaimSettlementTypeFromJSONTyped,
    ApiClaimSettlementTypeToJSON,
} from './';

/**
 * claim settlement
 * @export
 * @interface ApiClaimSettlement
 */
export interface ApiClaimSettlement {
    /**
     * 
     * @type {ApiClaimSettlementType}
     * @memberof ApiClaimSettlement
     */
    claimSettlementType: ApiClaimSettlementType;
}

export function ApiClaimSettlementFromJSON(json: any): ApiClaimSettlement {
    return ApiClaimSettlementFromJSONTyped(json, false);
}

export function ApiClaimSettlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiClaimSettlement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'claimSettlementType': ApiClaimSettlementTypeFromJSON(json['claimSettlementType']),
    };
}

export function ApiClaimSettlementToJSON(value?: ApiClaimSettlement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'claimSettlementType': ApiClaimSettlementTypeToJSON(value.claimSettlementType),
    };
}

