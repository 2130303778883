import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const PrivacyLatvian: React.FC = () => {
  return (
    <>
      <SectionHeader text={'PRIVĀTUMA NOTEIKUMI'} />
      <div>
        <p>
          <b>IEVADS</b>
        </p>
        <p>
          Šis personas datu apstrādes noteikumu dokuments (turpmāk „<b>Privātuma noteikumi</b>“)
          apraksta, kā Yaway apstrādā personas datus sava pakalpojuma ietvaros. Privātuma noteikumu
          mērķis ir sniegt skaidru un caurspīdīgu informāciju par personas datu apstrādi saskaņā ar
          spēkā esošo regulējumu.
        </p>

        <p>
          Ja Jums rodas precizējoši jautājumi par to, kā mēs apstrādājam Jūsu personas datus, vai
          Jūs vēlaties iesniegt mums pieprasījumus ar Jūsu personas datu apstrādi saistīto tiesību
          īstenošanai, sazinieties ar mums, izmantojot kontaktinformāciju, kas ir norādīta zemāk,
          sadaļā “Kontaktinformācija”.
        </p>

        <p>
          Yaway šos Privātuma noteikumus var šad tad grozīt. Aktuālie Privātuma noteikumi vienmēr ir
          publicēti interneta vietnē <a href='https://yaway.ai'>https://yaway.ai</a>.
        </p>

        {NumericSection('1.', <b>JĒDZIENI</b>)}
        {NumericSection('Datu subjekts', <>Fiziska persona, kuras datus apstrādā Yaway;</>, 4)}

        {NumericSection(
          'VDAR',
          <>
            Eiropas Parlamenta un Padomes Regula (ES) 2016/679, 2016. gada 27. aprīlis, par fizisku
            personu aizsardzību attiecībā uz personas datu apstrādi un šādu datu brīvu apriti, ar ko
            atceļ Direktīvu 95/46/EK (Vispārīgā datu aizsardzības regula);
          </>,
          4
        )}

        {NumericSection(
          'Personas dati',
          <>
            Jebkāda informācija par identificētu vai identificējamu fizisku personu (“datu
            subjektu”); identificējama fiziska persona ir persona, kuru var tieši vai netieši
            identificēt, galvenokārt uz tādas identifikācijas pazīmes pamata kā vārds, personas
            kods, atrašanās vietas informācija, tīkla identifikators vai uz šīs fiziskās personas
            vienas vai vairāku fiziskas, fizioloģiskas, ģenētiskas, garīgas, ekonomiskas, kultūras
            vai sociālas pazīmes pamata;
          </>,
          4
        )}

        {NumericSection(
          'Spēkā esošās tiesības',
          <>
            Visi spēkā esošie Eiropas Savienības tiesību akti un visi spēkā esošie Igaunijas
            Republikas tiesību akti, tai skaitā, bet ne tikai, VDAR nacionālie piemērošanas akti,
            kas ir spēkā šo Privātuma noteikumu darbības laikā, vai arī stājas spēkā pēc noteikumu
            ieviešanas;
          </>,
          4
        )}

        {NumericSection(
          'Lietotājs',
          <>
            kas izmanto Platformu, lai pasūtītu auto palīdzību vai paziņotu par kaitējumu
            transportlīdzeklim;
          </>,
          4
        )}

        {NumericSection(
          'Yaway',
          <>
            Yaway OÜ, reģistrācijas numurs 16406514, atrašanās vieta Valukoja 8/1, 11415, Tallinn,
            Igaunija;
          </>,
          4
        )}

        {NumericSection(
          'Platforma',
          <>
            Interneta vidē <a href='https://yaway.ai'>https://yaway.ai</a> Yaway pārvaldīta
            platforma, caur kuru var pasūtīt auto palīdzību vai nosūtīt paziņojumus par ar
            transportlīdzekli notikušu kaitējuma gadījumu;
          </>,
          4
        )}

        {NumericSection(
          'Apstrāde',
          <>
            ar Personas datiem vai to kopumiem veicama automatizēta vai neautomatizēta darbība vai
            darbību kopums, piemēram, vākšana, dokumentēšana, kārtošana, strukturēšana, uzglabāšana,
            pielāgošana un grozīšana, pieprasījumu veikšana, lasīšana, izmantošana, publiskošana
            nosūtot, izplatot vai citā veidā nodrošinot pieejamību, apvienošana vai savienošana,
            ierobežošana, dzēšana vai iznīcināšana;
          </>,
          4
        )}

        {NumericSection(
          'Pakalpojuma sniedzējs',
          <>
            ir pakalpojuma sniedzējs, kuram Lietotājs, izmantojot Platformu, var iesniegt Pasūtījumu
            auto palīdzības pakalpojuma saņemšanai vai ar transportlīdzekli notikuša kaitējuma
            gadījuma izskatīšanai;
          </>,
          4
        )}

        {NumericSection(
          'Pasūtījums',
          <>
            ir Lietotāja caur Platformu Pakalpojuma sniedzējam iesniegts pasūtījums, piemēram, auto
            palīdzības pakalpojuma saņemšanai vai kaitējuma gadījuma izskatīšanai;
          </>,
          4
        )}

        {NumericSection(
          'Datu pārzinis',
          <>
            Fiziska vai juridiska persona, publiska sektora iestāde, dienests vai cits orgāns, kas
            viens vai kopā ar citiem nosaka personas datu apstrādes mērķus un līdzekļus. Šo
            Noteikumu izpratnē darbinieka personas datu Datu pārzinis ir Yaway; tiesisko attiecību,
            kas rodas starp Pakalpojuma sniedzēju un Lietotāju caur Platformu, ietvaros Lietotāja
            personas datu Datu pārzinis ir Pakalpojuma sniedzējs;
          </>,
          4
        )}

        {NumericSection(
          'Pilnvarotais datu apstrādātājs',
          <>
            Fiziska vai juridiska persona, publiska sektora iestāde, dienests vai cits orgāns, kas
            apstrādā personas datus Datu pārziņa vārdā;
          </>,
          4
        )}

        {NumericSection(
          'Vispārīgie noteikumi vai Noteikumi',
          <>
            lietošanas noteikumi, kas tiek piemēroti Yaway un Lietotāju savstarpējām attiecībām
            saistībā ar Platformas lietošanu, ir pieejami{' '}
            <a href='https://yaway.ai'>https://yaway.ai</a>
          </>,
          4
        )}

        {NumericSection('2.', <b>KAD UN KĀDĒĻ MĒS APSTRĀDĀJAM PERSONAS DATUS?</b>)}
        {NumericSection(
          '2.1',
          <>
            Ja esat izlēmuši izmantot Yaway Platformu, mums ir jāapstrādā jūsu personas dati, lai
            nodrošinātu Platformas izmantošanu saskaņā ar Noteikumiem.
          </>
        )}
        {NumericSection(
          '2.2',
          <>
            Apstrādājam personas datus, kurus esat nosūtījuši Yaway saistībā ar Platformas
            izmantošanu. Šie dati ir arī šādi:
          </>
        )}
        {NumericSection(
          '2.2.1',
          <>
            <u>vispārīgi personas dati</u>: vārds (vārds, uzvārds);
          </>,
          2
        )}
        {NumericSection(
          '2.2.2',
          <>
            <u>kontaktinformācija</u>: e-pasta adrese; tālruņa numurs;
          </>,
          2
        )}
        {NumericSection(
          '2.2.3',
          <>
            <u>Auto palīdzības pakalpojuma (vilcēja) pasūtīšanai nepieciešamā informācija</u>:
            transportlīdzekļa informācija (marka, modelis, gads, numura zīme), adrese un
            koordinātas, gadījuma apraksts (Lietotāja Platformas izmantošanas laikā sniegts apraksts
            brīvā teksta formā par gadījuma detaļām);
          </>,
          2
        )}
        {NumericSection(
          '2.2.4',
          <>
            <u>Kaitējuma gadījuma izskatīšanai nepieciešamā informācija</u>: transportlīdzekļa
            informācija (marka, modelis, gads, numura zīme), gadījuma apraksts (Lietotāja Platformas
            izmantošanas laikā sniegts apraksts brīvā teksta formā par gadījuma detaļām);
            fotogrāfijas;
          </>,
          2
        )}
        {NumericSection(
          '2.2.5',
          <>
            <u>Cita informācija</u>: cita informācija, kuru Lietotājs var nosūtīt, izmantojot
            Platformu;
          </>,
          2
        )}
        {NumericSection(
          '2.2.6',
          <>
            <u>tehniskā informācija</u>: tehniskā informācija, kuru vāc saistībā ar Platformas
            piedāvājumu, kuru vāc ar sīkdatnēm (lasiet sadaļu “Sīkdatnes” zemāk).
          </>,
          2
        )}

        {NumericSection('3.', <b>KĀDS IR JŪSU PERSONAS DATU APSTRĀDES TIESISKAIS PAMATS?</b>)}
        {NumericSection(
          '3.1',
          <>
            Varam apstrādāt personas datus Platformas piedāvāšanai saskaņā ar Yaway Noteikumiem. Šīs
            datu apstrādes tiesiskais pamats ir VDAR 6. (1) (b) pants, t. i., personas datu apstrāde
            ir vajadzīga līguma, kura līgumslēdzēja puse ir datu subjekts, izpildei vai pasākumu
            veikšanai pirms līguma noslēgšanas atbilstoši datu subjekta pieprasījumam.
          </>
        )}
        {NumericSection(
          '3.2',
          <>
            Personas datus varam apstrādāt uz datu subjekta sniegtas piekrišanas pamata. Šādas datu
            apstrādes tiesiskais pamats ir VDAR 6. (1) (a) pants. Šādās situācijās Yaway personas
            datus apstrādā ar noteikumiem, kas ir minēti piekrišanā, kuru katrs datu subjekts ir
            mums sniedzis.
          </>
        )}
        {NumericSection(
          '3.3',
          <>
            Varam apstrādāt personas datus, ja apstrāde ir nepieciešama mums piemērojama juridiska
            pienākuma izpildei, piemēram, ar grāmatvedības mērķiem uz piemērojama grāmatvedības
            likuma pamata, vai, ja no Yaway pieprasa personas datus uz kompetentas iestādes
            pieprasījuma, piemēram, uz spēkā esoša tiesas lēmuma pamata. Šādas datu apstrādes
            tiesiskais pamats ir VDAR 6. (1) (c) pants.
          </>
        )}
        {NumericSection(
          '3.4',
          <>
            Ir gadījumi, kad personas datus varam apstrādāt arī tad, ja personas datu apstrāde ir
            nepieciešama ar mūsu tiesiskas intereses mērķi. Šādas datu apstrādes tiesiskais pamats
            ir VDAR 6. (1) (f) pants. Šādā gadījumā Yaway nodrošina, ka apstrāde ir proporcionāla un
            mēs esam veikuši savas tiesiskās intereses ietekmes vērtējumu.
          </>
        )}

        {NumericSection('4.', <b>PERSONAS DATU GLABĀŠANA</b>)}
        {NumericSection(
          '4.1',
          <>
            Yaway neglabā personas datus ilgāk kā tas ir nepieciešams, izrietot no personas datu
            apstrādes mērķa vai uz spēkā esošu tiesību pamata. Vispārīgi piemērojam šādus glabāšanas
            termiņus.
          </>
        )}
        {NumericSection(
          '4.2',
          <>
            Ar līgumiem saistītus personas datus var glabāt līguma darbības laikā un uz mūsu
            tiesiskas intereses pamata atbilstoši VDAR 6. (1) (f) pantam līdz no piemērojamā likuma
            izrietošiem obligātajiem glabāšanas termiņiem. Vispārīgi Yaway saistībā ar pakalpojuma
            sniegšanu savāktos datus glabā tik ilgi, līdz tas ir nepieciešams pakalpojumu sniegšanai
            starp datu subjektu un Yaway noslēgtā līguma darbības laikā un 3 gadus pēc līguma
            beigām. Vispārīgs noteikums ir dzēst visus ar Lietotāju saistītos personas datus 3 gadus
            pēc Lietotāja iesniegta Pasūtījuma nosūtīšanas Pakalpojuma sniedzējam, izņemot tad, ja
            mums ir tiesisks pamats jūsu personas datus glabāt ilgāk.
          </>
        )}
        {NumericSection(
          '4.3',
          <>
            Uz piekrišanas pamata vāktus datus glabājam līdz piekrišanas atsaukšanai. Ja datu
            subjekts nav atsaucis savu piekrišanu, Yaway uz piekrišanas pamata savāktu personas datu
            glabāšanai tiek piemērots tāds pats uzglabāšanas termiņš kā Platformas nodrošināšanai
            vāktajiem personas datiem. Vispārīgs noteikums ir, ja Platforma nav izmantota 3 gadus,
            dzēst visus ar lietotāju saistītos personas datus, izņemot tad, ja mums ir tiesisks
            pamats jūsu personas datus glabāt ilgāk.
          </>
        )}
        {NumericSection(
          '4.4',
          <>
            Ar grāmatvedības pamata dokumentiem un grāmatvedības ierakstiem saistītie personas dati
            ir jāuzglabā atbilstoši atbilstošajiem grāmatvedības likumiem. Līdz ar to uz
            grāmatvedības likuma pamata grāmatvedības dokumentus uzglabājam 7 gadus.
          </>
        )}

        {NumericSection('5.', <b>PERSONAS DATU APSTRĀDES PĀRSKATS</b>)}
        {NumericSection(
          '',
          <>
            Yaway apstrādā personas datus šādiem specifiskiem mērķiem šādu uzglabāšanas termiņu
            laikā:
          </>
        )}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='134'>
                  <p>
                    <strong>Apstrādes mērķis</strong>
                  </p>
                </td>
                <td width='132'>
                  <p>
                    <strong>Personas datu kategorijas</strong>
                  </p>
                </td>
                <td width='133'>
                  <p>
                    <strong>Kā mēs esam ieguvuši personas datus</strong>
                  </p>
                </td>
                <td width='174'>
                  <p>
                    <strong>Uzglabāšanas termiņš</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Auto palīdzības pasūtīšana, izmantojot Platformu (vilcēja pakalpojuma Pasūtījuma
                    nosūtīšanas nodrošināšana Lietotājam saskaņā ar Noteikumiem)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Vārds, uzvārds, e-pasta adrese, gadījuma tips, adrese un koordinātas,
                    transportlīdzekļa informācija, gadījuma apraksts, gadījuma fotogrāfijas
                  </p>
                </td>
                <td width='133'>
                  <p>Tieši no katra datu subjekta (Lietotāja)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Noteikumu darbības laikā un līdz 3 gadiem pēc ar datu subjektu noslēgtā
                    līguma beigām, uz Yaway tiesiskas intereses pamata līdz no piemērojamajiem
                    likumiem izrietošu noilguma termiņu beigām.
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Paziņojuma par kaitējuma gadījumu nosūtīšana, izmantojot Platformu (kaitējuma
                    izskatīšanas pakalpojuma Pasūtījuma nosūtīšanas nodrošināšana Lietotājam saskaņā
                    ar Noteikumiem)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    Vārds, uzvārds, e-pasta adrese, gadījuma tips, transportlīdzekļa informācija,
                    gadījuma apraksts, gadījuma fotogrāfijas
                  </p>
                </td>
                <td width='133'>
                  <p>Tieši no katra datu subjekta (Lietotāja)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Noteikumu darbības laikā un līdz 3 gadiem pēc ar datu subjektu noslēgtā
                    līguma beigām, uz Yaway tiesiskas intereses pamata līdz no piemērojamajiem
                    likumiem izrietošu noilguma termiņu beigām.
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Caur zvanu centru iesniegta Pasūtījuma izskatīšana</p>
                </td>
                <td width='132'>
                  <p>
                    Vārds, uzvārds, e-pasta adrese, gadījuma tips, transportlīdzekļa informācija,
                    gadījuma apraksts un cita informācija, kuru Lietotājs paziņo zvana laikā
                  </p>
                </td>
                <td width='133'>
                  <p>Tieši no katra datu subjekta (Lietotāja)</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Noteikumu darbības laikā un līdz 3 gadiem pēc ar datu subjektu noslēgtā
                    līguma beigām, uz Yaway tiesiskas intereses pamata līdz no piemērojamajiem
                    likumiem izrietošu noilguma termiņu beigām.
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Atbilžu sniegšana uz datu subjekta pieprasījumiem</p>
                </td>
                <td width='132'>
                  <p>
                    Vārds, e-pasta adrese un citi personas dati, kurus jūs iesniedzat tieši mums arī
                    tad, ja vēršaties pie mums ar pieprasījumu vai jautājumu, vai nu caur Platformu,
                    vai jebkādu citu kanālu (piemēram, nosūtot e-pasta vēstuli).
                  </p>
                </td>
                <td width='133'>
                  <p>Tieši no katra datu subjekta.</p>
                </td>
                <td width='174'>
                  <p>
                    Līdz ar iesniegto pieprasījumu saistītā prasījuma noilguma termiņam. Vispārīgi
                    šis termiņš ir 3 gadi.
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Konta reģistrēšana vilcēja vadītājam (Pakalpojuma sniedzēja darbinieks)</p>
                </td>
                <td width='132'>
                  <p>Vārds, uzvārds, tālrunis, e-pasta adrese, lietotāja kontam izvēlētā parole.</p>
                </td>
                <td width='133'>
                  <p>Tieši no katra datu subjekta (no vilcēja vadītāja).</p>
                </td>
                <td width='174'>
                  <p>
                    Yaway Noteikumu darbības laikā un līdz 3 gadiem pēc ar datu subjektu noslēgtā
                    līguma beigām, uz Yaway tiesiskas intereses pamata līdz no piemērojamajiem
                    likumiem izrietošu noilguma termiņu beigām.
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Tehniskā informācija</p>
                </td>
                <td colSpan={3} width='441'>
                  <p>Sk. sadaļu “Sīkdatnes”.</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {NumericSection('6.', <b>KĀ PAKALPOJUMA SNIEDZĒJI GŪST PIEEJU PERSNAS DATIEM?</b>)}
        {NumericSection(
          '6.1',
          <>
            Platforma nodrošina Pasūtījumu starpniecību starp Lietotājiem un Pakalpojuma
            sniedzējiem.
          </>
        )}
        {NumericSection(
          '6.2',
          <>
            Nosūtot Pasūtījumu, Yaway nosūta Lietotāja ar Pasūtījumu saistīto informāciju
            atbilstošam Pakalpojuma sniedzējam.
          </>
        )}
        {NumericSection(
          '6.3',
          <>
            esniedzot Pasūtījumu, ar Pasūtījuma izpildi saistīto Lietotāja personas datu Datu
            pārzinis ir Pakalpojuma sniedzējs. Ar personas datu apstrādi saistītajos jautājumos
            saistībā ar Pakalpojuma sniedzēja veikto apstrādi, lūdzam sazināties ar Pakalpojuma
            sniedzēju.
          </>
        )}

        {NumericSection('7.', <b>KAD YAWAY VAR NODOT PERSONAS DATUS TREŠAJĀM PERSONĀM?</b>)}
        {NumericSection(
          '7.1',
          <>
            Yaway var nodot jūsu personas datus saviem pilnvarotajiem datu apstrādātājiem, piem.,
            konkrētiem pakalpojuma sniedzējiem-trešajām personām, piem., IT pakalpojuma sniedzējiem
            vai citu pakalpojumu sniedzējiem.
          </>
        )}
        {NumericSection(
          '7.2',
          <>
            Varam nodot jūsu personas datus trešajām personām arī tad, ja mums tam ir tiesisks
            pienākums, piemēram, ja personas datus mums pieprasa jebkāda iestāde, kurai ir
            kompetence šos datus pieprasīt, piemēram, gadījumā, ja datus mums pieprasa tiesa vai
            tiesībsargājošā iestāde.
          </>
        )}
        {NumericSection(
          '7.3',
          <>
            Yaway nenodod personas datus ārpus Eiropas Savienības vai Eiropas Ekonomiskās Zonas,
            izņ., ja mums tam ir spēkā esošs tiesisks pamats un šajos privātuma principos
            paskaidrotajiem mērķiem. Nosūtot jūsu personas datus uz trešajām valstīm, nodrošinām, ka
            nosūtīšanai piemēro prasībām atbilstošus drošības pasākumus, kā arī jūsu tiesības ir
            aizsargātas, piemēram, Eiropas Komisijas parauga līgumi personas datu nosūtīšanai uz
            trešajām valstīm (t. i., līgumiskās standarta klauzulas). Varat pieteikt drošības
            pasākumu, kurus esam ieviesuši saistībā ar personas datu nosūtīšanu, kopijas saņemšanu,
            sazinieties ar mums, izmantojot zemāk minēto kontaktinformāciju.
          </>
        )}

        {NumericSection('8.', <b>KĀ YAWAY AZSARGĀ JŪSU PERSONAS DATUS?</b>)}
        {NumericSection(
          '',
          <>
            Lai aizsargātu jūsu personas datus pret neatļautu piekļuvi, nelikumīgu apstrādi vai
            publicēšanu, nejaušu pazušanu, grozīšanu vai iznīcināšanu, izmantojam prasībām
            atbilstošus tehniskus un organizatoriskus pasākumus, kas ir saskaņā ar piemērojamajiem
            likumiem. Šie pasākumi ir arī, bet ne tikai, prasībām atbilstošu datora drošības sistēmu
            izmantošana, datņu papīra un elektroniskā formā aizsargāšana ar tehniskiem un fiziskiem
            līdzekļiem, piekļuves dokumentiem un ēkām kontrolēšana un ierobežošana.
          </>
        )}

        {NumericSection('9.', <b>SĪKDATNES</b>)}
        {NumericSection(
          '9.1',
          <>
            Yaway Platforma izmanto sīkdatnes. Šī sadaļa aptver mūsu sīkdatņu lietošanas principus (
            <b>sīkdatņu principi</b>), kas tiek piemēroti, kad izmantojat platformu.
          </>
        )}
        {NumericSection(
          '9.2',
          <>
            Sīkdatnes ir nelielas teksta datnes, kuras interneta vietne saglabā jūsu cietajā diskā.
            Sīkdatnes mums palīdz sekot un uzlabot savas platformas darbību un lietošanu, kā arī
            jūsu pieredzi platformā. Varam izmantot sīkdatnes, lai redzētu, kādas zonas un funkcijas
            ir populāras, kā arī uzskaitīt apmeklējumus mūsu platformā, lai identificētu jūs kā
            atkārtotu lietotāju un pielāgotu jūsu pieredzi platformā jūsu izvēlēm. Varam izmantot
            sīkdatnes arī ar virzīšanas vai reklāmas mērķiem.
          </>
        )}
        {NumericSection('9.3', <>Savā platformā izmantojam šāda tipa sīkdatnes: </>)}
        {NumericSection(
          '9.3.1',
          <>
            <u>Ļoti nepieciešamās sīkdatnes</u>, kas ir neizbēgami nepieciešamas platformas un tās
            funkciju izmantošanai.
          </>,
          2
        )}
        {NumericSection(
          '9.3.2',
          <>
            <u>Funkcionālās sīkdatnes</u>, kas saglabā informāciju par jūsu veiktajām izvēlēm un
            nodrošina iespēju pielāgot platformu atbilstoši jūsu vajadzībām. Funkcionālās sīkdatnes
            saglabā jūsu veiktās izvēles. Mūsu izmantotās funkcionālās sīkdatnes saglabā e-pasta
            adresi pēc autorizācijas, tādēļ, ja autorizējaties platformā ar vairākām e-pasta
            adresēm, varam jums ieteikt apvienot dažādus platformas kontus, tam būtu jāvienkāršo
            jūsu procesi.
          </>,
          2
        )}
        {NumericSection(
          '9.3.3',
          <>
            <u>Analīzes sīkdatnes</u>, kas saglabā informāciju par to, kā mūsu platforma tiek
            izmantota, lai saņemtu informāciju par to, cik bieži mūsu platforma tiek apmeklēta, kuru
            mūsu platformā apmeklētāji pavada visvairāk laika, cik bieža ir mijiedarbība ar lapu vai
            lapas daļu, kas mums ļauj platformas uzbūvi, pārvietošanos tajā un platformas saturu
            padarīt iespējami draudzīgu lietotājam.
          </>,
          2
        )}
        {NumericSection(
          '9.3.4',
          <>
            <u>Reklāmas sīkdatnes</u>, kas reklāmas sniedzējiem ļauj sekot, kā jūs izmantojat
            portālu, lai virzītu reklāmu un saturu, kas jums varētu būt visinteresējošākais.
          </>,
          2
        )}
        {NumericSection('9.4', <>KKonkrētas sīkdatnes, kuras platforma izmanto, ir šādas: </>)}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='188'>
                  <p>
                    <strong>Sīkdatne</strong>
                  </p>
                </td>
                <td width='139'>
                  <p>
                    <strong>Apraksts</strong>
                  </p>
                </td>
                <td width='135'>
                  <p>
                    <strong>Ilgums</strong>
                  </p>
                </td>
                <td width='138'>
                  <p>
                    <strong>Tips</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_ga</p>
                </td>
                <td width='139'>
                  <p>
                    Šo sīkdatni izvieto Google Analytics. Sīkdatni izmanto apmeklētāja, sesijas,
                    akcijas informācijas aprēķināšanai un lai sekot lapas izmantošanai lapas
                    analītiskajam pārskatam. Sīkdatnes saglabā datus anonīmi un piešķir nejauši
                    izvēlētu numuru katra lietotāja identificēšanai.
                  </p>
                </td>
                <td width='135'>
                  <p>2 gadi</p>
                </td>
                <td width='138'>
                  <p>Analīze</p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_gid</p>
                </td>
                <td width='139'>
                  <p>
                    Šo sīkdatni izvieto Google Analytics. Sīkdatni izmanto, lai saglabātu
                    informāciju, kā apmeklētāji izmanto interneta vietni, kā arī tas palīdz sastādīt
                    analītisko pārskatu par interneta vietnes rezultātiem. Savāktie dati ir arī
                    apmeklētāju skaits, to izcelsmes avots, kā arī lapas, kuras apmeklē, anonīmā
                    formā.
                  </p>
                </td>
                <td width='135'>
                  <p>1 diena</p>
                </td>
                <td width='138'>
                  <p>Analīze</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {NumericSection(
          '9.5',
          <>
            Jebkurā laikā varat dzēst vai bloķēt sīkdatnes sava interneta pārlūka iestatījumos.
            Tomēr dažas sīkdatnes var būt nepieciešamas platformas darbībai. Tādēļ jums ir jāsaprot,
            ka, bloķējot vai dzēšot sīkdatnes, dažas platformas daļas var nestrādāt korekti.
          </>
        )}
        {NumericSection(
          '9.6',
          <>
            Vairāk vispārīgas informācijas par sīkdatnēm, tai skaitā sesijas sīkdatņu un pastāvīgo
            sīkdatņu atšķirībām, atradīsit{' '}
            <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>
          </>
        )}
        {NumericSection(
          '9.7',
          <>
            Ja Jums ir jautājumi par sīkdatņu principiem, varat ar mums sazināties, izmantojot zemāk
            minēto kontaktinformāciju.
          </>
        )}

        {NumericSection('10.', <b>JŪSU TIESĪBAS</b>)}
        {NumericSection(
          '10.1',
          <>
            Yaway vēlas nodrošināt, ka visas datu subjekta tiesības, kas izriet no piemērojamajiem
            tiesību aktiem, jums ir vienmēr garantētas. Jebkuram datu subjektam galvenokārt ir:
          </>,
          2
        )}
        {NumericSection(
          '10.1.1',
          <>tiesības iepazīties ar personas datiem, kurus Yaway par jums apstrādā; </>,
          2
        )}
        {NumericSection(
          '10.1.2',
          <>tiesības pieprasīt, lai Yaway izlabotu jebkādus nepareizus personas datus;</>,
          2
        )}
        {NumericSection(
          '10.1.3',
          <>
            tiesības pieprasīt, lai Yaway dzēstu jūsu personas datus un/vai ierobežotu jūsu personas
            datu apstrādi, ja mums nav spēkā esoša tiesiska pamata apstrādei;
          </>,
          2
        )}
        {NumericSection(
          '10.1.4',
          <>
            iesības saņemt savus apstrādātos personas datus strukturētā, vispārīgi izmantojamā un
            mašīnlasāmā formā, kā arī tiesības nosūtīt savus personas datus citam datu pārzinim.
          </>,
          2
        )}
        {NumericSection(
          '10.1.5',
          <>tiesības iesniegt iebildumus par savu personas datu apstrādi; </>,
          2
        )}
        {NumericSection(
          '10.2',
          <>
            Ja jūs uzskatāt, ka jūsu tiesības ir pārkāptas, jūs varat sazināties ar uzraudzības
            iestādi jūsu jurisdikcijā (Igaunijā, Datu aizsardzības inspekcija, adrese Tatari 39,
            Tallina 10134, <a href='mailto:info@aki.ee'>info@aki.ee</a>).
          </>,
          2
        )}

        {NumericSection('11.', <b>PIEMĒROJAMĀS TIESĪBAS UN JURISDIKCIJA</b>)}
        {NumericSection(
          '',
          <>
            Uz Privātuma Noteikumiem attiecas Igaunijas Republikas likumi. Jebkādi strīdi, kas
            izriet no privātuma principiem, tiks izšķirti Igaunijas Republikas Harju apgabaltiesā,
            ja vien jums nav tiesiskas tiesības vērsties savas dzīvesvietas tiesā.
          </>
        )}

        {NumericSection('12.', <b>KONTAKTINFORMĀCIJA</b>)}
        {NumericSection(
          '',
          <>
            Ja jums ir jautājumi par privātuma principiem vai sīkdatņu principiem, vai raizes
            saistībā ar to, kā mēs izmantojam jūsu personas datus, vai, ja vēlaties īstenot savas
            tiesības iepriekš aprakstītajā veidā, sazinieties ar mums, izmantojot e-pastu vai
            rakstiski, izmantojot zemāk minēto kontaktinformāciju.
          </>
        )}

        <p>
          <b>YAWAY OÜ</b>
        </p>
        <p>
          E-pasts: <a href='mailto:hello@yaway.ai'>hello@yaway.ai</a>
        </p>
        <p>Adrese: Valukoja 8/1, 11415, Tallinn, Igaunija</p>
      </div>
    </>
  );
};

export default PrivacyLatvian;
