import React, { ChangeEvent, useEffect, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Accordion, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import VehiclePartyForm from './VehiclePartyForm';
import OtherPartyForm from './ObjectPartyForm';
import Radio from '../../../components/controls/Radio';
import NextButton from '../../../components/controls/NextButton';
import {
  addPartiesAction,
  getOtherPartiesAction,
  removePartyAction,
  setPartyCountAction,
  setRedirectAction,
  updateClaimOtherPartiesDetailsAction,
} from './otherPartiesActions';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../../store/store';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import './OtherParties.css';
import './../../../components/controls/FormInput.css';
import { FieldErrors } from 'react-hook-form';
import { Loader } from '../../../components/loader/Loader';
import { push } from 'connected-react-router';
import { Party, PartyType } from './otherPartiesReducer';
import {
  trackClaimOtherPartiesSubmitEvent,
  trackClaimOtherPartiesViewEvent,
  trackClaimOtherPartyAddEvent,
  trackClaimOtherPartyDeleteEvent,
} from '../../../analytics/Analytics';
import { goToNextPage } from '../../../routing/RouteActions';
import { getRelativeCurrentUrl } from '../../../common/utils/URLUtils';
import { ModalPopup } from '../../../components/controls/ModalPopup';
export interface PartyState extends Party {
  submit: (formData: any) => void;
  submitCount: number;
}

export const OtherParties: React.FC<OtherPartyProps> = ({
  country,
  claimId,
  addParties,
  getParties,
  deleteParty,
  loadedParties,
  partiesInvolved,
  partiesInfo,
  incident,
  goToNextPage,
  setPartyCount,
  redirect,
  setRedirect,
  updateClaimOtherPartiesDetails,
  isMotionsCloudEnabled,
}: OtherPartyProps) => {
  const [parties, setParties] = useState<PartyState[]>([]);
  const [otherPartiesInvolved, setOtherPartiesInvolved] = useState<string | undefined | null>(
    () => {
      if (partiesInvolved === true) {
        return 'YES';
      } else if (partiesInvolved === false) {
        return 'NO';
      } else {
        return undefined;
      }
    }
  );
  const [otherPartiesInfo, setOtherPartiesInfo] = useState<string | undefined | null>(() => {
    if (partiesInfo === true) {
      return 'YES';
    } else if (partiesInfo === false) {
      return 'NO';
    } else {
      return undefined;
    }
  });
  const [lastPartyId, setLastPartyId] = useState(0);
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const [showParty, setShowParty] = useState(true);
  const [emptyTypeParties, setEmptyTypeParties] = useState<number[]>([]);
  const [errorPopup, setErrorPopup] = useState(<></>);

  useEffect(() => {
    trackClaimOtherPartiesViewEvent(claimId);
  }, []);

  useEffect(() => {
    setLoading(true);
    const updatedParties = loadedParties.map(
      (party: Party) =>
        ({
          ...party,
          submit: submit,
          submitCount: 0,
        } as PartyState)
    );

    setParties(updatedParties);

    if (loadedParties.length > 0) {
      setOtherPartiesInvolved('YES');
      setOtherPartiesInfo('YES');
    }

    if (loadedParties.length === 0 && partiesInvolved && partiesInfo) {
      setOtherPartiesInvolved('YES');
      setOtherPartiesInfo('NO');
    }

    setLoading(false);
  }, [loadedParties]);

  useEffect(() => {
    const maxId =
      parties.length > 0 &&
      parties.reduce((max, party) => (party.id > max ? party.id : max), parties[0].id);

    if (maxId) {
      setLastPartyId(maxId + 1);
    }
  }, []);

  useEffect(() => {
    if (claimId) {
      setLoading(true);
      setRedirect();
      getParties(claimId);
      setLoading(false);
    }
    if (redirect) {
      goToNextPage(incident, isMotionsCloudEnabled);
    }
  }, [redirect]);

  const handleOtherPartiesInvolvedChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setOtherPartiesInvolved(value);

    if (value === 'NO') {
      setShowParty(false);
      setOtherPartiesInfo(undefined);
    }
  };

  const handleOtherPartiesInfo = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    setOtherPartiesInfo(value);
    if (value === 'YES') {
      setShowParty(true);
      if (parties.length === 0) {
        handleAddParty();
      }
    }

    if (value === 'NO') {
      setShowParty(false);
    }
  };

  const submit = async (formData: PartyState) => {
    if (claimId) {
      setLoading(true);
      const updatedParties = parties.map((party: PartyState) => {
        if (party.id === formData.id) {
          party = formData;
        }
        return party;
      });

      setParties(updatedParties);
      await addParties(claimId, [formData]);
      setCounter(counter + 1);
      setLoading(false);
    }

    if (counter === parties.length) {
      push('/claims/accident-details');
    }
  };

  const handleAddParty = () => {
    const newParty = {
      id: lastPartyId || 0,
      submit: submit,
      submitCount: 0,
    };

    setParties([...parties, newParty]);
    setLastPartyId(lastPartyId + 1);
    setActiveKey(parties.length.toString());

    trackClaimOtherPartyAddEvent(claimId, newParty.id);
  };

  const handleOtherPartyType = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    const id = Number(event.target?.dataset['partyid']);

    const updatedParties = parties.map((party: PartyState) => {
      if (party.id === id) {
        party.type = value as PartyType;
      }

      return party;
    });
    if (emptyTypeParties.includes(id)) {
      const updatedEmptyTypeParties = emptyTypeParties.filter((it: number) => id !== it);
      setEmptyTypeParties(updatedEmptyTypeParties);
    }
    setParties(updatedParties);
  };

  const handleToggle = (id: number) => {
    if (activeKey === id.toString()) {
      setActiveKey(undefined);
    } else setActiveKey(id.toString());
  };

  const handlePartyHeader = (value: string, formData: PartyState) => {
    const updatedParties = parties.map((party: PartyState) => {
      if (party.id === formData.id) {
        if (formData.type === 'vehicle') {
          party = {
            ...party,
            vehicle: {
              ...party.vehicle,
              licensePlate: value,
            },
          };
        } else if (formData.type === 'object') {
          party = {
            ...party,
            object: {
              ...party.object,
              description: value,
            },
          };
        }
      }

      return party;
    });

    setParties(updatedParties);
  };

  const setErrors = (errors: FieldErrors<any>, formData: PartyState) => {
    const updatedParties = parties.map((party: PartyState) => {
      if (party.id === formData.id) {
        party = {
          ...party,
          errors: errors,
        };
      }

      return party;
    });

    setParties(updatedParties);
  };

  const handlePartySubmission = () => {
    const submittingParties = parties.map((party: PartyState) => ({
      ...party,
      submitCount: party.submitCount + 1,
    }));

    setParties(submittingParties);
  };

  const submitParties = async () => {
    setPartyCount(parties.length);
    if (!otherPartiesInvolved) {
      setOtherPartiesInvolved(null);
      return;
    } else if (
      otherPartiesInvolved === 'NO' ||
      (otherPartiesInvolved === 'YES' && otherPartiesInfo === 'NO')
    ) {
      goToNextPage(incident, isMotionsCloudEnabled);
    } else if (!otherPartiesInfo) {
      setOtherPartiesInfo(null);
      return;
    }

    await updatePartyDetails();

    if (showParty && parties.length > 0) {
      if (checkIfInvalidPartyPresent()) {
        setErrorPopup(
          <ModalPopup
            message={<Translate value={'otherParties.invalidPartyPresentErrorMessage'} />}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
        return;
      }
      handlePartySubmission();
    } else {
      const currentUrl = getRelativeCurrentUrl();
      currentUrl.includes('other-parties') && goToNextPage(incident, isMotionsCloudEnabled);
    }
    trackClaimOtherPartiesSubmitEvent(claimId, parties.length);
  };

  const updatePartyDetails = async () => {
    if (claimId) {
      await updateClaimOtherPartiesDetails(claimId, {
        otherPartiesInvolved: otherPartiesInvolved === 'YES',
        otherPartiesInfo: otherPartiesInfo === 'YES',
      });
    }
  };

  const checkIfInvalidPartyPresent = () => {
    let invalidPartiesCount = 0;
    parties.map((party: PartyState) => {
      if (party.type === undefined) {
        setEmptyTypeParties([...emptyTypeParties, party.id]);
        invalidPartiesCount += 1;
      }
      return party;
    });

    return invalidPartiesCount > 0;
  };

  const removeParty = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (claimId) {
      const partyId = Number((event.currentTarget as any).id);
      const remainingParties = parties.filter((party: PartyState) => party.id !== partyId);

      setParties(remainingParties);

      if (remainingParties.length === 0) {
        setShowParty(false);
        setOtherPartiesInfo('NO');
      }

      await deleteParty(claimId, partyId);
      trackClaimOtherPartyDeleteEvent(claimId, partyId);
    }
  };

  return (
    <Layout showProgress>
      <Container className='content' fluid='lg'>
        <SectionHeader text={<Translate value='otherParties.title' />} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='mx-3' id='otherPartiesForm'>
              <Form.Group>
                <Form.Label className='font-weight-bold text-muted'>
                  <Translate value='otherParties.otherPartiesInvolved' />
                </Form.Label>
                <div>
                  <Radio
                    inline
                    label={<Translate value='otherParties.yes' />}
                    name='otherPartiesInvolved'
                    type='radio'
                    value='YES'
                    defaultChecked={otherPartiesInvolved === 'YES'}
                    data-testid={'otherPartiesInvolvedYesBtn'}
                    id={`otherPartiesInvolved-1`}
                    onChange={handleOtherPartiesInvolvedChange}
                    isInvalid={otherPartiesInvolved === null}
                  />
                  <Radio
                    inline
                    label={<Translate value='otherParties.no' />}
                    value='NO'
                    defaultChecked={otherPartiesInvolved === 'NO'}
                    name='otherPartiesInvolved'
                    type='radio'
                    id={`otherPartiesInvolved-2`}
                    onChange={handleOtherPartiesInvolvedChange}
                    isInvalid={otherPartiesInvolved === null}
                  />
                </div>
              </Form.Group>

              {otherPartiesInvolved && otherPartiesInvolved === 'YES' && (
                <Form.Group>
                  <Form.Label className='font-weight-bold text-muted'>
                    <Translate value='otherParties.otherPartiesInfo' />
                  </Form.Label>
                  <div>
                    <Radio
                      inline
                      label={<Translate value='otherParties.yes' />}
                      name='otherPartiesInfo'
                      data-testid={'otherPartiesInfoYesBtn'}
                      type='radio'
                      value='YES'
                      defaultChecked={otherPartiesInfo === 'YES'}
                      id={`otherPartiesInfo-1`}
                      onChange={handleOtherPartiesInfo}
                      isInvalid={otherPartiesInfo === null}
                    />
                    <Radio
                      inline
                      label={<Translate value='otherParties.no' />}
                      name='otherPartiesInfo'
                      type='radio'
                      id={`otherPartiesInfo-2`}
                      value='NO'
                      defaultChecked={otherPartiesInfo === 'NO'}
                      onChange={handleOtherPartiesInfo}
                      isInvalid={otherPartiesInfo === null}
                    />
                  </div>
                </Form.Group>
              )}

              {showParty && parties && (
                <Accordion className='bg-white' activeKey={activeKey}>
                  {parties.map((party: PartyState, index: number) => (
                    <Card
                      key={index}
                      className={`m-auto bg-white my-2 custom-card-border ${
                        (party?.errors?.licensePlate || party?.errors?.description) &&
                        'border border-danger border-left-0 border-right-0'
                      }`}
                    >
                      <Card.Header className='custom-card'>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={index.toString()}
                          onClick={() => handleToggle(index)}
                        >
                          <Row>
                            <Col>
                              {party.type === 'vehicle' &&
                                party.vehicle?.licensePlate.toUpperCase()}
                              {party.type === 'object' && party.object?.description}
                              {(party?.errors?.licensePlate || party?.errors?.description) && (
                                <span className='text-danger'>
                                  <Translate value={'otherParties.missingValue'} />
                                </span>
                              )}
                            </Col>
                            <Col className='text-right'>
                              {index.toString() === activeKey ? (
                                <MdKeyboardArrowUp
                                  size={25}
                                  className={`${
                                    party?.errors?.licensePlate || party?.errors?.description
                                      ? 'text-danger'
                                      : 'text-muted'
                                  }`}
                                />
                              ) : (
                                <MdKeyboardArrowDown
                                  size={25}
                                  className={`${
                                    party?.errors?.licensePlate || party?.errors?.description
                                      ? 'text-danger'
                                      : 'text-muted'
                                  }`}
                                />
                              )}
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                          <Card.Body className='p-0'>
                            <Form.Group controlId='otherPartyType' className='mt-2'>
                              <Form.Label className='input-lbl'>
                                <Translate value='otherParties.otherPartiesType' />
                              </Form.Label>
                              <Form.Row className='mt-2 d-flex justify-content-start'>
                                <Radio
                                  label={<Translate value={'otherParties.vehiclePartyType'} />}
                                  name={`otherPartiesType-${party.id}`}
                                  type='radio'
                                  id={`otherPartiesType-${party.id}-1`}
                                  defaultChecked={party.type === 'vehicle'}
                                  data-partyId={party.id}
                                  value='vehicle'
                                  onChange={handleOtherPartyType}
                                  isInvalid={emptyTypeParties.includes(party.id)}
                                />
                              </Form.Row>
                              <Form.Row className='mt-2 d-flex justify-content-start'>
                                <Radio
                                  label={<Translate value={'otherParties.objectPartyType'} />}
                                  name={`otherPartiesType-${party.id}`}
                                  type='radio'
                                  defaultChecked={party.type === 'object'}
                                  id={`otherPartiesType-${party.id}-2`}
                                  data-partyId={party.id}
                                  value='object'
                                  onChange={handleOtherPartyType}
                                  isInvalid={emptyTypeParties.includes(party.id)}
                                />
                              </Form.Row>
                            </Form.Group>
                            {party.type === 'vehicle' && (
                              <VehiclePartyForm
                                country={country}
                                formData={party}
                                handlePartyHeader={handlePartyHeader}
                                setErrors={setErrors}
                              />
                            )}
                            {party.type === 'object' && (
                              <OtherPartyForm
                                country={country}
                                formData={party}
                                handlePartyHeader={handlePartyHeader}
                                setErrors={setErrors}
                              />
                            )}
                            <Card.Footer className='text-right bg-white border-0'>
                              <Button
                                variant='outline-danger'
                                id={party.id.toString()}
                                onClick={removeParty}
                              >
                                <Translate value={'otherParties.delete'} />
                              </Button>
                            </Card.Footer>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card.Header>
                    </Card>
                  ))}
                </Accordion>
              )}
            </div>
            {showParty && parties.length > 0 && (
              <div className='text-center my-2'>
                <Button variant='outline-dark text-uppercase' onClick={handleAddParty}>
                  <span>
                    + <Translate value={'otherParties.addAnotherParty'} />
                  </span>
                </Button>
              </div>
            )}

            <NextButton
              className='my-2'
              onClick={submitParties}
              data-testid='submit'
              loading={loading}
            />
          </>
        )}
        {errorPopup}
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ locale, otherParties, claim, claimIncident }: AppState) => ({
  country: locale.countryCode,
  claimId: claim.claimId,
  loadedParties: otherParties.parties,
  partiesInvolved: otherParties.partiesInvolved,
  partiesInfo: otherParties.havePartyInfo,
  redirect: otherParties.redirect,
  incident: claimIncident.incident,
  isMotionsCloudEnabled: claim.isMotionsCloudEnabled,
});

const mapDispatchToProps = {
  addParties: addPartiesAction,
  getParties: getOtherPartiesAction,
  deleteParty: removePartyAction,
  setPartyCount: setPartyCountAction,
  setRedirect: setRedirectAction,
  updateClaimOtherPartiesDetails: updateClaimOtherPartiesDetailsAction,
  goToNextPage: goToNextPage,
};

export type OtherPartyProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OtherParties);
