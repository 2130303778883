import { createSlice } from '@reduxjs/toolkit';
import { ApiFile } from '../../../api';

export interface AdditionalDetailsState {
  description?: string;
  images?: File[] | Blob | Blob[] | ApiFile[];
  email: string;
}

const initialState: AdditionalDetailsState = {
  email: '',
};

const additionalDetailsSlice = createSlice({
  name: 'additionalDetails',
  initialState,
  reducers: {
    setAdditionalDetails: (state: AdditionalDetailsState, action: any) => {
      if (action && action.payload) {
        state.email = action.payload.email;
        state.description = action.payload.description;
      }
    },
    resetAdditionalDetails: (state: AdditionalDetailsState) => {
      state.email = initialState.email;
      state.description = undefined;
      state.images = undefined;
    },
    saveImages: (state: AdditionalDetailsState, action: any) => {
      if (action && action.payload) {
        state.images = action.payload;
      } else state.images = [];
    },
  },
});

const { actions, reducer } = additionalDetailsSlice;

export const { setAdditionalDetails, resetAdditionalDetails, saveImages } = actions;

export default reducer;
