import { Dispatch } from 'redux';
import { ApiClaimSettlementType } from '../../../api';
import { claimApi } from '../../../apis';
import { setSettlementType } from './settlementTypeReducer';

export const saveSettlementTypeAction =
  (claimId: string, settlementType: ApiClaimSettlementType) =>
  async (dispatch: Dispatch): Promise<void> => {
    await claimApi.saveSettlementType({
      id: claimId,
      apiClaimSettlement: { claimSettlementType: settlementType },
    });
    dispatch(setSettlementType(settlementType));
  };
