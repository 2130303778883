import React from 'react';
import { SectionHeader } from '../sectionHeader/SectionHeader';
import './TermsAndPrivacy.css';
import { NumericSection } from './Util';

export const PrivacyEnglish: React.FC = () => {
  return (
    <>
      <SectionHeader text={'PRIVACY TERMS'} />
      <div>
        <p>
          <b>INTRODUCTION</b>
        </p>
        <p>
          This personal data processing conditions document (hereinafter referred to as the &quot;
          <b>Privacy Terms</b>&quot;) describes how Yaway processes personal data within its
          services. The purpose of the Privacy Terms is to provide clear and transparent information
          about personal data processing in accordance with the applicable regulation.
        </p>

        <p>
          If you have further questions about how we process your personal data or if you wish to
          submit requests to exercise your rights related to the processing of your personal data,
          please contact us using the contact details provided in the &quot;Contact&quot; section
          below.
        </p>

        <p>
          Yaway may occasionally change these Privacy Terms. The current Privacy Terms are always
          published on the website <a href='https://yaway.ai'>https://yaway.ai</a>.
        </p>

        {NumericSection('1.', <b>DEFINITIONS</b>)}
        {NumericSection('Data Subject', <>A natural person whose data Yaway processes;</>, 4)}

        {NumericSection(
          'GDPR',
          <>
            Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016
            on the protection of natural persons with regard to the processing of personal data and
            on the free movement of such data, and repealing Directive 95/46/EC (General Data
            Protection Regulation);
          </>,
          4
        )}

        {NumericSection(
          'Personal Data',
          <>
            Any information relating to an identified or identifiable natural person (&quot;data
            subject&quot;); an identifiable natural person is one who can be identified, directly or
            indirectly, in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier, or to one or more factors
            specific to the physical, physiological, genetic, mental, economic, cultural, or social
            identity of that natural person;
          </>,
          4
        )}

        {NumericSection(
          'Applicable Law',
          <>
            All applicable European Union legislations and all applicable legislations of the
            Republic of Estonia, including but not limited to, the national implementing acts of the
            GDPR, which are in force during the validity of these Privacy Terms or come into force
            after the terms are established;
          </>,
          4
        )}

        {NumericSection(
          'User',
          <>
            who uses the Platform to order roadside assistance or to report damage that has occurred
            with the vehicle;
          </>,
          4
        )}

        {NumericSection(
          'Yaway',
          <>
            Yaway OÜ, registration code 16406514, located at Valukoja 8/1, 11415, Tallinn, Estonia;
          </>,
          4
        )}

        {NumericSection(
          'Platform',
          <>
            The platform managed by Yaway on the website{' '}
            <a href='https://yaway.ai'>https://yaway.ai</a>, through which one can order roadside
            assistance or send notifications about incidents involving vehicle damage;
          </>,
          4
        )}

        {NumericSection(
          'Processing',
          <>
            Any operation or set of operations which is performed on personal data or on sets of
            personal data, whether or not by automated means, such as collection, recording,
            organization, structuring, storage, adaptation or alteration, retrieval, consultation,
            use, disclosure by transmission, dissemination or otherwise making available, alignment
            or combination, restriction, erasure, or destruction;
          </>,
          4
        )}

        {NumericSection(
          'Service Provider',
          <>
            is the service provider to whom the User can submit an Order through the Platform to
            receive roadside assistance or for the handling of an incident involving vehicle damage;
          </>,
          4
        )}

        {NumericSection(
          'Order',
          <>
            is an order submitted by the User through the Platform to the Service Provider, for
            instance, to receive roadside assistance or for the handling of a damage claim;
          </>,
          4
        )}

        {NumericSection(
          'Data Controller',
          <>
            A natural or legal person, public authority, agency, or other body which, alone or
            jointly with others, determines the purposes and means of the processing of personal
            data. In the context of these Terms, the data controller for employee personal data is
            Yaway; within the legal relationship that arises between the Service Provider and the
            User through the Platform, the data controller for the User&apos;s personal data is the
            Service Provider;
          </>,
          4
        )}

        {NumericSection(
          'Data Processor',
          <>
            A natural or legal person, public authority, agency, or other body that processes
            personal data on behalf of the data controller;
          </>,
          4
        )}

        {NumericSection(
          'General Terms or Terms',
          <>
            Terms of use that apply to the relationship between Yaway and Users in connection with
            the use of the Platform are available at <a href='https://yaway.ai'>https://yaway.ai</a>
            .
          </>,
          4
        )}

        {NumericSection('2.', <b>WHEN AND WHY DO WE PROCESS PERSONAL DATA?</b>)}
        {NumericSection(
          '2.1',
          <>
            If you have decided to use the Yaway Platform, we need to process your personal data to
            enable the use of the Platform in accordance with the Terms.
          </>
        )}
        {NumericSection(
          '2.2',
          <>
            We process personal data that you have provided to Yaway in connection with the use of
            the Platform. These data include, among others, the following:
          </>
        )}
        {NumericSection(
          '2.2.1',
          <>
            <u>general personal data</u>: name (first name, last name);
          </>,
          2
        )}
        {NumericSection(
          '2.2.2',
          <>
            <u>contact details</u>: email address; phone number;
          </>,
          2
        )}
        {NumericSection(
          '2.2.3',
          <>
            <u>Data necessary for ordering roadside assistance (tow truck)</u>: vehicle details
            (make, model, year, license plate), address and coordinates, description of the incident
            (a free-text description of the incident details provided by the User when using the
            Platform);
          </>,
          2
        )}
        {NumericSection(
          '2.2.4',
          <>
            <u>Data necessary for handling a damage claim</u>: vehicle details (make, model, year,
            license plate), description of the incident (a free-text description of the incident
            details provided by the User when using the Platform); photos;
          </>,
          2
        )}
        {NumericSection(
          '2.2.5',
          <>
            <u>Other data</u>: other data that the User might transmit through the Platform;
          </>,
          2
        )}
        {NumericSection(
          '2.2.6',
          <>
            <u>technical data</u>: technical data collected in connection with the provision of the
            Platform, which is collected using cookies (read the section below titled
            &quot;Cookies&quot;).
          </>,
          2
        )}

        {NumericSection('3.', <b>WHAT IS THE LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA?</b>)}
        {NumericSection(
          '3.1',
          <>
            We may process personal data to provide the Platform in accordance with Yaway&apos;s
            Terms. The legal basis for such data processing is Article 6 (1) (b) of the GDPR, i.e.,
            the processing of personal data is necessary for the performance of a contract to which
            the data subject is a party, or in order to take steps at the request of the data
            subject prior to entering into a contract.
          </>
        )}
        {NumericSection(
          '3.2',
          <>
            We may process personal data based on the consent given by the data subject. The legal
            basis for such data processing is Article 6 (1) (a) of the GDPR. In such situations,
            Yaway processes personal data under the conditions specified in the consent that each
            data subject has given to us.
          </>
        )}
        {NumericSection(
          '3.3',
          <>
            We may process personal data if the processing is necessary for the fulfillment of a
            legal obligation applicable to us, for example, for accounting purposes under the
            applicable accounting law, or if personal data is requested from Yaway based on a
            request from a competent authority, for instance, under a valid court order. The legal
            basis for such data processing is Article 6 (1) (c) of the GDPR.
          </>
        )}
        {NumericSection(
          '3.4',
          <>
            In certain cases, we may also process personal data if the processing of personal data
            is necessary for our legitimate interests. The legal basis for such data processing is
            Article 6 (1) (f) of the GDPR. In such a case, Yaway ensures that the processing is
            proportionate and we have conducted an impact assessment of our legitimate interest.
          </>
        )}

        {NumericSection('4.', <b>STORAGE OF PERSONAL DATA</b>)}
        {NumericSection(
          '4.1',
          <>
            Yaway does not retain personal data longer than necessary based on the purpose of
            personal data processing or under applicable law. Generally, we apply the following
            retention periods.
          </>
        )}
        {NumericSection(
          '4.2',
          <>
            Personal data related to contracts may be retained for the duration of the contract and
            based on our legitimate interest under Article 6 (1) (f) of the GDPR until mandatory
            limitation periods prescribed by applicable law. Generally, Yaway retains data collected
            in connection with the provision of services as long as it is necessary for the
            provision of services during the validity of the contract between the data subject and
            Yaway and for 3 years after the contract has ended. As a general rule, all personal data
            related to the User is deleted 3 years after the delivery of the order submitted by the
            User to the Service Provider, unless we have a legal basis to retain your personal data
            for a longer period.
          </>
        )}
        {NumericSection(
          '4.3',
          <>
            Data collected based on consent is stored until the consent is withdrawn. If the data
            subject has not withdrawn their consent, Yaway applies the same retention period for
            personal data collected based on consent as for personal data collected to support the
            Platform. As a general rule, if the Platform has not been used for 3 years, all personal
            data related to the user is deleted, unless we have a legal basis to retain your
            personal data for a longer period.
          </>
        )}
        {NumericSection(
          '4.4',
          <>
            Personal data related to accounting source documents and accounting entries must be
            retained in accordance with the relevant accounting laws. Therefore, based on the
            accounting law, we retain accounting documents for 7 years.
          </>
        )}

        {NumericSection('5.', <b>OVERVIEW OF PERSONAL DATA PROCESSING</b>)}
        {NumericSection(
          '',
          <>
            Yaway processes personal data for the following specific purposes within the following
            retention periods:
          </>
        )}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='134'>
                  <p>
                    <strong>The purpose of processing</strong>
                  </p>
                </td>
                <td width='132'>
                  <p>
                    <strong>Categories of personal data</strong>
                  </p>
                </td>
                <td width='133'>
                  <p>
                    <strong>How we obtained the personal data</strong>
                  </p>
                </td>
                <td width='174'>
                  <p>
                    <strong>Retention period</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Ordering roadside assistance service through the platform (enabling the user to
                    submit a tow service order in accordance with the Terms & Conditions)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    First name, last name, email address, type of incident, address and coordinates,
                    vehicle details, description of the incident, photos of the incident
                  </p>
                </td>
                <td width='133'>
                  <p>Directly from each data subject (User)</p>
                </td>
                <td width='174'>
                  <p>
                    During the validity of Yaway&apos;s Terms and up to 3 years after the
                    termination of the contract with the data subject, based on Yaway&apos;s
                    legitimate interest until the expiration of the statutory limitation periods
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Through the platform, reporting an incident (providing the User the option to
                    submit an order for damage handling service in accordance with the Terms)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    First name, last name, email address, type of incident, vehicle details,
                    description of the incident, photos of the incident
                  </p>
                </td>
                <td width='133'>
                  <p>Directly from each data subject (User)</p>
                </td>
                <td width='174'>
                  <p>
                    During the validity of the Yaway Terms and up to 3 years after the termination
                    of the contract concluded with the data subject, based on Yaway&apos;s
                    legitimate interest until the expiration dates specified by the applicable law
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Processing the order submitted through the call center</p>
                </td>
                <td width='132'>
                  <p>
                    First name, last name, email address, type of incident, vehicle details,
                    description of the incident, and other information that the User discloses
                    during the call
                  </p>
                </td>
                <td width='133'>
                  <p>Directly from each data subject (User)</p>
                </td>
                <td width='174'>
                  <p>
                    During the validity of the Yaway Terms and up to 3 years after the termination
                    of the contract with the data subject, based on Yaway&apos;s legitimate interest
                    until the expiration dates stipulated by the applicable law
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Responding to the data subject&apos;s inquiries</p>
                </td>
                <td width='132'>
                  <p>
                    Name, email address, and other personal data that you provide to us directly
                    when you contact us with an inquiry or question, whether through the Platform or
                    any other channel (for example, by sending an email)
                  </p>
                </td>
                <td width='133'>
                  <p>Directly from each data subject</p>
                </td>
                <td width='174'>
                  <p>
                    Until the expiration date of the claim related to the submitted inquiry.
                    Generally, this period is 3 years
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>
                    Account registration for the tow truck driver (Service Provider&apos;s employee)
                  </p>
                </td>
                <td width='132'>
                  <p>
                    First name, last name, phone number, email address, and the password chosen for
                    the user account
                  </p>
                </td>
                <td width='133'>
                  <p>Directly from each data subject (tow truck driver)</p>
                </td>
                <td width='174'>
                  <p>
                    During the validity of Yaway&apos;s Terms and up to 3 years after the
                    termination of the contract with the data subject, based on Yawayy&apos;s
                    legitimate interest and in accordance with the expiration periods required by
                    applicable law
                  </p>
                </td>
              </tr>
              <tr>
                <td width='134'>
                  <p>Technical data</p>
                </td>
                <td colSpan={3} width='441'>
                  <p>Refer to section &quot;Cookies&quot;</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {NumericSection('6.', <b>HOW DO SERVICE PROVIDERS ACCESS PERSONAL DATA?</b>)}
        {NumericSection(
          '6.1',
          <>The platform facilitates the mediation of orders between Users and Service Providers.</>
        )}
        {NumericSection(
          '6.2',
          <>
            When ordering a service, Yaway forwards the data related to the User&apos;s Order to the
            appropriate Service Provider.
          </>
        )}
        {NumericSection(
          '6.3',
          <>
            When placing an Order, the Service Provider is the responsible processor of the
            User&apos;s personal data related to the fulfillment of the Order. For questions related
            to the processing of personal data in connection with the Service Provider&apos;s
            processing, please contact the Service Provider.
          </>
        )}

        {NumericSection('7.', <b>WHEN CAN YAWAY SHARE PERSONAL DATA WITH THIRD PARTIES?</b>)}
        {NumericSection(
          '7.1',
          <>
            Yaway may share your personal data with its authorized processors, e.g. certain
            third-party service providers, such as IT service providers or other service providers.
          </>
        )}
        {NumericSection(
          '7.2',
          <>
            We may also share your personal data with third parties if we are legally obliged to do
            so, for example, if any official authority with the competence to request such data
            demands them from us, such as in cases where the data is requested by a court or law
            enforcement agency.
          </>
        )}
        {NumericSection(
          '7.3',
          <>
            Yaway does not transfer personal data outside the European Union or the European
            Economic Area, except when we have a valid legal basis and for the purposes explained in
            these privacy principles. When transferring your personal data to third countries, we
            ensure that appropriate security measures are applied and that your rights are
            protected, for example, by using the European Commission&apos;s standard contractual
            clauses for transferring personal data to third countries (i.e., contractual standard
            clauses). You can request a copy of the security measures we have implemented in
            relation to the transfer of personal data by contacting us using the contact details
            provided below.
          </>
        )}

        {NumericSection('8.', <b>HOW DOES YAWAY PROTECT YOUR PERSONAL DATA?</b>)}
        {NumericSection(
          '',
          <>
            To protect your personal data from unauthorized access, illegal processing or
            disclosure, accidental loss, alteration, or destruction, we use appropriate technical
            and organizational measures in accordance with applicable laws. These measures include,
            but are not limited to, the implementation of suitable computer security systems,
            protecting files in paper and electronic form with technical and physical means, and
            controlling and restricting access to documents and buildings.
          </>
        )}

        {NumericSection('9.', <b>COOKIES</b>)}
        {NumericSection(
          '9.1',
          <>
            Yaway Platform uses cookies. This section covers our principles of cookie usage (
            <b>cookie principles</b>), which apply when you use the platform.
          </>
        )}
        {NumericSection(
          '9.2',
          <>
            Cookies are small text files that are saved to your hard drive by a website. Cookies
            help us monitor and improve the functionality and usage of our platform, as well as your
            experience on the platform. We might use cookies to see which areas and features are
            popular and to count visits to our platform, to identify you as a returning visitor, and
            to tailor your experience on the platform according to your preferences. We might also
            use cookies for targeting or advertising purposes.
          </>
        )}
        {NumericSection('9.3', <>We use the following types of cookies on our platform:</>)}
        {NumericSection(
          '9.3.1',
          <>
            <u>Essential cookies</u>, which are absolutely necessary for the use of the platform and
            its features.
          </>,
          2
        )}
        {NumericSection(
          '9.3.2',
          <>
            <u>Functional cookies</u>, which store information about your choices and allow
            customization of the platform according to your needs. Functional cookies save your
            choices. The functional cookies we use store the email address after logging in, so if
            you log into the platform with multiple email addresses, we may suggest you combine
            different platform accounts, which should simplify your processes.
          </>,
          2
        )}
        {NumericSection(
          '9.3.3',
          <>
            <u>Analytics cookies</u>, which store information about how our platform is used to get
            insights about how often our platform is visited, where on our platform visitors spend
            the most time, how frequent the interaction is with a page or part of a page, which
            allows us to modify the platform&apos;s structure, navigation, and content to be as
            user-friendly as possible.
          </>,
          2
        )}
        {NumericSection(
          '9.3.4',
          <>
            <u>Advertising cookies</u>, which allow advertisers to track how you use the portal to
            target ads and content that might be of most interest to you.
          </>,
          2
        )}
        {NumericSection('9.4', <>Specific cookies used by the platform are as follows:</>)}
        {NumericSection(
          '',
          <table className='table table-bordered' width='0' cellSpacing='0' cellPadding='0'>
            <tbody>
              <tr>
                <td width='188'>
                  <p>
                    <strong>Cookie</strong>
                  </p>
                </td>
                <td width='139'>
                  <p>
                    <strong>Description</strong>
                  </p>
                </td>
                <td width='135'>
                  <p>
                    <strong>Duration</strong>
                  </p>
                </td>
                <td width='138'>
                  <p>
                    <strong>Type</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_ga</p>
                </td>
                <td width='139'>
                  <p>
                    This cookie is installed by Google Analytics. The cookie is used to calculate
                    visitor, session, and campaign data and to track website usage for the
                    website&apos;s analytical report. Cookies store data anonymously and assign a
                    randomly generated number to identify each visitor.
                  </p>
                </td>
                <td width='135'>
                  <p>2 years</p>
                </td>
                <td width='138'>
                  <p>Analytics</p>
                </td>
              </tr>
              <tr>
                <td width='188'>
                  <p>_gid</p>
                </td>
                <td width='139'>
                  <p>
                    This cookie is installed by Google Analytics. The cookie is used to store
                    information on how visitors use the website, and it helps compile an analytical
                    report on the website&apos;s performance. The collected data includes, among
                    other things, the number of visitors, their source of origin, and the pages they
                    visit, in an anonymous form.
                  </p>
                </td>
                <td width='135'>
                  <p>1 day</p>
                </td>
                <td width='138'>
                  <p>Analytics</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        {NumericSection(
          '9.5',
          <>
            You can delete or block cookies at any time in your web browser settings. However, some
            cookies may be necessary for the platform to function. Therefore, you should understand
            that by blocking or deleting cookies, some parts of the platform may not work correctly.
          </>
        )}
        {NumericSection(
          '9.6',
          <>
            For more general information about cookies, including the differences between session
            cookies and persistent cookies, visit{' '}
            <a href='https://www.allaboutcookies.org'>www.allaboutcookies.org</a>
          </>
        )}
        {NumericSection(
          '9.7',
          <>
            If you have questions about the cookie policy, you can contact us using the contact
            details provided below.
          </>
        )}

        {NumericSection('10.', <b>YOUR RIGHTS</b>)}
        {NumericSection(
          '10.1',
          <>
            Yaway aims to ensure that all data subject rights arising from applicable legislation
            are always guaranteed to you. In particular, every data subject has:
          </>,
          2
        )}
        {NumericSection(
          '10.1.1',
          <>the right to access personal data that Yaway processes about you;</>,
          2
        )}
        {NumericSection(
          '10.1.2',
          <>the right to request that Yaway corrects any inaccurate personal data;</>,
          2
        )}
        {NumericSection(
          '10.1.3',
          <>
            the right to request that Yaway deletes your personal data and/or limits the processing
            of your personal data, if we do not have a valid legal basis for processing;
          </>,
          2
        )}
        {NumericSection(
          '10.1.4',
          <>
            the right to receive your processed personal data in a structured, commonly used, and
            machine-readable format, as well as the right to transmit your personal data to another
            data controller;
          </>,
          2
        )}
        {NumericSection(
          '10.1.5',
          <>the right to object to the processing of your personal data;</>,
          2
        )}
        {NumericSection(
          '10.2',
          <>
            If you believe that your rights have been violated, you can contact the supervisory
            authority of your jurisdiction (in Estonia, the Data Protection Inspectorate, address
            Tatari 39, Tallinn 10134, <a href='mailto:info@aki.ee'>info@aki.ee</a>).
          </>,
          2
        )}

        {NumericSection('11.', <b>APPLICABLE LAW AND JURISDICTION</b>)}
        {NumericSection(
          '',
          <>
            Applicable to the Privacy Terms are the laws of the Republic of Estonia. Any disputes
            arising from the privacy principles will be resolved in the Harju County Court in the
            Republic of Estonia, unless you have a legal right to turn to the court of your place of
            residence.
          </>
        )}

        {NumericSection('12.', <b>CONTACT DETAILS</b>)}
        {NumericSection(
          '',
          <>
            If you have questions about the privacy principles or the cookie principles, or concerns
            about how we use your personal data, or if you wish to exercise your rights in the
            manner described above, please contact us via email or in writing using the contact
            details provided below.
          </>
        )}

        <p>
          <b>YAWAY OÜ</b>
        </p>
        <p>
          E-mail: <a href='mailto:hello@yaway.ai'>hello@yaway.ai</a>
        </p>
        <p>Address: Valukoja 8/1, 11415 Tallinn</p>
      </div>
    </>
  );
};

export default PrivacyEnglish;
